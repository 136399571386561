import React, { useState } from 'react';
import { Field } from 'formik';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import TextInput from '../components/TextInput';

const PasswordInput = ({ field, form, ...props }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div style={{ width:'100%',position: 'relative', width: '100%' }}>
            <TextInput
                {...field}
                {...props}
                type={showPassword ? 'text' : 'password'}
                style={{ width: '100%'}}
            />
            <span
                onClick={togglePasswordVisibility}
                style={{
                    position: 'absolute',
                    right: '10px',
                    top: '70%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                }}
            >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
        </div>
    );
};
export default PasswordInput;