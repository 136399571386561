import React, { createContext, useState, useContext, useEffect } from 'react';
import { loginUser } from './login';

export const SessionContext = createContext('default');

export function useSession() {
  return useContext(SessionContext);
}

export function SessionProvider({ children }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const savedUser = sessionStorage.getItem('user');
    if (savedUser) {
      const jsonUser = JSON.parse(savedUser);
      setUser(jsonUser);
    }
  }, []);

  const loginHandler = async (data) => {
    try {
      const userData = await loginUser(data);
      setUser(userData);
      sessionStorage.setItem('userData', JSON.stringify(userData));
      sessionStorage.setItem('token', userData.token);
      return userData;
    } catch (error) {
      setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
      return null;
    }
  };

  const logoutHandler = () => {
    setUser(null);
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
  };

  const getToken = () => {
    return user?.token;
  }

  return (
    <SessionContext.Provider value={{ user, error, login: loginHandler, logout: logoutHandler, token: getToken }}>
      {children}
    </SessionContext.Provider>
  );
}
