import React, { useState,useEffect } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { fetchComunas,fetchRegiones } from "../services/register";

import { FloatLabel } from 'primereact/floatlabel';
import format from './../common/format';
import validate from './../common/validate';
import { registerUser } from "../services/register";
import TextInput from '../components/TextInput';
import PasswordInput from '../components/passwordInput';
import { Fn } from '../utils/rutValidation';
import { isValidEmail } from '../utils/isValidEmail';


// Styled-components
const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-top: 10px;
  display: block;
  font-weight: bold;
`;

const StyledField = styled(Field)`
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: -15px;
  margin-bottom: 10px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #0056b3;
  }
`;



const Card = styled.div`
   
    display: flex;
    justify-content: center;
    .p-button {
        margin: 0.5rem;
    }
`;

const CustomDialog = styled(Dialog)`
    width:40%;
    @media screen and (max-width: 800px) {
        width:90%;
    }
    .p-dialog {
        width: 900px; /* Cambia esto al ancho deseado */
        max-width: 80%; /* Asegura que no exceda el 80% del ancho de la pantalla */
    }

    .p-dialog-header {
        background-color: transparent;
        border-bottom: none;
        display:none;
    }

    .p-dialog-content {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        gap: 1rem;
        border-radius: 12px;
        background-image: #FFF
        @media screen and (max-width: 800px) {
            padding: 1rem;
        }
    }

    .p-dialog-footer {
        display: none;
    }

    .p-dialog-header-icon {
        color: var(--primary-50);
    }
`;

const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
        color: var(--primary-50);
        font-weight: 600;
    }

    .p-inputtext {
        background-color: rgba(255, 255, 255, 0.2);
        border: none;
        padding: 1rem;
        color: var(--primary-50);
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .p-button {
    padding: 1rem;
    width: 100%;

    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: #06B6D4; /* Assuming this is a darker shade */
    color:white;
    &:hover {
        background-color: #079db6;
         border: 1px solid rgba(255, 255, 255, 0.8);
    }
}
`;

export default function HeadlessDemo() {
    const [visible, setVisible] = useState(false);
    const [comunas, setComunas] = useState([]);
    const [regions, setRegions] = useState([]);

    useEffect(() => {
      fetchRegiones().then((response) => {
        
        setRegions(response);
      });
    }, []);

    const initialValues = {
        rut: '',
        firstName: '',
        PaternallastName: '',
        MaternallLastName: '',
        email: '',
        region: null,
        comuna: null,
        phone: '',
        password: '',
        confirmPassword: ''
    };

    const rut = Yup.string()
            .test('valida-rut', 'RUT inválido', function(value) {
                return validate.rut(value);
            })
            .required('Requerido');



            const handleSubmit = (values) => {
                /*
                
                
                */
               
               var data = {
              rut_usuario:values.rut,
              nombre_usuario:values.firstName,
              apellido_usuario: values.PaternallastName + " " + values.MaternallLastName,
              correo_usuario:values.email,
              id_comuna:values.comuna,
              numero_telefono:values.phone,
              contrasenna:values.password,
              rol:'Postulante',
              reset:true
            
              }
              

                registerUser(data).then((response) => {
                    
                  if(response.status===200){
              
                    alert("Usuario registrado correctamente");
                    setVisible(false);
                  }else{
                    //
                    alert(`Error al crear la cuenta: ${response.response.data.detail}`);
                  }
                
                });
              
            }

    const handleRegionChange = (event, setFieldValue) => {
        const selectedRegionId = event.target.value;
        setFieldValue('region', selectedRegionId);
        regions.forEach((region) => {
            if (region.id_region === parseInt(selectedRegionId)) {
                fetchComunas(selectedRegionId).then((response) => {
                    setComunas(response.comunas);
                });
            }
        });
        setFieldValue('comuna', '');
    };

    const handleComunaChange = (event, setFieldValue) => {
        const selectedComuna = event.target.value;
        setFieldValue('comuna', selectedComuna);
    };

    const [errores, setErrores] = useState([]);

    const validateRut = (rut) => {
        if (!rut) {
            return 'RUT requerido';
        }
    
        if (!Fn.validaRut(rut)) {
            return 'Invalido!, El rut debe ir sin puntos y Con guion';
        }
    
        return null; // Retorna null si el RUT es válido
    }

      const validate = (values) => {
        const errors = {};

        const rutError = validateRut(values.rut);
          if (rutError) {
              errors.rut = rutError;
          }

        if (!values.firstName) {
            errors.firstName = 'Nombre Requerido';
          } else if (values.firstName.length < 2) {
            errors.firstName = 'Nombre Es muy corto';
        }
        if (!values.PaternallastName) {
            errors.PaternallastName = 'Apellido paterno Requerido';
          } else if (values.PaternallastName.length < 2) {
            errors.PaternallastName = 'Apellido paterno Es muy corto';
        }
        if (!values.MaternallLastName) {
            errors.MaternallLastName = 'Apellido materno Requerido';
          } else if (values.MaternallLastName.length < 2) {
            errors.MaternallLastName = 'Apellido materno Es muy corto';
        }
        if (!values.email) {
            errors.email = 'Correo Requerido';
          } else if (values.email.length < 5) {
            errors.email = 'Correo Es muy corto';
        }else if (!isValidEmail(values.email)) {
            errors.email = 'Formato de correo inválido';
        }
        if (!values.phone) {
            errors.phone = 'Telefono Requerido';
          } else if (values.phone.length < 5) {
            errors.phone = 'Telefono Es muy corto';
        }
      
        if (!values.password) {
            errors.password = 'Contraseña Requerida';
        } else if (values.password.length < 8) {
            errors.password = 'Contraseña debe tener al menos 8 caracteres';
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = 'Confirmación de Contraseña Requerida';
        } else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = 'Las contraseñas no coinciden';
        }

        
          const errorMessages = Object.values(errors);
          setErrores(errorMessages);
         
          return errors;
        };

    return (
        <Card className="card">
            <Button label="¿No tienes cuenta?" icon="pi pi-user" onClick={() => setVisible(true)} />
            <CustomDialog
                visible={visible}
                modal
                onHide={() => setVisible(false)}
            >
                <div className="p-dialog-content">
                    <Title>Registro de usuario</Title>
                    <Formik 
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={(values) => validate(values)}>
                        {({ setFieldValue, values }) => (
                            <StyledForm as={Form}>
                                <br />
                                <div style={{ width: '100%', display: 'flex', gap: '1rem' }}>
                                    <FloatLabel style={{ width: '100%' }}>

                                        <TextInput style={{ width: '100%' }} maxLength='12' type='text' id="rut" name="rut" onChange={(event) => { setFieldValue('rut',event.target.value); }} />
                                        <label htmlFor="rut">RUT (11111111-1)</label>
                            
                                    
                                        
                                    </FloatLabel>
                                  
                                    <FloatLabel style={{ width: '100%' }}>
                                    
                                        <TextInput  style={{ width: '100%' }} type='text' id="firstName" name="firstName" onChange={(event) => { setFieldValue('firstName',event.target.value); }}/>
                                        <label htmlFor="firstName">Nombre</label>
                                       </FloatLabel>
                                    <br/>
                                    
                                </div>
                         
                                
                                <div style={{ marginTop: '2rem', width: '100%', display: 'flex', gap: '1rem' }}>
                                    <FloatLabel style={{ width: '100%' }}>
                                        <TextInput  style={{ width: '100%',  height:'50%' }} type='text' id="PaternallastName" name="PaternallastName" onChange={(event) => { setFieldValue('PaternallastName',event.target.value); }}/>
                                        <label htmlFor="PaternallastName">Apellido paterno</label>
                                       </FloatLabel>
                                    <FloatLabel style={{ width: '100%' }}>
                                        <TextInput  style={{ width: '100%' }} type='text' id="MaternallLastName" name="MaternallLastName" onChange={(event) => { setFieldValue('MaternallLastName',event.target.value); }}/>
                                        <label htmlFor="MaternallLastName">Apellido materno</label>
                                        </FloatLabel>
                                </div>
                                <FloatLabel style={{ width: '100%', marginTop: '2rem' }}>
                                    <TextInput  style={{ width: '100%' }} type='email' id="email" name="email" onChange={(event) => { setFieldValue('email',event.target.value); }}/>
                                    <label htmlFor="email">Correo electrónico</label>
                                    </FloatLabel>
                                <Label htmlFor="region">Región:</Label>
                                <StyledField required as="select" id="region" name="region" onChange={(event) => handleRegionChange(event, setFieldValue)}>
                                    <option value="">Seleccione una opción</option>
                                    {regions.map((region) => (
                                        <option key={region.id_region} value={region.id_region}>
                                            {region.descripcion_region}
                                        </option>
                                    ))}
                                </StyledField>
                                <ErrorMessage name="region" component={ErrorText} />
                                <Label htmlFor="comuna">Comuna:</Label>
                                <StyledField required as="select" id="comuna" name="comuna" onChange={(event) => handleComunaChange(event, setFieldValue)}>
                                    <option value="">Seleccione una opción</option>
                                    {comunas.map((comuna) => (
                                        <option key={comuna.id_comuna} value={comuna.id_comuna}>
                                            {comuna.descripcion_comuna}
                                        </option>
                                    ))}
                                </StyledField>
                                <ErrorMessage name="comuna" component={ErrorText} />

                                <Label htmlFor="phone">Teléfono:</Label>
                                <StyledField  type="text" id="phone" name="phone" />
                                <br/>
                                <ErrorMessage name="phone" component={ErrorText} />

                                <Label htmlFor="password">Contraseña (8 carácteres mínimo):</Label>
                                <Field
                                    id="password"
                                    name="password"
                                    component={PasswordInput}
                                />
                              

                                <Label htmlFor="confirmPassword">Confirmar Contraseña:</Label>
                                <Field
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    component={PasswordInput}
                                />
                                

                                <ButtonContainer style={{ marginTop: '15px' }}>
                                    <Button   type="submit" label="Crear cuenta" text />
                                    <Button  label="Cancelar" onClick={() => setVisible(false)} text />
                                </ButtonContainer>
                            </StyledForm>
                        )}
                    </Formik>
                </div>
            </CustomDialog>
        </Card>
    );
}