import { useField } from 'formik';
import styled from 'styled-components';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'react-tooltip';

const TextAreaInputStyled = styled(InputTextarea)`
    width: 100%;
    height: 80px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12px;
    resize: vertical;
    &:focus {
        background-color: lightblue;
        outline: none;
    }
`;

const Div = styled.div`
    color: red;
`;

const Label = styled.label`
    font-weight: bold;
    display: flex; /* Añadido para alinear los elementos en línea */
    align-items: center; /* Centra verticalmente el asterisco con el texto del label */
`;

const Asterisk = styled.span`
    color: red;
    margin-left: 5px; /* Ajusta el espacio entre el texto del label y el asterisco */
`;

const TextArea = ({ label, tooltipContent, required, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <div>
            <Label>
                {label}
                {required && <Asterisk>*</Asterisk>}
            </Label>
            <TextAreaInputStyled
                {...field}
                maxLength={500}
                data-tooltip-id="my-tooltip-multiline"
                data-tooltip-html={tooltipContent}
            />
            <Tooltip id="my-tooltip-multiline" />
            {meta.touched && meta.error ? <Div>{meta.error}</Div> : null}
        </div>
    );
};

export default TextArea;
