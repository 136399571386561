import {Component } from 'react'
import styled from 'styled-components';

const LogoImg = styled.img`
    width:auto;
    height:60px;
    @media screen and (max-width: 800px) {
        height:35px;
    }
`;

class Logo extends Component{
    render(){
        return(
          
                <LogoImg src='/IMG/Logo-FDS2.png'  title='COLBUN' alt='COLBUN' />
               
        )
    }
}

export default Logo