import React, { useRef } from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { IoIosExit } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import styled from 'styled-components';
import { IoMenu } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const IconWrapper = styled.div`
    display: flex;
    align-items: center;

    .icon {
        margin-right: 8px; /* Ajusta el valor según el espacio que desees */
    }
`;

const StyledButton = styled(Button)`
    .button-icon {
        margin-right: 8px; /* Ajusta el valor según el espacio que desees */
    }
`;

export default function PopupDoc({ label }) {
    const menuRight = useRef(null);
    const toast = useRef(null);
    const navigate = useNavigate();

    const items = [
        {
            label: 'Menu',
            items: [
                {
                    label: (
                        <IconWrapper>
                            <FaUser className="icon" />
                            Mi cuenta
                        </IconWrapper>
                    ),
                    command: () => navigate('/MiPerfil')
                },
                {
                    label: (
                        <IconWrapper>
                            <IoIosExit className="icon" />
                            Salir
                        </IconWrapper>
                    ),
                    command: () => navigate('/Login')
                    
                }
            ]
        }
    ];

    return (
        <div className="card flex justify-content-center">
            <Toast ref={toast}></Toast>
            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
            <StyledButton 
                label={label} 
                icon={<IoMenu className="button-icon" />} 
                iconPos="left" 
                className="mr-2" 
                onClick={(event) => menuRight.current.toggle(event)} 
                aria-controls="popup_menu_right" 
                aria-haspopup 
            />
        </div>
    );
}
