// Footer.js
import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

const FooterContainer = styled.footer`

  background-color: black;
  color: white;
  padding: 20px;
  text-align: center;
  bottom:1px;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  font-size: 24px;

  a {
    color: white;
    margin: 0 10px;
    text-decoration: none;

    &:hover {
      color: #ccc;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <p>FDS Coronel 2024 - contacto@fdscolbun.org</p>
      <SocialIcons>
        <a href="https://www.facebook.com/ColbunEnergia/" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="https://www.instagram.com/energiacolbun/" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      </SocialIcons>
    </FooterContainer>
  );
};

export default Footer;
