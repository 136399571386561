import React, { useEffect, useState,Component } from 'react';
import Logo from './Logo';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PopupDoc from './menutest';
import { useNavigate } from 'react-router-dom';
import { FaHome } from "react-icons/fa";

const styles = {
    navbar: {
        display: 'flex',
        justifyContent: 'center', // Centrar todo el contenido
        alignItems: 'center',
        height: '80px',
        width: '100%',
        padding: '0 90px',
        position: 'fixed',
        boxShadow: '0 2px 10px rgb(0,0,0,0.1)',
        backgroundColor: '#F2F2F2',
        zIndex: 1001,
    },
};



const ContentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    @media screen and (max-width: 800px) {
        justify-content: space-between;
        width: 100%;
    }
`;

const LogoWrapper = styled.div`
    @media screen and (max-width: 800px) {
        margin-left: -30%; 
    }
            cursor: pointer;
`;

const PopupDocWrapper = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 800px) {
        margin-right: -30%; 
    }
`;

const HomeButton = styled.h4`
    
    margin-right: 10px; // Espacio entre el botón HOME y PopupDoc
    cursor: pointer;
`;



const Navbar = ({ name }) => {
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const userData = sessionStorage.getItem('userData');
        if (userData) {
            const parsedUserData = JSON.parse(userData);
            setUser(parsedUserData);
            
        }
    }, []);
    

    const handleLogoClick = () => {
        if (user.rol === 'Postulante') {
            navigate('/FeedPostulante');
        } else if (user.rol === 'Evaluador') {
            navigate('/FeedEvaluador');
        } else if (user.rol === 'Administrador') {
            navigate('/FeedAdmin');
        }
    };
    return (
        <nav style={styles.navbar}>
            <ContentContainer> 
            <LogoWrapper onClick={handleLogoClick}>
                    <Logo />
                </LogoWrapper>
                
                <PopupDocWrapper>
                    <HomeButton>
                    <h4  onClick={handleLogoClick}><FaHome />Ir a Home</h4>
                    </HomeButton>

                    <PopupDoc label={name} />
                </PopupDocWrapper>
            </ContentContainer>
        </nav>
    );
};


Navbar.propTypes = {
    name: PropTypes.string // Define the 'name' prop as a string
};


Navbar.defaultProps = {
    name:'Usuario'
}

export default Navbar;
