import React, { useState } from 'react';
import { 
    Img, Overlay,
    Div, DivColorInputs, DivColorText,
    Img2, StyledDivInfo, StyledImg, CenterButton,
    StyledButton, StyledImageContainer, StyledTextContainer,
    StyledH2, StyledP, Input 
} from '../pages/Styles/StyleLogin';
import { useSession } from '../services/sessionContext'; // Ensure correct import path
import Footer from '../components/Footer';
import HeadlessDemo from '../components/RegistroModal';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import { Button } from 'primereact/button';
import PasswordInput from '../components/passwordInput';

import { Formik, Field, Form } from 'formik'; // Importa Formik y Field

const style = {
    input: {
        width: '100%',
    },
    input2: {
        width: '100%',
        marginBottom: '5px',
    }
}

function Login() {
    const [correo, setCorreo] = useState('');
    const { login, error } = useSession(); 
    const navigate = useNavigate(); 

    const handleLogin = (values) => {
        const data = {
            correo_usuario: values.correo,
            contrasenna: values.contrasena,
        };

        login(data).then((response) => {
            if (response) {
                const userData = response.usuario;
                sessionStorage.setItem('userData', JSON.stringify(userData));
                sessionStorage.setItem('token', response.token);
            
                if (userData.rol === 'Evaluador') {
                    navigate('/FeedEvaluador');
                } else if (userData.rol === 'Postulante') {
                    navigate('/FeedPostulante');
                } else if (userData.rol === 'Administrador') {
                    navigate('/FeedAdmin');
                }
            } else {
                document.getElementById("error").hidden = false;
            }
        }).catch((err) => {
            console.error(err);
            document.getElementById("error").hidden = false;
        });
    };

    return (
        <div>
            <Div>
                <Img src='/IMG/CARRUSEL/FDS-CORONEL-COLBUN.jpeg' />
                <Overlay>
                    <DivColorText>
                        <div>
                            <Img2 src='/IMG/Logo-FDS2.png' width='auto' height='auto' title='COLBUN' alt='COLBUN' />
                        </div>
                        <h1 style={{fontSize:'24px', textAlign:'justify'}}>Bienvenidos a nuestra plataforma de postulación al Fondo de Desarrollo Social Colbún. Esta iniciativa busca apoyar a las organizaciones sociales de los territorios cercanos a sus instalaciones, mediante la capacitación en la formulación de proyectos sociales y el otorgamiento de financiamiento para la ejecución de iniciativas de impacto comunitario.</h1>
                        <h2>Le invitamos a leer y descargar las bases en el siguiente enlace:  
  <a href="https://docs.google.com/file/d/1Y2IyAYborpoeFIE5ipWYeZJHxNpuN5RH/edit?usp=docslist_api&filetype=msword" target="_blank">  ¡Haz click aquí!</a>
</h2>

                    </DivColorText>
                    <DivColorInputs>
                        <h2>Sistema de gestión Fondos desarrollo social Colbún</h2>
                        <p>
                            Bienvenido al sistema de gestión de fondos concursables de Colbun, favor ingresar sus credenciales para continuar.
                        </p>
                       
                        <Formik
                            initialValues={{ correo: '', contrasena: '' }}
                            onSubmit={handleLogin}
                        >
                            {({ values }) => (
                                <Form>
                                    <p id="error" hidden={!error} style={{color:'red'}}>Usuario o contraseña incorrectos</p>
                                    <br />
                                    <FloatLabel>
                                        <Field
                                            as={InputText}
                                            validateOnly
                                            style={style.input2}
                                            type='email'
                                            id="username"
                                            name="correo"
                                        />
                                        <label htmlFor="username">Correo electrónico</label>
                                    </FloatLabel>
                                    <br />
                                    <FloatLabel>
                                        <Field
                                            name="contrasena"
                                            component={PasswordInput}
                                            style={style.input}
                                        />
                                        <label htmlFor="password">Contraseña</label>
                                    </FloatLabel>
                                    <br /><br />
                                    <CenterButton>
                                        <Button severity='success' type="submit">Iniciar sesión</Button>
                                    </CenterButton>
                                    
                                </Form>
                            )}
                        </Formik>
                        <HeadlessDemo />
                        <p>Si tiene algún problema con su contraseña, por favor comuníquese a través del siguiente correo: contacto@fdscolbun.org.</p>
                    </DivColorInputs>
                </Overlay>
            </Div>
            <Footer />
        </div>
       
    );
}

export default Login;
