import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import styled from 'styled-components';

import { fetchSolicitudesByEv } from '../services/solicitud';
import $ from 'jquery';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrap = styled.div`
  flex: 1;
  margin-top: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContenedorTabla = styled.div`
  padding: 5px;
  border-radius: 5px;
  border: solid 1px;
  border-color: black;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 100%;
`;

const Title = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10px;
  align-self: flex-start;
`;

const Div = styled.div`
  width: 80%;
  max-width: 1015px;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const EstadoCell = styled.td`
  background-color: ${({ estado }) => {
    switch (estado) {
      case 'Pendiente':
        return '#fce282';
      case 'Revisado':
        return '#dfd';
      default:
        return '#fff';
    }
  }};
`;

function FeedPostulante() {
  const tableId = '#table_id';
  const isTableInitialized = useRef(false);
  const [user, setUser] = useState({});
  const [postulantesData, setPostulantesData] = useState([]);

  useEffect(() => {
    const userData = sessionStorage.getItem('userData');

    if(userData.rol==="Postulante"){
      window.location.href = '/feedPostulante';
    } else if(userData.rol==='Administrador'){
      window.location.href = '/feedAdmin';
    }
    if (userData) {
      const parsedUser = JSON.parse(userData);
      setUser(parsedUser);
    }
  }, []);

  useEffect(() => {
    if (user.rut_usuario) {
      fetchSolicitudesByEv(user.rut_usuario).then(data => {
        setPostulantesData(data);
      });
    }
  }, [user.rut_usuario]);

  useEffect(() => {
    const config = {
      responsive: true,
      columnDefs: [
        { responsivePriority: 1, targets: 0 },
        { responsivePriority: 2, targets: -1 },
        { responsivePriority: 3, targets: 4 }
      ],
      order: [[2, 'asc']],
      language: {
        sProcessing: "Procesando...",
        sLengthMenu: "Mostrar _MENU_ registros",
        sZeroRecords: "No se encontraron registros",
        sEmptyTable: "No hay datos disponibles",
        sInfo: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        sInfoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
        sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
        sSearch: "Buscar:",
        sLoadingRecords: "Cargando...",
        oAria: {
          sSortAscending: ": Activar para ordenar la columna de manera ascendente",
          sSortDescending: ": Activar para ordenar la columna de manera descendente"
        }
      }
    };

    if ($(tableId).length && !isTableInitialized.current && postulantesData.length > 0) {
      $(tableId).DataTable(config);
      $(tableId).DataTable().columns.adjust();
      $(tableId).DataTable().responsive.recalc();
      isTableInitialized.current = true;
    }
  }, [postulantesData]);

  return (
    <PageContainer>
      <Navbar name={user.nombre_usuario} />
      <ContentWrap>
        <Div>
          <Title>Lista de postulaciones</Title>
          <ContenedorTabla>
            <table id="table_id" className="display responsive nowrap" width="100%">
              <thead>
                <tr>
                  <th>Organizacion Postulante</th>
                  <th>Comuna</th>
                  <th>Region</th>
                  <th>Fecha Postulación</th>
                  <th>Estado</th>
                  <th>Puntaje</th>
                </tr>
              </thead>
              <tbody>
                {postulantesData.map(postulante => (
                  <tr key={postulante.id_solicitud}>
                    <td>
                      <StyledLink to={'/FormularioRevision?id_postulacion='+postulante.id_solicitud}>
                        {postulante.nombre_organizacion}
                      </StyledLink>
                    </td>
                    <td>{postulante.descripcion_comuna}</td>
                    <td>{postulante.descripcion_region}</td>
                    <td>{postulante.Fecha_Postulacion}</td>
                    <EstadoCell estado={postulante.estado_solicitud}>{postulante.estado_solicitud}</EstadoCell>
                    <td>{postulante.Puntaje!=undefined ? postulante.Puntaje : 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ContenedorTabla>
        </Div>
      </ContentWrap>
      <Footer />
    </PageContainer>
  );
}

export default FeedPostulante;
