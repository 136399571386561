import * as Yup from 'yup';
import validate from './validate';

const rut = Yup.string()
            .test('valida-rut', 'RUT inválido', function(value) {
                return validate.rut(value);
            })
            .required('Requerido');


const customFormSchema = Yup.object({
    rut: rut,
    firstName: Yup.string().required('Nombre requerido'),
    lastName: Yup.string().required('Primer apellido requerido'),
    secondLastName: Yup.string().required('Segundo apellido requerido'),
    email: Yup.string().email('Correo inválido').required('Correo requerido'),
    region: Yup.string().nullable().notOneOf([null, ''], 'Región requerida'),
    comuna: Yup.string().nullable().notOneOf([null, ''], 'Comuna requerida'),
    phone: Yup.string().required('Teléfono requerido'),
    password: Yup.string().min(8, 'La contraseña debe tener al menos 8 caracteres').required('Contraseña requerida'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden').required('Confirmación de contraseña requerida') // validación para confirmar contraseña
});

const schemas = {
    customFormSchema: customFormSchema
}

export default customFormSchema;