import axios from 'axios';

const API_URL = 'https://devapi.fdscolbun.org';

const createController = (endpoint) => {
  const controller = axios.create({
    baseURL: `${API_URL}/${endpoint}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return controller;
};

export const loginUser = async (data) => {
  try {
    const response = await axios.post(API_URL+'/usuario/login/', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    return 400;
    throw error;
  }
}