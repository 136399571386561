import React, { useState, useEffect } from 'react';
import { Form, Formik, ErrorMessage, Field } from 'formik';
import TextInput from '../components/TextInput';
import TextArea from '../components/TextArea';
import RadioInput from '../components/RadioInput';
import NumberInput from '../components/NumberInput';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

import { Checkbox } from 'primereact/checkbox';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { IoMdDownload } from 'react-icons/io';

import { TiWarning } from "react-icons/ti";
import { IoMdCloseCircle } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import Navbar from '../components/Navbar'
import { createComment,getComment,getSolicitudInfoById,getSolicitudById, fetchRespuestas, evaluarSolicitud, fetchPuntajes, evaluarPuntaje, fetchDocument, deleteDocument  } from '../services/solicitud';

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const Asterisk = styled.span`
    color: red;
    margin-left: 4px; /* Ajusta el espacio entre el texto del label y el asterisco */
`;



const DocumentDisplay = ({ certificado, clearCertificado }) => {
  const isFromDB = certificado && certificado.nombre_documento;
  const isLoadedByUser = certificado && certificado[0] && certificado[0].name;

  const handleDelete = () => {
    if (isFromDB) {
      // Simulate an API call to delete the document from the database
      deleteDocument(certificado.id_documento).then((response) => {
        if(response){
          clearCertificado(null);
        }
      })
    } else if (isLoadedByUser) {
      clearCertificado(null);
    }
  };

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };
  const handleDownload = () => {
    if (isFromDB) {
      const mimeType = certificado.tipo_documento; // Adjust based on your document type
      const blob = base64ToBlob(certificado.documento, mimeType);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = certificado.nombre_documento;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } else if (isLoadedByUser) {
      const file = certificado[0];
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    }
  };


  if (isFromDB) {
    return (
      <div style={{ display: 'flex', marginBottom:'5%' }}>
        <p>Nombre del documento: {certificado.nombre_documento}</p>
        <div style={{marginTop:'2.5%'}}>
        <button
          type="button"
          onClick={handleDownload}
          style={{
            cursor: 'pointer',
            color: 'blue',
            border: '0px',
            borderRadius: '25px',
            width: '5%',
            marginTop: '2.5%',
            height: '5%'
          }}
        >
         <IoMdDownload size={24} />
        </button>
        </div>
      <div style={{marginTop:'2.5%'}}>
     
      </div>
    
      </div>
    );
  }

  if (isLoadedByUser) {
    return (
      <div style={{ display: 'flex' }}>
        <p>Nuevo documento: {certificado[0].name}</p>
        <button
          type="button"
          onClick={handleDelete}
          style={{
            cursor: 'pointer',
            color: 'red',
            border: '0px',
            borderRadius: '25px',
            width: '5%',
            marginTop: '2.5%',
            height: '5%'
          }}
        >
          x
        </button>
      </div>
    );
  }

  return null; // Hide when neither condition is met
};


const FileUpload = ({ name, label, errorMessageStyle, onChange, loading}) => {
  

  // Function to convert file to an array of bytes

  // Function to send file data to the API
  const sendFileToApi = async (bytes, form) => {
    try {
      const response = {
        id_solicitud: form.values.id_solicitud,  // adjust these fields as necessary
        tipo_documento: form.values.tipo_documento,
        documento: bytes,
        categoria: form.values.categoria
      }
   
      
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
     
    }
  };

  return (
    <>
      <Label>
        {label}
        <Asterisk>*</Asterisk>
        <ErrorMessageStyled name={name} component="div" style={errorMessageStyle} />
      </Label>
      <Field name={name}>
        {({ field, form }) => (
          <>
           
          </>
        )}
      </Field>
      <br />
      <br />
    </>
  );
};
const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const DivContenedorMax = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  height: auto
  border-radius: 5px;
  @media screen and (max-width: 780px) {  
      background-color: #fff;
  }  
 
`;

const Div = styled.div`  
  border-radius: 15px;
  margin-left: 5%;  
  margin-right: 5%;  
  margin-bottom:5%;
  background-color: #fff;  
  padding-left: 2%;  
  padding-top: 85px; /* Ajuste para dejar espacio para el navbar */  
  padding-bottom: 8%;  
  position: relative; /* No se necesita cambiar esto */  
  height:100%;
  min-height: 900px;  
  width: 1050px;  
  max-width: 1050px;  
  transition: opacity 0.3s ease;  

  @media screen and (max-width: 780px) {  
    height: auto;  
    min-height: 100%;  
    width: 100vw; /* ancho del viewport */  
    margin-left: 0; /* Elimina margen izquierdo */  
    padding-left: 10px; /* Ajusta según sea necesario */  
    margin-right: 0; /* Elimina margen derecho */  
    padding-right: 10px; /* Ajusta según sea necesario */  
    max-width: 100%; /* Asegura que el contenedor se expanda completamente */  
    height: 100vh; /* Permite que la altura mínima se ajuste al contenido */  
    position: relative; /* Asegura que el contenedor se adapte a la altura del contenido */  
    padding-bottom: 5%;  
    padding-top: 95px; /* Ajusta para dispositivos móviles también */  
  }  
`;

const DivNavbar = styled.div`  
  position: fixed; /* Cambiar a posición fija */  
  top: 0; /* Colocarla en la parte superior */  
  left: 0; /* Asegurar que ocupe todo el ancho */  
  width: 100%;  
  background-color: #f1f1f1; /* Puedes establecer el fondo si es necesario */  
  /* Es posible que también desees añadir un poco de padding o altura según tu diseño */  
  padding: 0px; /* Ajusta esto según sea necesario */  
  z-index:999;
  margin-bottom:50%;
`;  


const DivFlex = styled.div`
  margin-top:-25px;
  display: flex;
  gap: 10%;
  justify-content: center;
  align-items: flex-start;
  align-text: center;

  @media screen and (max-width: 780px) {
    flex-direction: column;
  } 

  &.fade {
    animation: ${fadeAnimation} 0.5s;
  }

  &.fade-out {
    animation: ${fadeAnimation} 0.5s reverse;
  }

`;


const DivForm = styled.div`

`;

const Label = styled.label`
    font-weight: bold;
    margin-right: 5px;
    margin-top:0px;
    margin-buttom:0px;
`;
const ErrorMessageStyled = styled(ErrorMessage)`
  display: inline-block;
  color: red; /* Puedes ajustar el estilo del mensaje de error aquí */
  margin-left: 3px; /* Espacio entre el label y el mensaje de error */
  
`;

const ButtonSubir = styled.button`
    padding: 7px 17px;
    background-color: #11CDDE;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin:1%;
    margin-right:0%;
    bottom:2%;
    &:hover {
      color: white;
      background-color: #07B4C4 ;
    }
`;

const ButtonNext = styled.button`
    padding: 10px 20px;
    background-color: #42ADEA;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin:1%;
    bottom:2%;
    &:hover {
      background-color: #3592C8;
    }
`;
const ButtonAtras = styled.button`
    padding: 10px 20px;
    background-color: #42ADEA;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin:1%;
    bottom:2%;

    &:hover {
      background-color: #3592C8;
    }
`;
const ButtonEnviar = styled.button`
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin:1%;
    bottom:2%;
    background-color:#3CB371;
    &:hover {
      background-color: #359B62;
    }
    &:disabled {
      background-color: #e7e7e7; /* Fondo cuando está deshabilitado */
      color: #a0a0a0; /* Color del texto cuando está deshabilitado */
      cursor: not-allowed; /* Cambiar cursor cuando está deshabilitado */
      pointer-events: none; /* Deshabilitar efectos hover */
  }
`;
const DivError = styled.button`
    border: none;
    padding:5px;
    color:black;
    height:auto;
    margin:1%;
    bottom:0%;
    background-color:salmon;
    border-radius: 5px;
    border:solid 1px;
    border-color:black;
`;
const DivSinErrores = styled.div`
    border: none;
    padding:5px;
    color:black;
    height:auto;
    margin:1%;
    bottom:0%;
    background-color:#3CB371;
    
    border-radius: 5px;
    border:solid 1px;
    border-color:black;
`;

const DivButtons = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-top:2%;
  margin:0px;
  bottom:3%;
  @media screen and (max-width: 780px) {
    position: relative;
    bottom: 15%;
    margin-bottom:10%;
    width: 100%;
  } 
`;

const DivContenedorError = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    bottom: 0;
    width: 95%;
`;


const ProgressBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @media screen and (max-width: 780px) {
    margin-top:2%;
    justify-content: center;
    align-items: center;
    width:auto;
    } 
`;

const StepProgress = styled.div`
  flex: 1;
  display: flex; /* Añade esta línea para hacer que los pasos se alineen horizontalmente */
  @media screen and (max-width: 780px) {
    margin-bottom:5%;
    } 
`;


const Step = styled.div`  
  display: flex;  
  align-items: center;  
  position: relative;  
  flex: 1; /* Opcional: Esto asegura que cada Step ocupe el mismo espacio horizontalmente */  
  animation: ${fadeAnimation} 0.5s ease-in-out;  
  @media screen and (max-width: 780px) {  
    flex-direction: row; /* Ajusta la dirección del flexbox a horizontal */  
    justify-content: center; /* Centra los elementos en el eje principal */  
    align-items: center; /* Centra los elementos en el eje secundario */  

  }  
`;


const Bullet = styled.div`
position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => (props.active === 'true' ? '#76c7c0' : '#e0e0df')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.active === 'true' ? 'white' : 'black')};
  font-weight: bold;
  z-index:10;
  cursor: pointer; /* Agrega esta línea para mostrar la mano de selección */
  &:hover {
    background-color: #61A29C; 
  }
  @media screen and (max-width: 780px) {
    margin-top:10%;
    
    } 
`;

const StepTitle = styled.p`  
  margin-top: 10px;  
  @media screen and (max-width: 780px) {  
    display: none;  
    margin: 0;  
    padding: 0;  
    height: 0;  
    overflow: hidden;  
    visibility: hidden;  
  }  
`;

const shakeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-5px);
  }
  20% {
    transform: translateX(5px);
  }
  30% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
`;


const SaveIcon = styled.button`
  padding:7px;
  border-radius: 5px;
  border: none;
  position: absolute;
  left: 2%;
  top:145px;
  display: block;
  background-color: #5bc0de;
  transition: width 0.3s ease, height 0.3s ease;

  &:hover {
    background-color: #50AAC5;
    color:white;
   
  }
    @media screen and (max-width: 780px) {  
      top:16%;
  }  

`;




const CheckIcon = styled(FaCheckCircle)`
  position: absolute;
  margin-left: -3%;
  margin-bottom: 20px;
  display: block;
  color: #22bb33;
  z-index: 10;
`;

const CloseIcon = styled(IoMdCloseCircle)`
  position: absolute; 
  margin-left: -3%;
  margin-bottom: 20px;
  display: block;
  color: #bb2124;
  z-index: 10;
`;

const WarningIcon2 = styled(TiWarning )`
  height:23px;
  width:auto;
  display: inline-block;
  color: #ffcc00;
  z-index: 99999;
  margin-left: 1px;
  vertical-align: middle;
  `;

  const H2 = styled.h2`
  margin-top:-5px;
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  `;

 

const DivFlex2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 780px) {
    flex-direction: column;
  }

  &.fade {
    animation: ${fadeAnimation} 0.5s;
  }

  &.fade-out {
    animation: ${fadeAnimation} 0.5s reverse;
  }
`;

const FormSection = styled.div`
  flex: 1;
  min-width: 300px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const TextComment = styled(TextArea)`
  width: 100%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`
const TextArea2 = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`;

const RadioGroup = styled.div`
  margin-bottom: 15px;
`;

const RadioInput2 = styled.input`
  margin-right: 10px;
`;

const WarningIcon = styled.span`
  margin-left: 5px;
  color: #f39c12;
`;


const DivFlex3 = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 780px) {
    flex-direction: column;
  }

  &.fade {
    animation: ${fadeAnimation} 0.5s;
  }

  &.fade-out {
    animation: ${fadeAnimation} 0.5s reverse;
  }
`;


const FieldP3 = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FormSection3 = styled.div`
  flex: 1;
  min-width: 300px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
`;

const FormLabel3 = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const TextArea3 = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  margin-bottom: 10px;
`;

const RadioGroup3 = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

const RadioInputWrapper = styled.div`
  flex: 1;
  min-width: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const RadioInput3 = styled.input`
  margin-right: 5px;
`;

const WarningIcon3 = styled.span`
  margin-left: 5px;
  color: #f39c12;
`;

const Table3 = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
    text-align: left;
  }

  td {
    background-color: #fff;
  }
`;




const DivFlex4 = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 780px) {
    flex-direction: column;
  }

  &.fade {
    animation: ${fadeAnimation} 0.5s;
  }

  &.fade-out {
    animation: ${fadeAnimation} 0.5s reverse;
  }
`;

const FormSection4 = styled.div`
  flex: 1;
  min-width: 300px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
`;

const FormLabel4 = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const TextArea4 = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  margin-bottom: 10px;
`;

const RadioGroup4 = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

const RadioInputWrapper4 = styled.div`
  flex: 1;
  min-width: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const RadioInput4 = styled.input`
  margin-right: 5px;
`;

const WarningIcon4 = styled.span`
  margin-left: 5px;
  color: #f39c12;
`;

const FieldP5 = styled(TextInput)`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
    text-align: left;
  }

  td {
    background-color: #fff;
  }
`;
const MultiStepFormRevision = () => {

    

    const [checked, setChecked] = useState(false);

    const [currentStep, setCurrentStep] = React.useState(0);

    const [errores, setErrores] = useState([]);

    const [animating, setAnimating] = useState(false);
    const [solicitud, setSolicitud] = useState([])
    const [user, setUser] = useState({});
    const [fecha,setFecha] = useState(0);
    const [solicitudDraft, setSolicitudDraft] = useState([])
    const [isDraft, setIsDraft] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [respuestas, setRespuestas] = useState([]);
    const [puntajes, setPuntajes ] = useState([])
    const [solicitudId , setSolicitudId] = useState(0);
    
    const [presupuestos, setPresupuestos] = useState([]);
    const [presupuestoGO, setPresupuestosGO] = useState([]);
    const [presupuestoInfra, setPresupuestosInfra] = useState([]);
  const [presupuestoHhrr, setPresupuestosHhrr] = useState([]);
    const [radioSolucion1, setRadioSolucion1] = useState(null);
    const [radioSolucion2, setRadioSolucion2] = useState(null);
    const [radioSolucion3, setRadioSolucion3] = useState(null);
    const [radioSolucion4, setRadioSolucion4] = useState(null);
    const [radioSolucion5, setRadioSolucion5] = useState(null);
    const [radioSolucion6, setRadioSolucion6] = useState(null);
    const [radioSolucion7, setRadioSolucion7] = useState(null);
    const [certificadoVigencia, setCertificadoVigencia] = useState([]);
    const [certificadoCedulaREP, setCertificadoCedulaREP] = useState([]);
    const [certificadoCedulaDire, setCertificadoCedulaDire] = useState([]);
    const [certificadoCedulaOtroDire, setCertificadoCedulaOtroDire] = useState([]);
    const [certificadoBancario, setCertificadoBancario] = useState([]);
    const [certificadoCartaCompromiso, setCertificadoCartaCompromiso] = useState([]);
    const [certificadoCotizacion, setCertificadoCotizacion] = useState([]);
    const [comentarios, setComentarios] = useState([]);
    const timeoutMethod = (callback, delay) => {
      setTimeout(callback, delay);
    };

    useEffect(() => {
      const userData = sessionStorage.getItem('userData');
      const user = JSON.parse(userData);
      const token = sessionStorage.getItem('token');
  
      setUser(user);
      if (user.rol === "Postulante") {
        window.location.href = '/feedPostulante';
        return; // Ensure the rest of the code is not executed
      }
  
      const queryParams = new URLSearchParams(window.location.search);
      const queryParamValue = queryParams.get('id_postulacion');
  
      if (queryParamValue) {
        setSolicitudId(queryParamValue);
        setIsDraft(true);

        try{
          fetchDocument(queryParamValue).then((certificado) => {
            if(certificado){
              
              certificado.forEach(element => {
                
                if(element.categoria==='Certificado de vigen'){
                  setCertificadoVigencia(element);
                }
                else if(element.categoria==='Certificado de cedul'){
                  setCertificadoCedulaREP(element);
              }else if(element.categoria ==='CertificadoBancario'){
                setCertificadoBancario(element);
              }
              else if(element.categoria==='CertificadoCartaComp'){
                setCertificadoCartaCompromiso(element);
              }
              else if(element.categoria==='Cedula directiva 2'){
                setCertificadoCedulaOtroDire(element);
              }
              else if(element.categoria==='Cedula directiva'){
                setCertificadoCedulaDire(element);
              }
              else if(element.categoria==='CertificadoCotizacio'){
                setCertificadoCotizacion(element);
              }
          })
        }
          })
        }catch(er){
          
        }

        const fetchData = async () => {
          try {
            const solicitudInfo = await getSolicitudInfoById(queryParamValue, token);
           try{
            const comentariosVar = await getComment(queryParamValue, token);
            setComentarios(comentariosVar);
           }catch(er){
            
           }
            setSolicitudDraft(solicitudInfo);
           
            
  
            if (solicitudInfo.id_proceso) {
              const solicitud = await getSolicitudById(solicitudInfo.id_proceso, token);
              setSolicitud(solicitud);
              calculateDaysRemaining(solicitud.fecha_fin);
            }
            
            const solicitudRespuestas = await fetchRespuestas(queryParamValue);
            setRespuestas(solicitudRespuestas[0]);
            const solicitudPuntajes = await fetchPuntajes(queryParamValue);
            setPuntajes(solicitudPuntajes);
            
            setRadioSolucion3(solicitudPuntajes.puntaje_problema);
            setRadioSolucion4(solicitudPuntajes.puntaje_beneficiario)
            setRadioSolucion1(solicitudPuntajes.puntaje_solucion)
            setRadioSolucion2(solicitudPuntajes.puntaje_objetivo);
            setRadioSolucion5(solicitudPuntajes.puntaje_actividades);
            setRadioSolucion6(solicitudPuntajes.puntaje_plan_difusion);
            setRadioSolucion7(solicitudPuntajes.puntaje_presupuesto);
            solicitudPuntajes.puntaje_actividad==5 ? setChecked(true) : setChecked(false);
            
          
            
          } catch (error) {
          
            console.error(error);
          } finally {
            timeoutMethod(() => {
              setIsLoading(false);
            }, 2000);
          }
        };
  
        fetchData();



      } else {
        setIsLoading(false);
      }
    }, []);
  
    const calculateDaysRemaining = (fecha_fin) => {
      const fechaObjetivo = new Date(fecha_fin);
      const fechaActual = new Date();
      const diferenciaMilisegundos = fechaObjetivo - fechaActual;
      const diasRestantes = Math.ceil(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
      setFecha(diasRestantes);
    };

    const nextStep = () => {
      setAnimating(true);
      
      setTimeout(() => {
        setCurrentStep(currentStep + 1);
        setTimeout(() => {
          setAnimating(false);
        }, 500); // delay before setting animating to false
      }, 0); // immediate change to currentStep
    };
    
    const prevStep = () => {
      setAnimating(true);
      setTimeout(() => {
        setCurrentStep(currentStep - 1);
        setTimeout(() => {
          setAnimating(false);
        }, 500); // delay before setting animating to false
      }, 0); // immediate change to currentStep
    };

    const handleClick = (step) => {
      if (!animating) {
        setCurrentStep(step);
      }
    };

    const validate = (values, currentStep) => {

      const  errors = {};
      const errorMessages = Object.values(errors);


       
        return errors;
    };

    const errorMessageStyle = {
      color: 'red',
    };


    const [goperacionalesMontoSolicitados, setGoperacionalesMontoSolicitados] = useState(Array(5).fill(0));


    const [goperacionalesTotal, setGoperacionalesTotal] = useState(0);
    
    useEffect(() => {
      var fill = []
      
      if(presupuestoGO){
        for (let i = 0; i < 5; i++) {
          fill.push(presupuestoGO[i])
        }
      }else{
        fill = [0,0,0,0,0]
      }

      setGoperacionalesMontoSolicitados(fill);
      var x = calculateTotal(fill)
      setGoperacionalesTotal(x);
    }, [presupuestoGO]);
   
   
    const handleGoperacionalesMontoSolicitadoChange = (index, event) => {
      const newGoperacionalesMontoSolicitados = [...goperacionalesMontoSolicitados];
      newGoperacionalesMontoSolicitados[index] = event.target.value;
      setGoperacionalesMontoSolicitados(newGoperacionalesMontoSolicitados);
     
      calculateTotal(newGoperacionalesMontoSolicitados);
    };

    const calculateTotal = (montoSolicitados) => {
      const total = montoSolicitados.reduce((acc, monto) => parseInt(acc) + parseInt(monto), 0);
      return total;
   
    };

    const [infraestructuraMontoSolicitados, setInfraestructuraMontoSolicitados] = useState(Array(2).fill(0));

    useEffect(() => {
      var fill = []
      if(presupuestoInfra){
        for (let i = 0; i < 2; i++) {
          fill.push(presupuestoInfra[i])
        }
      }else{
        fill = [0,0]
      }
      setInfraestructuraMontoSolicitados(fill);
      calculateInfraestructuraTotal(fill);
    }, [presupuestoInfra]);
    const [infraestructuraTotal, setInfraestructuraTotal] = useState(0);
  
    const handleInfraestructuraMontoSolicitadoChange = (index, event) => {
      const newInfraestructuraMontoSolicitados = [...infraestructuraMontoSolicitados];
      newInfraestructuraMontoSolicitados[index] = event.target.value;
      setInfraestructuraMontoSolicitados(newInfraestructuraMontoSolicitados);
      calculateInfraestructuraTotal(newInfraestructuraMontoSolicitados);
    };
  
    const calculateInfraestructuraTotal = (montoSolicitados) => {
      const total = montoSolicitados.reduce((acc, monto) => parseInt(acc) + parseInt(monto), 0);
      setInfraestructuraTotal(total);
    };

    const [recursosHumanosMontoSolicitados, setRecursosHumanosMontoSolicitados] = useState(Array(2).fill());
    useEffect(() => {
      var fill = []
      if(presupuestoHhrr){
        for (let i = 0; i < 2; i++) {
          fill.push(presupuestoHhrr[i])
        }
      }else{
        fill = [0,0]
      }
      setRecursosHumanosMontoSolicitados(fill);
      
      calculateRecursosHumanosTotal(fill);
    }, [presupuestoHhrr]);

    const [recursosHumanosTotal, setRecursosHumanosTotal] = useState(0);
  
    const handleRecursosHumanosMontoSolicitadoChange = (index, event) => {
      const newRecursosHumanosMontoSolicitados = [...recursosHumanosMontoSolicitados];
      newRecursosHumanosMontoSolicitados[index] = event.target.value;
      setRecursosHumanosMontoSolicitados(newRecursosHumanosMontoSolicitados);
      calculateRecursosHumanosTotal(newRecursosHumanosMontoSolicitados);
    };
  
    const calculateRecursosHumanosTotal = (montoSolicitados) => {
      
      const total = montoSolicitados.reduce((acc, monto) => parseInt(acc) + parseInt(monto), 0);
      setRecursosHumanosTotal(total);
    };



    const handleRadioChange = (name, value, multiplier) => {
      switch (name) {
        case "radioSolucion1":
          setRadioSolucion1(value * multiplier);
          break;
        case "radioSolucion2":
          setRadioSolucion2(value * multiplier);
          break;
        case "radioSolucion3":
          setRadioSolucion3(value * multiplier);
          break;
        case "radioSolucion4":
          setRadioSolucion4(value * multiplier);
          break;
        case "radioSolucion5":
          setRadioSolucion5(value * multiplier);
          break;
        case "radioSolucion6":
          setRadioSolucion6(value * multiplier);
          break;
        case "radioSolucion7":
          setRadioSolucion7(value * multiplier);
          break;
        default:
          break;
      }
    };
    
    const calculateTotalPTS = () => {
      let total =
      (radioSolucion1 || 0) +
      (radioSolucion2 || 0) +
      (radioSolucion3 || 0) +
      (radioSolucion4 || 0) +
      (radioSolucion5 || 0) +
      (radioSolucion6 || 0) +
      (radioSolucion7 || 0);
      
      if (checked) {
        total += 5;
      }
    
      return total;
    };

    const handleSubmit = (values) => {
      
      const puntajes = {
        id_solicitud : parseInt(solicitudId),
        puntaje_problema : radioSolucion3,
        puntaje_beneficiario : radioSolucion4,
        puntaje_solucion : radioSolucion1,
        puntaje_objetivo : radioSolucion2,
        puntaje_actividades : radioSolucion5,
        puntaje_plan_difusion : radioSolucion6,
        puntaje_presupuesto : radioSolucion7,
        puntaje_actividad : checked ? 5 : 0
      }

      const comentarios = {
        id_solicitud : parseInt(solicitudId),
        observacion_idef_problema : values.observacion_idef_problema ? values.observacion_idef_problema : '',
        observacion_beneficiarios : values.observacion_beneficiarios ? values.observacion_beneficiarios : '',
        observacion_solucion : values.observacion_solucion ? values.observacion_solucion : '',
        observacion_objetivos : values.observacion_objetivo ? values.observacion_objetivo : '',
        observacion_actividades : values.observacion_actividades ? values.observacion_actividades : '',
        observacion_plan_difusion : values.observacion_plan_difusion ? values.observacion_plan_difusion : '',
        observacion_presupuesto : values.observacion_presupuesto ? values.observacion_presupuesto : '',
        observacion_descripcion_proyecto : values.observacion_descripcion_proyecto ? values.observacion_descripcion_proyecto : ''
      }
     
      const totalPTS = calculateTotalPTS(values);
      evaluarSolicitud(puntajes).then((response) => {

      }).finally(() => {
        var puntajex ={
          id_solicitud: parseInt(solicitudId),
          estado:'Revisado',
          Puntaje: totalPTS
        }
        evaluarPuntaje(puntajex).then((response) => {
          

          createComment(comentarios).then((response) => {
            alert("Solicitud evaluada exitosamente");
            window.location.href = '/feedEvaluador';
          })

          
        })
      })
       
      

    };

    if (isLoading) {
      return <div>Loading...</div>; // Sh
    }
  

  return (
    <Formik
    initialValues={{
      NombreProyecto: isDraft ? solicitudDraft.nombre_proyecto :'',
      NumBeneficiarios: isDraft ? solicitudDraft.numero_beneficiarios : '',
      radio: isDraft ? solicitudDraft.linea_financiamiento : '',
      AporteSolicitado: isDraft ? solicitudDraft.aporte_solicitado : '',
      observacion_idef_problema: isDraft  ? comentarios.observacion_idef_problema : '',
      observacion_beneficiarios: isDraft  ? comentarios.observacion_beneficiarios : '',
      observacion_solucion: isDraft  ? comentarios.observacion_solucion : '',
      observacion_objetivos: isDraft  ? comentarios.observacion_objetivo : '',
      observacion_actividades: isDraft  ? comentarios.observacion_actividades : '',
      observacion_plan_difusion: isDraft  ? comentarios.observacion_plan_difusion : '',
      observacion_presupuesto: isDraft ?  comentarios.observacion_presupuesto : '',
      observacion_descripcion_proyecto: isDraft  ? comentarios.observacion_descripcion_proyecto : '',
      NombreOrg:  isDraft ? solicitudDraft.nombre_organizacion : '',
      RUTOrg: isDraft ? solicitudDraft.rut_organizacion : '',
      DirOrg: isDraft ? solicitudDraft.direccion_org : '',
      NombreRepLegal: isDraft ? solicitudDraft.nombre_representante_legal : '',
      RUTRepLegal:  isDraft ? solicitudDraft.rut_representante_legal : '',
      DirRepLegal: isDraft ? solicitudDraft.direccion_rep_legal : '',
      TelRepLegal:  isDraft ? solicitudDraft.telefono_rep_legal : '',
      CorreoRepLegal: isDraft ? solicitudDraft.correo_rep_legal : '',
      NombreRespProyecto: isDraft ? solicitudDraft.nombre_responsable_proy : '',
      RUTRespProyecto:  isDraft ? solicitudDraft.rut_responsable_proy : '',
      DirRespProyecto: isDraft ? solicitudDraft.direccion_responsable_proy : '',
      TelRespProyecto: isDraft ? solicitudDraft.telefono_responsable_proy : '',
      CorreoRespProyecto: isDraft ? solicitudDraft.correo_responsable_proy : '',

      IdProblema: isDraft ? respuestas.identificacion_problema : '', 
      Beneficiarios: isDraft ? respuestas.beneficiarios : '',
      DesProyecto: isDraft ? respuestas.descripcion_proyecto : '',
      ObjetivoGeneral: isDraft ? respuestas.objetivo_general : '',
      ObjetivoEspec1: isDraft ? respuestas.objetivo_especifico_1 : '',
      ObjetivoEspec2: isDraft ? respuestas.objetivo_especifico_2 : '',
      ObjetivoEspec3: isDraft ? respuestas.objetivo_especifico_3 : '',

      ActividadesNombre_0: isDraft ? respuestas.nombre_actividad_0 : '',
      ActividadesDescripcion_0: isDraft ? respuestas.descripcion_actividad_0 : '',
      ActividadesNombre_1: isDraft ? respuestas.nombre_actividad_1 : '',
      ActividadesDescripcion_1: isDraft ? respuestas.descripcion_actividad_1 : '',
      ActividadesNombre_2: isDraft ? respuestas.nombre_actividad_2 : '',
      ActividadesDescripcion_2: isDraft ? respuestas.descripcion_actividad_2 : '',
      ActividadesNombre_3: isDraft ? respuestas.nombre_actividad_3 : '',
      ActividadesDescripcion_3: isDraft ? respuestas.descripcion_actividad_3 : '',
      ActividadesNombre_4: isDraft ? respuestas.nombre_actividad_4 : '',
      ActividadesDescripcion_4: isDraft ? respuestas.descripcion_actividad_4 : '',
      PlanDifusion: isDraft ? solicitudDraft.plan_difusion : '',


      GoperacionalesItem_0: isDraft ? solicitudDraft.gasto_operacional_item_0 : '',
      GoperacionalesUtilizacion_0: isDraft ? solicitudDraft.gasto_operacional_utilizacion_0 : '',
      GoperacionalesMontoSolicitado_0: isDraft ? solicitudDraft.gasto_operacional_monto_0 : '',
      GoperacionalesItem_1: isDraft ? solicitudDraft.gasto_operacional_item_1 : '',
      GoperacionalesUtilizacion_1: isDraft ? solicitudDraft.gasto_operacional_utilizacion_1 : '',
      GoperacionalesMontoSolicitado_1: isDraft ? solicitudDraft.gasto_operacional_monto_1 : '',
      GoperacionalesItem_2: isDraft ? solicitudDraft.gasto_operacional_item_2 : '',
      GoperacionalesUtilizacion_2: isDraft ? solicitudDraft.gasto_operacional_utilizacion_2 : '',
      GoperacionalesMontoSolicitado_2: isDraft ? solicitudDraft.gasto_operacional_monto_2 : '',
      GoperacionalesItem_3: isDraft ? solicitudDraft.gasto_operacional_item_3 : '',
      GoperacionalesUtilizacion_3: isDraft ? solicitudDraft.gasto_operacional_utilizacion_3 : '',
      GoperacionalesMontoSolicitado_3: isDraft ? solicitudDraft.gasto_operacional_monto_3 : '',
      GoperacionalesExtraTotal : parseInt(solicitudDraft.gasto_operacional_monto_0)+parseInt(solicitudDraft.gasto_operacional_monto_1)+parseInt(solicitudDraft.gasto_operacional_monto_2)+parseInt(solicitudDraft.gasto_operacional_monto_3)+parseInt(solicitudDraft.gasto_operacional_monto_4),

      GoperacionalesItem_4: isDraft ? solicitudDraft.gasto_operacional_item_4 : '',
      GoperacionalesUtilizacion_4: isDraft ? solicitudDraft.gasto_operacional_utilizacion_4 : '',
      GoperacionalesMontoSolicitado_4: isDraft ? solicitudDraft.gasto_operacional_monto_4 : '',
      InfraestructuraItem_0: isDraft ? solicitudDraft.infra_item_0 : '',
      InfraestructuraUtilizacion_0: isDraft ? solicitudDraft.infra_utilizacion_0 : '',
      InfraestructuraMontoSolicitado_0: isDraft ? solicitudDraft.infra_monto_0 : '',
      InfraestructuraItem_1: isDraft ? solicitudDraft.infra_item_1 : '',
      InfraestructuraUtilizacion_1: isDraft ? solicitudDraft.infra_utilizacion_1 : '',
      InfraestructuraMontoSolicitado_1: isDraft ? solicitudDraft.infra_monto_1 : '',
      InfraestructuraExtraTotal : parseInt(solicitudDraft.infra_monto_0)+parseInt(solicitudDraft.infra_monto_1),

      RecursosHumanosItem_0: isDraft ? solicitudDraft.rrhh_item_0 : '',
      RecursosHumanosUtilizacion_0: isDraft ? solicitudDraft.rrhh_utilizacion_0 : '',
      RecursosHumanosMontoSolicitado_0: isDraft ? solicitudDraft.rrhh_monto_0 : '',
      RecursosHumanosItem_1: isDraft ? solicitudDraft.rrhh_item_1 : '',
      RecursosHumanosUtilizacion_1: isDraft ? solicitudDraft.rrhh_utilizacion_1 : '',
      RecursosHumanosMontoSolicitado_1: isDraft ? solicitudDraft.rrhh_monto_1 : '',
      RecursosHumanosExtraTotal : parseInt(solicitudDraft.rrhh_monto_0)+parseInt(solicitudDraft.rrhh_monto_1),



    }}
      validate={(values) => validate(values, currentStep)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
         <DivContenedorMax>
          <DivNavbar>
          <Navbar name={user.nombre_usuario} />
        </DivNavbar>
        <Div>
          <ProgressBar>
          <StepProgress>
              <Step className={`${currentStep >= 0 ? 'active' : ''}`}>
                <Bullet active={(currentStep >= 0).toString()} onClick={() => handleClick(0)}><span>1</span></Bullet>
                <StepTitle>Resumen</StepTitle>
             
              </Step>

              <Step className={`${currentStep >= 1 ? 'active' : ''}`}>
                <Bullet active={(currentStep >= 1).toString()} onClick={() => handleClick(1)}><span>2</span></Bullet>
                <StepTitle>Org. Postulante</StepTitle>
               
              </Step>

              <Step className={`${currentStep >= 2 ? 'active' : ''}`}>
                <Bullet active={(currentStep >= 2).toString()} onClick={() => handleClick(2)}><span>3</span></Bullet>
                <StepTitle>Problema/Solucion</StepTitle>
                {(radioSolucion1 !== null && 
                radioSolucion2 !== null && 
                radioSolucion3 !== null && 
                radioSolucion4 !== null) ? 
                <CheckIcon /> : 
               <CloseIcon />}
                  
              </Step>

              <Step className={`${currentStep >= 3 ? 'active' : ''}`}>
                <Bullet active={(currentStep >= 3).toString()} onClick={() => handleClick(3)}><span>4</span></Bullet>
                <StepTitle>Planificacion</StepTitle>
                {(radioSolucion5 !== null && 
                radioSolucion6 !== null) ? 
                <CheckIcon /> : 
                <CloseIcon />}
              </Step>

              <Step className={`${currentStep >= 4 ? 'active' : ''}`}>
                <Bullet active={(currentStep >= 4).toString()} onClick={() => handleClick(4)}><span>5</span></Bullet>
                <StepTitle>Presupuesto</StepTitle>
                {(radioSolucion7 !== null) ? 
               <CheckIcon /> : 
               <CloseIcon />}
              </Step>

              <Step className={`${currentStep >= 5 ? 'active' : ''}`}>
                <Bullet active={(currentStep >= 5).toString()} onClick={() => handleClick(5)}><span>6</span></Bullet>
                <StepTitle>Adjuntos</StepTitle>
              </Step>
            </StepProgress>
          </ProgressBar>
          <SaveIcon>Puntaje obtenido: {calculateTotalPTS()}</SaveIcon>
        <DivForm>
          {isLoading ? ( <Skeleton width="100%" height={200} />) : 
          (
            <Form>
            {currentStep === 0 && (
                <DivFlex className={animating ? 'fade' : ''}>
                <div>
            
                  <H2>Resumen</H2>
                      <TextInput disabled name="NombreProyecto" label="Nombre Proyecto" readOnly/>
                      <br />
                      <NumberInput disabled name="NumBeneficiarios" max={2} label="Número de beneficiarios directos del proyecto" readOnly/>
                      <br />
                      <Label>¿Cuál es la línea de financiamiento a la que postula con este proyecto?:</Label>
  
                      <RadioInput disabled name="radio" value="Bienestar Físico, Deporte y Recreación" label="Bienestar Físico, Deporte y Recreación" />
                      <RadioInput disabled name="radio" value="Bienestar Psicosocial" label="Bienestar Psicosocial" />
                      <RadioInput disabled name="radio" value="Formación y Capacitación" label="Formación y Capacitación" />
                      <RadioInput disabled name="radio" value="Protección y cuidado del medio ambiente" label="Protección y cuidado del medio ambiente" />
                      <RadioInput disabled name="radio" value="Educación y Artes" label="Educación y Artes" />
                      <RadioInput disabled name="radio" value="Cultura e Identidad Local" label="Cultura e Identidad Local" />
                      <RadioInput disabled name="radio" value="Infraestructura Comunitaria e Insumos" label="Infraestructura Comunitaria e Insumos" />
                      <ErrorMessage disabled name="radio" component="div" style={errorMessageStyle} />
                      <br />
                      <NumberInput disabled name="AporteSolicitado" showPrefix={true} label="Aporte Solicitado" readOnly/>
                      <br />
                      </div>
                </DivFlex>
  
              )}
              {currentStep === 1 && (
  
                <DivFlex className={animating ? 'fade' : ''}>
                <div>
                <h2>Org. Postulante</h2>
                <DivFlex > 
  
                  <div>
                   
                    <TextInput disabled name="NombreOrg" label="Nombre de la Organización" readOnly/>
                    <br />
                    <TextInput disabled name="RUTOrg" label="R.U.T de la organización" readOnly/>
                    <br />
                    <TextInput disabled name="DirOrg" label="Dirección de la organización" readOnly/>
                    <br />
                    <TextInput disabled name="NombreRepLegal" label="Nombre del/la representante legal" readOnly/>
                    <br />
                    <TextInput disabled name="RUTRepLegal" label="R.U.T del/la representante legal" readOnly/>
                    <br />
                    <TextInput disabled name="DirRepLegal" label="Dirección del/la representante legal" readOnly/>
                    <br />
                  </div>
                  <div>
                    <TextInput disabled name="TelRepLegal" label="Teléfono del/la representante legal" readOnly/>
                    <br />
                    <TextInput disabled name="CorreoRepLegal" label="Correo electrónico del/la representante legal" readOnly/>
                    <br />
                    <TextInput disabled name="NombreRespProyecto" label="Nombre del/la Responsable del Proyecto" readOnly/>
                    <br />
                    <TextInput disabled name="RUTRespProyecto" label="R.U.T del/la responsable del proyecto" readOnly/>
                    <br />
                    <TextInput disabled name="DirRespProyecto" label="Dirección del/la responsable del proyecto" readOnly/>
                    <br />
                    <TextInput disabled name="TelRespProyecto" label="Teléfono del/la responsable del proyecto" readOnly/>
                    <br />
                    <TextInput disabled name="CorreoRespProyecto" label="Correo electrónico del/la representante del proyecto" readOnly/>
                    <br />
                  </div>
                </DivFlex>
                </div>
                </DivFlex>
  
              )}
              {currentStep === 2 && (
  
              <DivFlex2 className={animating ? 'fade' : ''}>
              <FormSection>
                <h2>Problema</h2>
                <FormLabel>Identificación del problema y justificación del proyecto</FormLabel>
                <TextArea2 disabled name="IdProblema" value={values.IdProblema} readOnly/>
                <FormLabel>Puntaje Identificación del Problema: (20Pts)<WarningIcon>⚠️</WarningIcon></FormLabel>
                <RadioGroup onChange={(e) => {
                  var comentario = document.getElementById('comentario_idenfificacion');
                  comentario.style.display = 'block';
                  }} >
                  <RadioInput2 type="radio"  name="radioSolucion3" value="0" checked={radioSolucion3 === 0 * 20} onChange={() => handleRadioChange("radioSolucion3", 0, 20)} />MALO
                  <RadioInput2 type="radio"  name="radioSolucion3" value="0.3" checked={radioSolucion3 === 0.3 * 20} onChange={() => handleRadioChange("radioSolucion3", 0.3, 20)} />INSUFICIENTE
                  <RadioInput2 type="radio"  name="radioSolucion3" value="0.5" checked={radioSolucion3 === 0.5 * 20} onChange={() => handleRadioChange("radioSolucion3", 0.5, 20)} />BUENO
                  <RadioInput2 type="radio"  name="radioSolucion3" value="0.7" checked={radioSolucion3 === 0.7 * 20} onChange={() => handleRadioChange("radioSolucion3", 0.7, 20)} />MUY BUENO
                  <RadioInput2 type="radio"  name="radioSolucion3" value="1" checked={radioSolucion3 === 1 * 20} onChange={() => handleRadioChange("radioSolucion3", 1, 20)} />EXCELENTE
                </RadioGroup>
                <div id="comentario_idenfificacion" >
                <FormLabel>Comentario identificación del problema</FormLabel>
                <TextComment  name='observacion_idef_problema' ></TextComment>
                </div>
                <FormLabel>Beneficiarios y beneficiarias</FormLabel>
                <TextArea2 disabled name="Beneficiarios" value={values.Beneficiarios} readOnly/>
                <FormLabel>Puntaje Beneficiarios: (15Pts)<WarningIcon>⚠️</WarningIcon></FormLabel>
                <RadioGroup onChange={
                  (e) => {
                    var comentario = document.getElementById('comentario_beneficiario');
                    comentario.style.display = 'block';
                  }
                }>
                  <RadioInput2 type="radio"  name="radioSolucion4" value="0" checked={radioSolucion4 === 0 * 15} onChange={() => handleRadioChange("radioSolucion4", 0, 15)} />MALO
                  <RadioInput2 type="radio"  name="radioSolucion4" value="0.3" checked={radioSolucion4 === 0.3 * 15} onChange={() => handleRadioChange("radioSolucion4", 0.3, 15)} />INSUFICIENTE
                  <RadioInput2 type="radio"  name="radioSolucion4" value="0.5" checked={radioSolucion4 === 0.5 * 15} onChange={() => handleRadioChange("radioSolucion4", 0.5, 15)} />BUENO
                  <RadioInput2 type="radio"  name="radioSolucion4" value="0.7" checked={radioSolucion4 === 0.7 * 15} onChange={() => handleRadioChange("radioSolucion4", 0.7, 15)} />MUY BUENO
                  <RadioInput2 type="radio"  name="radioSolucion4" value="1" checked={radioSolucion4 === 1 * 15} onChange={() => handleRadioChange("radioSolucion4", 1, 15)} />EXCELENTE
                </RadioGroup>
                <div id="comentario_beneficiario" >
                <FormLabel>Comentario Beneficairios y Beneficiarias</FormLabel>
               <TextComment name='observacion_beneficiarios'></TextComment>
                </div>
              </FormSection>
              <FormSection>
                <h2>Solución</h2>
                <FormLabel>Descripción general del proyecto</FormLabel>
                <TextArea2 disabled name="DesProyecto" value={values.DesProyecto} readOnly/>
                <FormLabel>Propuesta de solución: (20Pts)<WarningIcon>⚠️</WarningIcon></FormLabel>
                <RadioGroup onChange={
                  (e) => {
                    var comentario = document.getElementById('comentario_descripcion');
                    comentario.style.display = 'block';
                  }
                }>
                  <RadioInput2 type="radio"  name="radioSolucion1" value="0" checked={radioSolucion1 === 0 * 20} onChange={() => handleRadioChange("radioSolucion1", 0, 20)} />MALO
                  <RadioInput2 type="radio"  name="radioSolucion1" value="0.3" checked={radioSolucion1 === 0.3 * 20} onChange={() => handleRadioChange("radioSolucion1", 0.3, 20)} />INSUFICIENTE
                  <RadioInput2 type="radio"  name="radioSolucion1" value="0.5" checked={radioSolucion1 === 0.5 * 20} onChange={() => handleRadioChange("radioSolucion1", 0.5, 20)} />BUENO
                  <RadioInput2 type="radio"  name="radioSolucion1" value="0.7" checked={radioSolucion1 === 0.7 * 20} onChange={() => handleRadioChange("radioSolucion1", 0.7, 20)} />MUY BUENO
                  <RadioInput2 type="radio"  name="radioSolucion1" value="1" checked={radioSolucion1 === 1 * 20} onChange={() => handleRadioChange("radioSolucion1", 1, 20)} />EXCELENTE
                </RadioGroup>
                <div id="comentario_descripcion" >
                <FormLabel>Comentario descripción del proyecto</FormLabel>
               <TextComment name='observacion_descripcion_proyecto'></TextComment>
                </div>
                <FormLabel>Objetivo General</FormLabel>
                <TextArea2 disabled name="ObjetivoGeneral" value={values.ObjetivoGeneral} readOnly/>
                <FormLabel>Objetivo Específico 1</FormLabel>
                <TextArea2 disabled name="ObjetivoEspec1" value={values.ObjetivoEspec1} readOnly/>
                <FormLabel>Objetivo Específico 2</FormLabel>
                <TextArea2 disabled name="ObjetivoEspec2" value={values.ObjetivoEspec2} readOnly/>
                <FormLabel>Objetivo Específico 3</FormLabel>
                <TextArea2 disabled name="ObjetivoEspec3" value={values.ObjetivoEspec3} readOnly/>
                <FormLabel>Objetivos: (15Pts)<WarningIcon>⚠️</WarningIcon></FormLabel>
                <RadioGroup onChange={
                  (e) => {
                    var comentario = document.getElementById('comentario_objetivo');
                    comentario.style.display = 'block';
                  }
                }>
                  <RadioInput2 type="radio"  name="radioSolucion2" value="0" checked={radioSolucion2 === 0 * 15} onChange={() => handleRadioChange("radioSolucion2", 0, 15)} />MALO
                  <RadioInput2 type="radio"  name="radioSolucion2" value="0.3" checked={radioSolucion2 === 0.3 * 15} onChange={() => handleRadioChange("radioSolucion2", 0.3, 15)} />INSUFICIENTE
                  <RadioInput2 type="radio"  name="radioSolucion2" value="0.5" checked={radioSolucion2 === 0.5 * 15} onChange={() => handleRadioChange("radioSolucion2", 0.5, 15)} />BUENO
                  <RadioInput2 type="radio"  name="radioSolucion2" value="0.7" checked={radioSolucion2 === 0.7 * 15} onChange={() => handleRadioChange("radioSolucion2", 0.7, 15)} />MUY BUENO
                  <RadioInput2 type="radio"  name="radioSolucion2" value="1" checked={radioSolucion2 === 1 * 15} onChange={() => handleRadioChange("radioSolucion2", 1, 15)} />EXCELENTE
                </RadioGroup>

                <div id="comentario_objetivo" >
                <FormLabel>Comentario objetivo</FormLabel>
               <TextComment name='observacion_objetivos'></TextComment>
                </div>
              </FormSection>
              </DivFlex2>
  
              )}
              {currentStep === 3 && (
  
              <DivFlex3 className={animating ? 'fade' : ''}>
              <FormSection3>
                <h2>Planificación</h2>
                <FormLabel3>Actividades del Proyecto:</FormLabel3>
                <Table3>
                  <thead>
                    <tr>
                      <th>Nombre de la Actividad</th>
                      <th>Descripción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <tr key={index}>
                        <td>
                          <FieldP3 disabled name={`ActividadesNombre_${index}`} value={values[`ActividadesNombre_${index}`]} placeholder="Nombre de la actividad" readOnly/>
                        </td>
                        <td>
                          <FieldP3 disabled name={`ActividadesDescripcion_${index}`} value={values[`ActividadesDescripcion_${index}`]} placeholder="Descripción" readOnly/>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table3>
                <FormLabel3>Actividades: (10Pts)<WarningIcon>⚠️</WarningIcon></FormLabel3>
                <RadioGroup3 onChange={(e) => {
                  var comentario = document.getElementById('comentario_actividades');
                  comentario.style.display = 'block';
                  }
                }>
                  <RadioInputWrapper>
                    <RadioInput3 type="radio" name="radioSolucion5" value="0" checked={radioSolucion5 === 0 * 10} onChange={() => handleRadioChange("radioSolucion5", 0, 10)} />
                    <span>MALO</span>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput3 type="radio" name="radioSolucion5" value="0.3" checked={radioSolucion5 === 0.3 * 10} onChange={() => handleRadioChange("radioSolucion5", 0.3, 10)} />
                    <span>INSUFICIENTE</span>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput3 type="radio" name="radioSolucion5" value="0.5" checked={radioSolucion5 === 0.5 * 10} onChange={() => handleRadioChange("radioSolucion5", 0.5, 10)} />
                    <span>BUENO</span>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput3 type="radio" name="radioSolucion5" value="0.7" checked={radioSolucion5 === 0.7 * 10} onChange={() => handleRadioChange("radioSolucion5", 0.7, 10)} />
                    <span>MUY BUENO</span>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput3 type="radio" name="radioSolucion5" value="1" checked={radioSolucion5 === 1 * 10} onChange={() => handleRadioChange("radioSolucion5", 1, 10)} />
                    <span>EXCELENTE</span>
                  </RadioInputWrapper>
                </RadioGroup3>
                <div  id="comentario_actividades" >
                <FormLabel>Comentario actividades</FormLabel>
                <TextComment name='observacion_actividades'></TextComment>
                </div>
                <FormLabel3>Plan de difusión</FormLabel3>
                <TextArea2 value={values.PlanDifusion} disabled name="PlanDifusion"/>
                <FormLabel3>Propuesta de difusión (5Pts)<WarningIcon>⚠️</WarningIcon></FormLabel3>
                <RadioGroup3 onChange={
                  (e) => {
                    var comentario = document.getElementById('comentario_difusion');
                    comentario.style.display = 'block';
                  }
                }>
                  <RadioInputWrapper>
                    <RadioInput3 type="radio" name="radioSolucion6" value="0" checked={radioSolucion6 === 0 * 5} onChange={() => handleRadioChange("radioSolucion6", 0, 5)} />
                    <span>MALO</span>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput3 type="radio" name="radioSolucion6" value="0.3" checked={radioSolucion6 === 0.3 * 5} onChange={() => handleRadioChange("radioSolucion6", 0.3, 5)} />
                    <span>INSUFICIENTE</span>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput3 type="radio" name="radioSolucion6" value="0.5" checked={radioSolucion6 === 0.5 * 5} onChange={() => handleRadioChange("radioSolucion6", 0.5, 5)} />
                    <span>BUENO</span>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput3 type="radio" name="radioSolucion6" value="0.7" checked={radioSolucion6 === 0.7 * 5} onChange={() => handleRadioChange("radioSolucion6", 0.7, 5)} />
                    <span>MUY BUENO</span>
                  </RadioInputWrapper>
                  <RadioInputWrapper>
                    <RadioInput3 type="radio" name="radioSolucion6" value="1" checked={radioSolucion6 === 1 * 5} onChange={() => handleRadioChange("radioSolucion6", 1, 5)} />
                    <span>EXCELENTE</span>
                  </RadioInputWrapper>
                </RadioGroup3>
                <div  id="comentario_difusion" >
                <FormLabel>Comentario propuesta de difusión</FormLabel>
                <TextComment name='observacion_plan_difusion'></TextComment>
                </div>
              </FormSection3>
              </DivFlex3>
  
              )}
              {currentStep === 4 && (
  
  <DivFlex className={animating ? 'fade' : ''}>
  <FormSection>
    <h2>Presupuesto</h2>
    <FormLabel>Gastos Operacionales:</FormLabel>
    <Table>
      <thead>
        <tr>
          <th>Item</th>
          <th>Utilización</th>
          <th>Monto solicitado</th>
        </tr>
      </thead>
      <tbody>
        {goperacionalesMontoSolicitados.map((value, index) => (
          <tr key={index}>
            <td>
              <FieldP5 disabled name={`GoperacionalesItem_${index}`} value={values[`GoperacionalesItem_${index}`]}placeholder="Item" readOnly/>
            </td>
            <td>
              <FieldP5 disabled name={`GoperacionalesUtilizacion_${index}`} value={values[`GoperacionalesUtilizacion_${index}`]} placeholder="Utilización" readOnly/>
            </td>
            <td>
              <FieldP5
                disabled name={`GoperacionalesMontoSolicitado_${index}`}
                placeholder="Monto solicitado"
                type='number'
                showPrefix={true}
                width={100}
                value={values[`GoperacionalesMontoSolicitado_${index}`]}
                onChange={(e) => handleGoperacionalesMontoSolicitadoChange(index, e)}
                readOnly
              />
            </td>
          </tr>
        ))}
        <tr key={4}>
          <td></td>
          <td></td>
          <td>
            <FieldP5 disabled name={`GoperacionalesExtraTotal`} value={values.GoperacionalesExtraTotal} type='number'
                showPrefix={true} readOnly/>
          </td>
        </tr>
      </tbody>
    </Table>
    <ErrorMessage name={`GoperacionalesItem_0`} component="div"   style={{ color: 'red' }} />
    <ErrorMessage name={`GoperacionalesUtilizacion_0`} component="div" style={{ color: 'red' }} />
    <ErrorMessage name={`GoperacionalesMontoSolicitado_0`} component="div" style={{ color: 'red' }} />
    
    <FormLabel>Infraestructura:</FormLabel>
    <Table>
      <thead>
        <tr>
          <th>Item</th>
          <th>Utilización</th>
          <th>Monto solicitado</th>
        </tr>
      </thead>
      <tbody>
        {infraestructuraMontoSolicitados.map((value, index) => (
          <tr key={index}>
            <td>
              <FieldP5 disabled name={`InfraestructuraItem_${index}`} value={values[`InfraestructuraItem_${index}`]} placeholder="Item" readOnly/>
            </td>
            <td>
              <FieldP5 disabled name={`InfraestructuraUtilizacion_${index}`} value={values[`InfraestructuraUtilizacion_${index}`]}  placeholder="Utilización" readOnly/>
            </td>
            <td>
              <FieldP5
                disabled name={`InfraestructuraMontoSolicitado_${index}`}
                placeholder="Monto solicitado"
                type='number'
                showPrefix={true}
                value={values[`InfraestructuraMontoSolicitado_${index}`]}
                onChange={(e) => handleInfraestructuraMontoSolicitadoChange(index, e)}
                readOnly
              />
            </td>
          </tr>
        ))}
        <tr key={4}>
          <td></td>
          <td></td>
          <td>
            <FieldP5
              disabled name="InfraestructuraExtraTotal"
              placeholder="TOTAL"
              type='number'
              showPrefix={true}
              readOnly
              value={values.InfraestructuraExtraTotal}
            />
          </td>
        </tr>
      </tbody>
    </Table>
    <ErrorMessage name={`InfraestructuraItem_0`} component="div" style={{ color: 'red' }} />
    <ErrorMessage name={`InfraestructuraUtilizacion_0`} component="div" style={{ color: 'red' }} />
    <ErrorMessage name={`InfraestructuraMontoSolicitado_0`} component="div" style={{ color: 'red' }} />
    
    <FormLabel>Recursos Humanos:</FormLabel>
    <Table>
      <thead>
        <tr>
          <th>Item</th>
          <th>Utilización</th>
          <th>Monto solicitado</th>
        </tr>
      </thead>
      <tbody>
        {recursosHumanosMontoSolicitados.map((value, index) => (
          <tr key={index}>
            <td>
              <FieldP5 disabled name={`RecursosHumanosItem_${index}`} value={values[`RecursosHumanosItem_${index}`]}  placeholder="Item" readOnly/>
            </td>
            <td>
              <FieldP5 disabled name={`RecursosHumanosUtilizacion_${index}`} value={values[`RecursosHumanosUtilizacion_${index}`]} placeholder="Utilización" readOnly/>
            </td>
            <td>
              
              <FieldP5
                              disabled name={`RecursosHumanosMontoSolicitado_${index}`}
                              placeholder="Monto solicitado"
                              type='number'
                              showPrefix={true}
                             
                              value={values[`RecursosHumanosMontoSolicitado_${index}`]}
                              onChange={(e) => handleRecursosHumanosMontoSolicitadoChange(index, e)} 
                              readOnly
                            />
            </td>
          </tr>
        ))}
        <tr key={4}>
          <td></td>
          <td></td>
          <td>
            <FieldP5
              disabled name="RecursosHumanos[2].extra"
              placeholder="TOTAL"
              readOnly
              type='number'
              showPrefix={true}
              value={values.RecursosHumanosExtraTotal}
              
            />
          </td>
        </tr>
      </tbody>
    </Table>
    <ErrorMessage name={`RecursosHumanosItem_0`} component="div" style={{ color: 'red' }} />
    <ErrorMessage name={`RecursosHumanosUtilizacion_0`} component="div" style={{ color: 'red' }} />
    <ErrorMessage name={`RecursosHumanosMontoSolicitado_0`} component="div" style={{ color: 'red' }} />
    
    <FormLabel>Presupuesto: (10Pts)<WarningIcon>⚠️</WarningIcon></FormLabel>
    <RadioGroup onChange={
      (e) => {
        var comentario = document.getElementById('comentario_presupuesto');
        comentario.style.display = 'block';
      }
    }>
      <RadioInputWrapper>
        <RadioInput type="radio" name="radioSolucion7" value="0" checked={radioSolucion7 === 0 * 10} onChange={() => handleRadioChange("radioSolucion7", 0, 10)} />
        <span>MALO</span>
      </RadioInputWrapper>
      <RadioInputWrapper>
        <RadioInput type="radio" name="radioSolucion7" value="0.3" checked={radioSolucion7 === 0.3 * 10} onChange={() => handleRadioChange("radioSolucion7", 0.3, 10)} />
        <span>INSUFICIENTE</span>
      </RadioInputWrapper>
      <RadioInputWrapper>
        <RadioInput type="radio" name="radioSolucion7" value="0.5" checked={radioSolucion7 === 0.5 * 10} onChange={() => handleRadioChange("radioSolucion7", 0.5, 10)} />
        <span>BUENO</span>
      </RadioInputWrapper>
      <RadioInputWrapper>
        <RadioInput type="radio" name="radioSolucion7" value="0.7" checked={radioSolucion7 === 0.7 * 10} onChange={() => handleRadioChange("radioSolucion7", 0.7, 10)} />
        <span>MUY BUENO</span>
      </RadioInputWrapper>
      <RadioInputWrapper>
        <RadioInput type="radio" name="radioSolucion7" value="1" checked={radioSolucion7 === 1 * 10} onChange={() => handleRadioChange("radioSolucion7", 1, 10)} />
        <span>EXCELENTE</span>
      </RadioInputWrapper>
    </RadioGroup>  
    <div  id="comentario_presupuesto" >
                <FormLabel>Comentario presupuesto</FormLabel>
                <TextComment name='observacion_presupuesto'></TextComment>
                </div>
  </FormSection>
  </DivFlex>
  
              )}
              {currentStep === 5 && (
  
                  <DivFlex className={animating ? 'fade' : ''}>
                  <div>
                  <h2>Adjuntos</h2>
                  <FileUpload onChange={(e) => {setCertificadoVigencia(e.target.files)}} name="certificado1" label="Certificado de vigencia de la organización (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay  certificado={certificadoVigencia} clearCertificado={setCertificadoVigencia}/>
     
          <FileUpload onChange={(e) => {setCertificadoCedulaREP(e.target.files)}} name="certificado2" label="Cédula de Identidad del representante legal de la organización (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay
        certificado={certificadoCedulaREP}
        clearCertificado={setCertificadoCedulaREP}
      />
          <FileUpload onChange={(e) => {setCertificadoCedulaDire(e.target.files)}}name="certificado3" label="Cédula de Identidad de un integrante de la directiva. (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay
        certificado={certificadoCedulaDire}
        clearCertificado={setCertificadoCedulaDire}
      />
          <FileUpload onChange={(e) => {setCertificadoCedulaOtroDire(e.target.files)}} name="certificado4" label="Cédula de Identidad de otro integrante de la directiva. (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay
        certificado={certificadoCedulaOtroDire}
        clearCertificado={setCertificadoCedulaOtroDire}
      />
          <FileUpload onChange={(e) => {setCertificadoBancario(e.target.files)}}name="certificado5" label="Cuenta bancaria: (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay
        certificado={certificadoBancario}
        clearCertificado={setCertificadoBancario}
      />
          <FileUpload onChange={(e)=> {setCertificadoCartaCompromiso(e.target.files)}} name="certificado6" label="Carta de compromiso del trabajador/a. (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay
        certificado={certificadoCartaCompromiso}
        clearCertificado={setCertificadoCartaCompromiso}
      />
          <FileUpload onChange={(e) => {setCertificadoCotizacion(e.target.files)}} name="certificado7" label="Cotizaciones (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay
        certificado={certificadoCotizacion}
        clearCertificado={setCertificadoCotizacion}
      />
                  <br />
                  <br />
                    <div>
                    <Checkbox onChange={e => setChecked(e.target.checked)} checked={checked}></Checkbox>
                    <label className="ml-2"> Asiste a la actividad presencial convocada por Colbun.(5Pts)<WarningIcon/></label>
                    </div>
                  </div>
                  </DivFlex>
  
              )}
              <br />
              <br />
  
              
               <DivButtons>
  
                <div>
                {currentStep !== 0 &&  <ButtonAtras type="button" onClick={prevStep} disabled={currentStep === 0}>Anterior</ButtonAtras>}
                </div>
                
                <div>
              
                {currentStep === 5 ? <div><ButtonEnviar type="submit"
        disabled={radioSolucion1 == null || 
                  radioSolucion2 == null || 
                  radioSolucion3 == null || 
                  radioSolucion4 == null ||
                  radioSolucion5 == null ||
                  radioSolucion6 == null ||
                  radioSolucion7 == null 
                  }>Guardar</ButtonEnviar></div> : <></>}
                </div>
                <div>
                {currentStep !== 5 && <ButtonNext type="button" onClick={nextStep} disabled={currentStep === 5}>Siguiente</ButtonNext>}
                </div>
                
                </DivButtons>
                <DivContenedorError>
                  {errores && errores.length > 0 && (
                    <DivError>
                      <p>Error de envío, revisa el ingreso de tus datos: {errores.join(', ')}</p>
                    </DivError>
                  )}
                </DivContenedorError>
            </Form>
          )}
       
        </DivForm>
        </Div>
       </DivContenedorMax>
      )}
    </Formik>
  );
};

export default MultiStepFormRevision;
