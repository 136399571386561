import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar'; // Asegúrate de tener la ruta correcta
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const TabContent = styled.div`
  margin-top: -5px;
  animation: ${fadeIn} 0.5s ease-in-out;
  background-color: #fff;
  z-index: 0;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
`;

const Tab = styled.div`
  margin-top: 20px;
  width: auto;
  justify-content: start;
  padding: 10px 20px;
  background-color: ${({ active }) => (active ? '#007bff' : '#ccc')};
  position: relative;
  z-index: ${({ active }) => (active ? 3 : 2)};
  color: #fff;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  @media screen and (max-width: 800px) {
    padding: 10px 10px;
  }
`;

const Card = styled.div`
  width: 380px;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px;
  display: inline-block;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
              0 6px 20px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 800px) {
    width: 300px;
    height: 250px;
    margin: 1px;
  }
`;

const Title = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 1px;
  @media screen and (max-width: 800px) {
    font-size: 0.9rem;
    margin-top: -8%;
  }
`;

const Info = styled.div`
  position: absolute;
  margin-top: 0;
  bottom: 1px;
  padding: 0px;
  @media screen and (max-width: 800px) {
    font-size: 0.8rem;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 50%;
`;

const Content = styled.div`
  padding: 20px;
  background-color: #fff;
  height: 50%;
  display: flex;
  flex-direction: column;
`;

const PFecha = styled.p`
  font-weight: bold;
`;
const P = styled.p`
 margin-left: 20px;
  font-weight: bold;
`;

const Button = styled.button`
  position: absolute;
  bottom: 2%;
  right: 2%;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${({ status }) => {
    switch (status) {
      case 'En revisión':
        return '#f0ad4e';
      case 'Aprobado':
        return '#22bb33';
      case 'Rechazado':
        return '#bb2124';
      case 'Incompleto':
        return '#5bc0de';
      default:
        return '#007bff';
    }
  }};
`;

const DivContenedor = styled.div`
  margin-right: 10%;
  margin-left: 10%;
`;

class Cards extends Component {
  state = {
    searchQuery: '',
    sortOption: '',
    activeTab: 'procesosAbiertos', // default active tab
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };

  handleSort = (option) => {
    this.setState({ sortOption: option });
  };

  getSortedCards = (cards, sortOption) => {
    switch (sortOption) {
      case 'alphabeticalAsc':
        return [...cards].sort((a, b) => a.title.localeCompare(b.title));
      case 'alphabeticalDesc':
        return [...cards].sort((a, b) => b.title.localeCompare(a.title));
      case 'dateAsc':
        return [...cards].sort((a, b) => new Date(a.fechaCierre) - new Date(b.fechaCierre));
      case 'dateDesc':
        return [...cards].sort((a, b) => new Date(b.fechaCierre) - new Date(a.fechaCierre));
      default:
        return cards;
    }
  };

  handleTabClick = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const { searchQuery, sortOption, activeTab } = this.state;
    const { cardsData, solicitudesData,isLoading } = this.props;


    
    if (isLoading) {
      return (
        <DivContenedor>
          <Skeleton height={40} width="100%" count={5} style={{ marginBottom: 10 }} />
        </DivContenedor>
      );
    }
    // PROCESOS
    const filteredCards = cardsData.filter(
      (card) => card.Nombre_Postulacion.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const sortedCards = this.getSortedCards(filteredCards, sortOption);

    // SOLICITUDES
    const filteredSolicitudes = solicitudesData.filter(
      (card) => card.Nombre_Postulacion.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const sortedSolicitudes = this.getSortedCards(filteredSolicitudes, sortOption);
    
    return (
      <DivContenedor>
        <div>
          <Tab active={activeTab === 'procesosAbiertos'} onClick={() => this.handleTabClick('procesosAbiertos')}>
            Procesos
          </Tab>
          <Tab active={activeTab === 'misPostulaciones'} onClick={() => this.handleTabClick('misPostulaciones')}>
            Mis postulaciones
          </Tab>
        </div>

        {activeTab === 'misPostulaciones' && (
          <div>
             <SearchBar onSearch={this.handleSearch} onSort={this.handleSort} />
             <TabContent >
           
             {
                  isLoading ? (
                    <>
                      {[...Array(5)].map((_, index) => (
                        <Card key={index}>
                          <Skeleton height={150} />
                          <Content>
                            <Skeleton width="60%" />
                            <Info>
                              <Skeleton width="40%" />
                              <Skeleton width="80%" />
                            </Info>
                          </Content>
                          <Skeleton width="30%" height={30} style={{ marginTop: 10 }} />
                        </Card>
                      ))}
                    </>
                  ) : (
                    sortedSolicitudes.length > 0 ? (
                      sortedSolicitudes.map((card, index) => (
                        <Card key={index}>
                          <Image src={"/IMG/CardsEvaluador/cardIMG.jpeg"} alt="Image" />
                          <Content>
                            <Title>{card.Nombre_Postulacion}</Title>
                            <Info>
                              <p>Fecha postulación: {card.fecha_inicio}</p>
                            
                            </Info>
                          </Content>
                          {card.estado ? (
                            <Link to={'/Formulario/?id_postulacion=' + card.id_solicitud}>
                              <Button disabled={card.estado === "Pendiente" || card.estado === "Revisado"  }
  style={card.estado === "Pendiente" || card.estado === "Revisado"  ? { backgroundColor: 'gray', cursor: 'default' } : {}} status={card.estado}>{card.estado}</Button>
                            </Link>
                          ) : (
                            <Button disabled={card.estado === "Pendiente" || card.estado === "Revisado" }
                            style={card.estado === "Pendiente" || card.estado === "Revisado"  ? { backgroundColor: 'gray', cursor: 'default' } : {}}status={card.estado}>{card.estado}</Button>
                          )}
                        </Card>
                      ))
                    ) : (
                      <p>No hay postulaciones realizadas</p>
                    )
                  )
                }
            
          </TabContent>
          </div>
          
        )}

        {activeTab === 'procesosAbiertos' && (
          <div>
             <SearchBar onSearch={this.handleSearch} onSort={this.handleSort} />
          
             <TabContent>
           
           
           <P>Si ya realizó una postulación, revise la pestaña de Mis postulaciones.</P>
             {
              isLoading ? (
                <>
                  {[...Array(5)].map((_, index) => (
                    <Card key={index}>
                      <Skeleton height={150} />
                      <Content>
                        <Skeleton width="60%" />
                        <Info>
                          <Skeleton width="40%" />
                          <Skeleton width="80%" />
                        </Info>
                      </Content>
                      <Skeleton width="30%" height={30} style={{ marginTop: 10 }} />
                    </Card>
                  ))}
                </>
              ) : (
                sortedCards.map((card, index) => (

                  <Card key={index}>
                    <Image src={"/IMG/CardsEvaluador/cardIMG.jpeg"} alt="Image" />
                    <Content>
                      <Title>{card.Nombre_Postulacion}</Title>
                      <Info>
                        <p>Estado proceso: {card.estado ? 'Abierto' : 'Cerrado'}</p>
                        <p>Fecha de cierre: {card.fecha_fin}</p>
                      </Info>
                    </Content>
                    {card.estado ? (
                      <Link to={'/Formulario?id_proceso=' + card.id_postulacion}>
                        <Button disabled={sortedSolicitudes.length>0} style={card.estado && solicitudesData.length<1 ? { backgroundColor: 'green' } : { backgroundColor: 'gray', cursor:'default' }}>
                          Postular
                        </Button>
                      </Link>
                    ) : (
                      <Button disabled={sortedSolicitudes.length>0} style={card.estado && solicitudesData.length<1 ? { backgroundColor: 'green' } : { backgroundColor: 'gray',cursor:'default' }}>
                        Postular
                      </Button>
                    )}
                  </Card>
                ))
              )
            }
          </TabContent>
          </div>
          
        )}
      </DivContenedor>
    );
  }
}

export default Cards;