import React from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  max-width:940px;
`;

const SearchInputs = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const SearchInput = styled.div`
  position: relative;
  margin-right: 2%;
  flex-grow: 1;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  outline: none;
  box-sizing: border-box;
`;

const SearchIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const SelectInput = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  outline: none;
`;

const SearchBar = ({ onSearch, onSort }) => {
  return (
    <SearchContainer>
      
      <SearchInputs>
        <SearchInput>
          <Input 
            type="text" 
            placeholder="Buscar..." 
            onChange={(e) => onSearch(e.target.value)} 
          />
          <SearchIcon>🔍</SearchIcon>
        </SearchInput>
        <SelectInput onChange={(e) => onSort(e.target.value)}>
          <option value="">Ordenar por</option>
          <option value="alphabeticalAsc">Alfabético Ascendente</option>
          <option value="alphabeticalDesc">Alfabético Descendente</option>
          <option value="dateAsc">Fecha Ascendente</option>
          <option value="dateDesc">Fecha Descendente</option>
        </SelectInput>
      </SearchInputs>
    </SearchContainer>
  );
};

export default SearchBar;
