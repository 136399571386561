import axios from 'axios';

const API_URL = 'https://devapi.fdscolbun.org';

const apiService = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchPostulaciones = async (data, token) => {
  try {
    const response = await apiService.post('/proceso/', null, {
      params: {
        id_comuna: data,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}