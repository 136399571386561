import axios from 'axios';
import { data } from 'jquery';

const API_URL = 'https://devapi.fdscolbun.org';

const createController = (endpoint) => {

    const controller = axios.create({
      baseURL: `${API_URL}/${endpoint}`,
      headers: { 
        'Content-Type': 'application/json',
      },
    });
  
    return controller;
  };


export const fetchSolicitudByUser = async (token,rut) => {
  try {
    const controller = createController(`solicitud/postulante/${rut}`);
    const response = await controller.get('', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching solicitud with id ${rut}:`, error);
    throw error;
  }
};
  
  export const fetchSolicitudes = async () => {
    try {
      const controller = createController('solicitud');
      const response = await controller.get('');
      return response.data;
    } catch (error) {
      console.error('Error fetching solicitudes:', error);
      throw error;
    }
  };

  export const fetchSolicitudesByEv = async (rut_ev) => {
    try {
      const controller = createController('solicitud/evaluador/'+rut_ev);
      const response = await controller.get('');
      return response.data;
    } catch (error) {
      console.error('Error fetching solicitudes:', error);
      throw error;
    }
  };


    export const createSolicitud = async (data, isDraft = true) => {
    try {
      const controller = createController('solicitud/');
      const response = await controller.post(`?is_draft=${isDraft}`, data);
      return response.data;
    } catch (error) {
      console.error('Error creating solicitud:', error);
      throw error;
    }
  };
  
  export const deleteSolicitud = async (id) => {
    try {
      const controller = createController(`solicitud/${id}`);
      const response = await controller.delete('');
      return response.data;
    } catch (error) {
      console.error(`Error deleting solicitud with id ${id}:`, error);
      throw error;
    }
  };
  

  export const getSolicitudById = async (id, token) => {
    try {
      const controller = createController(`proceso/${id}`);
      const response = await controller.get('', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching solicitud with id ${id}:`, error);
      throw error;
    }
  };


  export const getSolicitudInfoById = async (id, token) => {
    try {
      const controller = createController(`solicitud/${id}`);
      const response = await controller.get('', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching solicitud with id ${id}:`, error);
      throw error;
    }
  };
 
  export const createRespuestasSolicitud = async (data) => {
    try {
      const controller = createController('solicitud/respuestas');
      const response = await controller.post('', data);
      return response.data;
    } catch (error) {
      console.error('Error creating solicitud:', error);
      throw error;
    }
  };

  export const createPlanificacionSolicitud = async (data) => {
    try {
      const controller = createController('solicitud/planificacion');
      const response = await controller.post('', data);
      return response.data;
    } catch (error) {
      console.error('Error creating solicitud:', error);
      throw error;
    }
  };

  export const createPresupuestoSolicitud = async(data) => {
    try{
      const controller = createController('solicitud/presupuestos');
      const response = await controller.post('', data);
      return response.data;
    }
    catch(error){
      console.error('Error creating solicitud:', error);
      throw error;
    }
  }

  export const fetchRespuestas = async(id_solicitud) => {
    try{
      const controller = createController('solicitud/respuestas/'+id_solicitud);
      const response = await controller.get('');
      return response.data;
    }
    catch(error){
      console.error('Error fetching respuestas:', error);
     
    }
  }
  export const fetchPresupuestos = async(id_solicitud) => {
    try{
      const controller = createController('solicitudpresupuesto/'+id_solicitud);
      const response = await controller.get('');
      return response.data;
    }
    catch(error){
      console.error('Error fetching presupuestos:', error);
      throw error;
    }
  }

  export const updateSolicitud = async(id,data) => {
    try{
      const controller = createController('solicitud/update/'+id);
      const response = await controller.put('', data);
      return response.data;
    }
    catch(error){
      console.error('Error updating solicitud:', error);
      throw error;
    }
  }

  export const updateRespuestas = async(id,data) => {
    try{
      const controller = createController('solicitud/respuesta/update/'+id);
      const response = await controller.put('', data);
      return response.data;
    }
    catch(error){
      console.error('Error updating respuestas:', error);
      throw error;
    }
  }

  export const evaluarSolicitud = async(data) => {
    try{
      const controller = createController('solicitud/evaluar')
      const response = await controller.post('',data)
      return response.data
    }catch(error){
      console.log("error evaluando solicitud" + error)
      throw error;
    }
  }

  export const evaluarPuntaje = async(data) => {
    try{
      const controller = createController('solicitud/evaluar/puntaje')
      const response = await controller.put('',data)
      return response.data
    }catch(error){
      console.log("error evaluando solicitud" + error)
      throw error;
    }
  }
  export const fetchPuntajes = async(id_solicitud) => {
    try{
      const controller = createController('solicitud/evaluacion/'+id_solicitud);
      const response = await controller.get('');
      return response.data;
    }
    catch(error){
      console.error('Error fetching puntajes:', error);
      throw error;
    }
  }


  export const uploadDocument = async(document) => {
    try{
      const controller = createController('document');
      const response = await controller.post('',document);
      return response.data;
    }
    catch(error){
      console.error('Error uploading document:', error);
      throw error;
    }
  }

  export const fetchDocument = async(id_solicitud) => {
    try{
      const controller = createController('document/'+id_solicitud);
      const response = await controller.get('');
      return response.data;
    }
    catch(error){
      console.error('Error fetching document:', error);
      throw error;
    }
  }

  export const deleteDocument = async(id) => {
    try{
      const controller = createController('document/'+id);
      const response = await controller.delete('');
      return response.data;
    }
    catch(error){
      console.error('Error deleting document:', error);
      throw error;
    }
  }
export const createComment = async(data) => {
  try{
    const controller = createController('solicitud/comentario');
    const response = await controller.post('',data);
    return response.data;
  }
  catch(error){
    console.error('Error creating comment:', error);
    throw error;
  }
}
export const getComment = async(id_solicitud) => {
  try{
    const controller = createController('solicitud/comentario/'+id_solicitud);
    const response = await controller.get('');
    return response.data;
  }
  catch(error){
    console.error('Error fetching comment:', error);
    throw error;
  }
}
  const solicitudService = {
    fetchSolicitudes,
    createSolicitud,
    updateSolicitud,
    deleteSolicitud,
    updateRespuestas
  };

  export default solicitudService;
  