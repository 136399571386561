import { useField } from 'formik';
import styled from 'styled-components';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'react-tooltip';

const InputStyled = styled(InputText)`
    padding-left: ${(props) => (props.showPrefix ? '20px' : '10px')};
    width: 100%;
    &:focus {
        background-color: lightblue; /* Cambia el color de fondo a tu preferencia */
        outline: none; /* Opcional: elimina el borde azul por defecto en algunos navegadores */
    }
`;

const Div = styled.div`
    color: red;
`;

const Asterisk = styled.span`
    color: red;
    margin-left: 4px; /* Ajusta el espacio entre el texto del label y el asterisco */
`;

const Label = styled.label`
    font-weight: bold;
`;
const TextInput = ({ label, tooltipContent, required, showPrefix, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <div style={{width:'100%'}}>
            <Label>
                {label}
                {required && <Asterisk>*</Asterisk>}
            </Label>
            <br />
            <div style={{ width:'100%', position: 'relative' }}>
                {showPrefix && <span style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)' }}>$</span>}
                <InputStyled
                    {...field}
                    {...props} // Pass all remaining props to InputStyled
                    data-tooltip-id="my-tooltipText"
                    data-tooltip-content={tooltipContent}
                    style={{ paddingLeft: showPrefix ? '20px' : '10px' }} // Adjust padding to make space for the prefix
                />
            </div>
            <Tooltip id="my-tooltipText" place="bottom" />
            {meta.touched && meta.error ? <Div>{meta.error}</Div> : null}
        </div>
    );
};

export default TextInput;
