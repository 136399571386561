import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Formulario from './pages/Formulario';
import FormularioRevision from './pages/FormularioRevision';
import FeedPostulante from './pages/FeedPostulante';
import FeedEvaluador from './pages/FeedEvaluador';
import FeedAdmin from './pages/FeedAdmin';
import Registro from './pages/Registro';
import MiPerfil from './pages/MiPerfil';


function App() {
  return (
    <Router> {/* Ensure only one Router is used at the top level */}
      <div>
  
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/Formulario" element={<Formulario />} />
          <Route path="/FeedPostulante" element={<FeedPostulante />} />
          <Route path="/FormularioRevision" element={<FormularioRevision />} />
          <Route path="/FeedEvaluador" element={<FeedEvaluador />} />
          <Route path="/FeedAdmin" element={<FeedAdmin />} />
          <Route path="/Registro" element={<Registro />} />
          <Route path="/MiPerfil" element={<MiPerfil />} />
        </Routes>
      
    
      </div>

    </Router>
   
  );
}

export default App;
