import axios from 'axios';

const API_URL = 'https://devapi.fdscolbun.org';

const apiService = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});



export const fetchEvaluadores = async (endpoint, token) => {
  try {
    const response = await apiService.get('/usuario/evaluadores', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching evaluadores data:', error);
    throw error;
  }
}

export const fetchUsers = async (endpoint, token) => {
  try {
    const response = await apiService.get('/usuario/', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
}

export const assign_evaluador = async (data) => {
  try {

    const response = await apiService.post('/solicitud/evaluador?id_solicitud='+data.id_solicitud+'&rut_evaluador='+data.rut_evaluador );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const assignRole_User = async (user, token) => {
  try {
    const response = await apiService.post('/usuario/updateRol', user, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error setting role to user:', error);
    throw error;
  }
}

export const updatePassword = async (email, password) => {
  try {
    const response = await apiService.post('/usuario/updatePassword', {
      email, 
      password
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  } catch (error) {
    console.error('Error updating password:', error);
    throw error;
  }
};