import Navbar from '../components/Navbar'
import React, { useEffect,useState } from 'react';
import Cards from '../components/Cards'
import Footer from '../components/Footer'
import styled from 'styled-components';
import { fetchPostulaciones } from '../services/postulations';
import { fetchSolicitudByUser } from '../services/solicitud';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrap = styled.div`
  flex: 1;
  margin-top: 70px; /* QUITAR LUEGO DE IMPLEMENTAR NAVBARREAL*/
  
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
`;


function FeedPostulante() {
  const [postulaciones, setPostulaciones] = useState([]);
  const [solicitudes, setSolicitudes] = useState([]);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const userData = sessionStorage.getItem('userData');
      const tokenData = sessionStorage.getItem('token');
      const user = JSON.parse(userData);
      setUser(user);

      if (user && tokenData) {
        try {
          // Fetch Postulaciones
          const postulacionesData = await fetchPostulaciones(user.id_comuna, tokenData);
          if (postulacionesData) {
            setPostulaciones(postulacionesData.data);
          } else {
            setPostulaciones([]);
          }

          // Fetch Solicitudes
          const solicitudesData = await fetchSolicitudByUser(tokenData, user.rut_usuario);
          if (solicitudesData) {
            var x = [];
            solicitudesData.forEach(element => {
              x.push(element.id_solicitud);
            });
            sessionStorage.setItem('solicitudesEnabled', x);

            setSolicitudes(solicitudesData);
          } else {
            setSolicitudes([]);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setPostulaciones([]);
          setSolicitudes([]);
        } finally {
          setIsLoading(false); // Set isLoading to false after data fetching is complete
        }
      } else {
        setIsLoading(false); // Set isLoading to false if user or tokenData is not available
      }
    };

    fetchData();
  }, []);


  if (!postulaciones || !solicitudes) {
    return <div>Loading...</div>; // Render a loading state while data is being fetched
  }

  return (
    <PageContainer>
      <Navbar name={user.nombre_usuario} />
      <ContentWrap>
        {postulaciones.status_code===404 && solicitudes.length===0 ? (
          <Container>
            <h1>No hay procesos disponibles para su comuna</h1>
          </Container>
        ) : (
          <div>
            <Cards cardsData={postulaciones} solicitudesData={solicitudes} isLoading={isLoading} />
            
          </div>
        )}
      </ContentWrap>
      <Footer />
    </PageContainer>
  );
}

export default FeedPostulante;