import {  useField   } from 'formik'
import styled from 'styled-components';
import { RadioButton } from 'primereact/radiobutton';
        
const Label = styled.label`
    margin-left:5px;
`;

const RadioInput = ({label, ...props}) =>{
    const [field] = useField({...props, type:'radio'})


    return (
        <div>
            <RadioButton  type='radio' {...field} {...props}/>
            <Label className="ml-2">{label}</Label>
       
       
   
        </div>
    )

}


export default RadioInput