import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import styled, { keyframes } from 'styled-components';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net-responsive-dt';
import $ from 'jquery';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { assignRole_User, fetchEvaluadores, updatePassword } from '../services/admin';
import { fetchSolicitudes } from '../services/solicitud';
import { assign_evaluador, } from '../services/admin';
import { fetchUsers } from '../services/admin';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrap = styled.div`
  flex: 1;
  margin-top: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ContenedorTabla = styled.div`
  padding: 5px;
  border-radius: 5px;
  border: solid 1px;
  border-color: black;
  margin-top: 20px;
  width: 100%;
  max-width: 100%;
`;

const Title = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10px;
  align-self: flex-start;
`;

const Div = styled.div`
  margin-bottom: 5%;
  width: 100%;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const EstadoCell = styled.td`
  background-color: ${({ estado }) => {
    switch (estado) {
      case 'Pendiente':
        return '#fce282';
      case 'Revisado':
        return '#dfd';
      default:
        return '#fff';
    }
  }};
`;

const Button = styled.button`
  background-color: ${props => (props.disabled ? '#cccccc' : '#4CAF50')};
  color: white;
  padding: 10px 20px;
  margin-left: 20px;
  border: none;
  border-radius: 5px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 16px;

  &:hover {
    background-color: ${props => (props.disabled ? '#cccccc' : '#45a049')};
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const TabContent = styled.div`
  margin-top: -5px;
  animation: ${fadeIn} 0.5s ease-in-out;
  background-color: #fff;
  z-index: 0;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  width: 100%;
`;

const Tab = styled.div`
  margin-top: 20px;
  width: auto;
  justify-content: start;
  padding: 10px 20px;
  background-color: ${({ active }) => (active ? '#007bff' : '#ccc')};
  position: relative;
  z-index: ${({ active }) => (active ? 3 : 2)};
  color: #fff;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: 800px) {
    padding: 10px 10px;
  }
`;

const FeedAdmin = () => {
  const [changes, setChanges] = useState({});
  const [changesRol, setChangesRol] = useState({});
  const [evaluadores, setEvaluadores] = useState([]);
  const [users, setUsers] = useState([]);
  const [solicitudes, setSolicitudes] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState('postulacionesSinAsignar');
  const isTableInitialized = useRef({ table_id: false, table_evaluadores: false });
  const user = JSON.parse(sessionStorage.getItem('userData'));
  const tokenData = sessionStorage.getItem('token');
  const [isLoading ,setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);

  const openModal = async(user) => {
    setSelectedUser(user);
    var password =  generatePassword(12);
    
    setPasswordChanged(password);
    setModalIsOpen(true);
  };

  function generatePassword(length = 12) {
    // Define character sets
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const specialChars = '!@#$%^&*()_+~`|}{[]:;?><,./-=';
    
    // Combine all character sets into one
    const allChars = uppercase + lowercase + numbers + specialChars;
    
    // Ensure the password contains at least one character from each set
    let password = '';
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];
    
    // Fill the remaining length with random characters from all sets
    for (let i = password.length; i < length; i++) {
        password += allChars[Math.floor(Math.random() * allChars.length)];
    }
    
    // Shuffle the password to avoid predictable patterns
    password = password.split('').sort(() => 0.5 - Math.random()).join('');
    
    return password;
}

// Example usage
  const assignNewPassword = (postulante) => {
  
    updatePassword(postulante,passwordChanged).then((response)=> {
      console.log(response);
      if(response.status === 200){
        alert('Contraseña reiniciada correctamente');
        closeModal();
      }else{
        alert('Error al reiniciar contraseña');
      }
    })
  }
  const closeModal = async() => {  
    
    setModalIsOpen(false);
    setSelectedUser(null);
  };
  useEffect(() => {

    if(user.rol==="Postulante"){
      window.location.href = '/feedPostulante';
    } else if(user.rol==='Evaluador'){
      window.location.href = '/feedEvaluador';
    }
    const fetchData = async () => {
      try {
        const usersResponse = await fetchUsers(tokenData);
        const usersData = usersResponse.map(element => ({
          id: element.rut_usuario,
          nombre: `${element.nombre_usuario} ${element.apellido_usuario}`,
          correo: element.correo_usuario,
          rol: element.rol
        }));
        
        setUsers(usersData);
        
        const evaluadoresResponse = await fetchEvaluadores(tokenData);
        const evaluadoresData = evaluadoresResponse.map(element => ({
          id: element.rut_usuario,
          nombre: `${element.nombre_usuario} ${element.apellido_usuario}`
        }));

        setEvaluadores(evaluadoresData);

        const solicitudesResponse = await fetchSolicitudes();
        const solicitudesData = solicitudesResponse.map(element => ({
          id: element.id_solicitud,
          nombre: element.nombre_organizacion,
          fondo: element.Nombre_postulacion,
          comuna: element.descripcion_comuna,
          region: element.descripcion_region,
          fechaPostulacion: element.Fecha_Postulacion,
          evaluador: evaluadoresData.find(evalu => evalu.id === element.rut_evaluador)?.nombre,
          evaluador_rut: element.rut_evaluador,
          estado: element.estado_solicitud,
          Puntaje : element.Puntaje ? element.Puntaje : "Sin evaluar"
         
        }));
        
        setSolicitudes(solicitudesData);

        setDataLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData().finally(() => setIsLoading(false));
  }, [tokenData]);

  useEffect(() => {
    if (dataLoaded) {
      if (!isTableInitialized.current.table_id) {
        $('#table_id').DataTable({
          responsive: true,
          columnDefs: [
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: -1 },
            { responsivePriority: 3, targets: 4 }
          ],
          order: [[3, 'asc']],
          language: {
            sProcessing: "Procesando...",
            sLengthMenu: "Mostrar _MENU_ registros",
            sZeroRecords: "No se encontraron registros",
            sEmptyTable: "No hay datos disponibles",
            sInfo: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            sInfoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
            sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
            sSearch: "Buscar:",
            sLoadingRecords: "Cargando...",
            oAria: {
              sSortAscending: ": Activar para ordenar la columna de manera ascendente",
              sSortDescending: ": Activar para ordenar la columna de manera descendente"
            }
          }
        });
        $('#table_id').DataTable().columns.adjust();
        isTableInitialized.current.table_id = true;
      }
      if (!isTableInitialized.current.table_evaluadores) {
        $('#table_evaluadores').DataTable({
          responsive: true,
          columnDefs: [
            { responsivePriority: 0, targets: 0 },
          ],
          order: [[0, 'asc']],
          language: {
            sProcessing: "Procesando...",
            sLengthMenu: "Mostrar _MENU_ registros",
            sZeroRecords: "No se encontraron registros",
            sEmptyTable: "No hay datos disponibles",
            sInfo: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            sInfoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
            sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
            sSearch: "Buscar:",
            sLoadingRecords: "Cargando...",
            oAria: {
              sSortAscending: ": Activar para ordenar la columna de manera ascendente",
              sSortDescending: ": Activar para ordenar la columna de manera descendente"
            }
          }
        });
        $('#table_evaluadores').DataTable().columns.adjust();
        isTableInitialized.current.table_evaluadores = true;
      }
    }
  }, [dataLoaded]);

  useEffect(() => {
    if (isTableInitialized.current.table_id) {
      $('#table_id').DataTable().draw();
      
    }
  }, [activeTab]);

  useEffect(() => {
    if (isTableInitialized.current.table_evaluadores) {
      $('#table_evaluadores').DataTable().draw();
    
    }
    
  }, [activeTab]);


  const handleSelectChange = (id, newEvaluador, x) => {
    
    if ( x==1 )
      {
      setChanges(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        id_solicitud:id,
        evaluador: newEvaluador
      }
    }));}else {
      
      setChangesRol(prev => ({
        ...prev,
        [id]: {
          
          rut_evaluador:id,
          rol: newEvaluador
        }
      }));

    }

   
  };



  const assignEvaluadorSolicitud = (rut,id) => {
    const data = {
      rut_evaluador: rut,
      id_solicitud : id
    }
      assign_evaluador(data).then((response)=> {
       
      })


  }

  const assignRolToUser = (rut,rol) => {
    const data = {
      rut_usuario: rut,
      rol : rol
    }
      assignRole_User(data,tokenData).then((response)=> {
       
      })
  }
  const handleAssign = () => {
    const changesArray = Object.values(changes);

    
    changesArray.forEach((element) => {
      assignEvaluadorSolicitud(element.evaluador,element.id_solicitud);
    })

    alert('Evaluadores asignados correctamente');
    window.location.reload();

    // Here you can perform actions with the selected evaluadores
  };

  const handleRoleAssign = () => {
    const changesArray = Object.values(changesRol);

    
    changesArray.forEach((element) => {
      assignRolToUser(element.rut_evaluador,element.rol);
    })

    alert('Roles asignados correctamente');
    window.location.reload();

    // Here you can perform actions with the selected evaluadores
  }

  return (
    <PageContainer>
      <Navbar name={user.nombre_usuario} />
      <ContentWrap>
      {isLoading ? (<Skeleton width="80%" height={80} />) : (
               <Div>
               <Tab active={activeTab === 'postulacionesSinAsignar'} onClick={() => setActiveTab('postulacionesSinAsignar')}>
                 Postulaciones
               </Tab>
              
               <Tab active={activeTab === 'adminCuentas'} onClick={() => setActiveTab('adminCuentas')}>
                 Administrar cuentas
               </Tab>
              
               {activeTab === 'postulacionesSinAsignar' && (
                 <TabContent>
                   <Title>
                     Lista de solicitudes
                     <Button onClick={handleAssign} disabled={Object.keys(changes).length === 0}>
                       Asignar
                     </Button>
                   </Title>
                   <ContenedorTabla>
                     <table id="table_id" className="display responsive nowrap" width="100%">
                       <thead>
                         <tr>
                           <th>Org. Postulante</th>
                           <th>Fondo</th>
                           <th>Comuna</th>
                           <th>Region</th>
                           <th>Fecha Postulación</th>
                           <th>Evaluador</th>
                           <th>Estado</th>
                           <th>Puntaje</th>
                         </tr>
                       </thead>
                       <tbody>
                         {solicitudes.map(postulante => (
                           <tr key={postulante.id}>
                             <td>
                               <StyledLink to={`/Formulario?id_postulacion=${postulante.id}`}>
                                 {postulante.nombre}
                               </StyledLink>
                             </td>
                             <td>{postulante.fondo}</td>
                             <td>{postulante.comuna}</td>
                             <td>{postulante.region}</td>
                             <td>{postulante.fechaPostulacion}</td>
                             <td>
                               <select
                                 value={changes[postulante.id]?.evaluador || postulante.evaluador_rut}
                                 onChange={(e) => handleSelectChange(postulante.id, e.target.value,1)}
                               >
                                 <option value="">Seleccionar evaluador</option>
                                 {evaluadores.map(evaluador => (
                                   <option key={evaluador.id} value={evaluador.id}>
                                     {evaluador.nombre}
                                   </option>
                                 ))}
                               </select>
                             </td>
                             <EstadoCell estado={postulante.estado}>{postulante.estado}</EstadoCell>
                             <td>{postulante.Puntaje}</td>
                           </tr>
                         ))}
                       </tbody>
                     </table>
                   </ContenedorTabla>
                 </TabContent>
               )}
     
     
               {activeTab === 'adminCuentas' && (
                 <TabContent>
                   <Title>
                     Administrar cuentas
                     <Button onClick={handleRoleAssign} disabled={Object.keys(changesRol).length === 0}>
                       Modificar rol
                     </Button>
                   </Title>
                   <ContenedorTabla>
                     <table id="table_evaluadores" className="display responsive nowrap" width="100%">
                       <thead>
                         <tr>
                           <th>Nombre</th>
                           <th>Correo</th>
                           <th>Rol</th>
                           <th>Reiniciar Contraseña</th>
                         </tr>
                       </thead>
                       <tbody>
                         {users.map(evaluador => (
                           <tr key={evaluador.id}>
                             <td>{evaluador.nombre}</td>
                             <td>{evaluador.correo}</td>
                             <td>
                             <select
                                 value={changesRol[evaluador.id]?.rol || evaluador.rol}
                                 onChange={(e) => handleSelectChange(evaluador.id,e.target.value,2)}
                               >
                                 <option value="">Seleccionar rol</option>
                                 <option key={1} value={'Postulante'}>
                                   Postulante
                                 </option>
                                 <option key={2} value={'Evaluador'}>
                                   Evaluador
                                 </option>
                                 <option key={3} value={'Administrador'}>
                                   Administrador
                                 </option>
                               
                               
                               </select>
                               </td>
                               <td>
                              <StyledLink as="button" onClick={() => openModal(evaluador)}>Reiniciar</StyledLink>
                            </td>
                              
                           </tr>
                         ))}
                       </tbody>
                     </table>

                     <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={closeModal}
                      contentLabel="Reiniciar Contraseña"
                      style={{
                        content: {
                          position: 'fixed',
                          zIndex: '9999',
                          top: '50%',
                          left: '50%',
                          right: 'auto',
                          bottom: 'auto',
                          marginRight: '-50%',
                          transform: 'translate(-50%, -50%)',
                          backgroundColor: 'white',
                          padding: '20px',
                          border: '1px solid #ccc',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          borderRadius: '8px',
                          width: '400px', // Set a fixed width for the modal to help with layout
                          display: 'flex',
                          flexDirection: 'column', // Ensure content stacks vertically
                          justifyContent: 'space-between' // Space between content and buttons
                        },
                        overlay: {
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          zIndex: '9998'
                        }
                      }}
                    >
                      <h2>Reiniciar Contraseña</h2>
                      
                      <p>Nueva contraseña: {passwordChanged}</p>
                      {selectedUser && (
                        <p style={{marginBottom:'50px'}}>¿Está seguro que desea reiniciar la contraseña para {selectedUser.correo}?</p>

                       
                      )}


                      
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between', // Spread buttons to corners
                        position: 'absolute', // Position the buttons absolutely within the modal
                        bottom: '20px', // Move the buttons to the bottom of the modal
                        left: '20px',   // Align with left padding
                        right: '20px',
                        marginTop:'50px'   // Align with right padding
                      }}>
                       
                        <button onClick={(e) => {
                          // Add your reset password logic here
                          assignNewPassword(selectedUser.correo);
                        }} style={{ alignSelf: 'flex-end' }}>Confirmar</button>

                      <button onClick={closeModal} style={{ alignSelf: 'flex-start' }}>Cancelar</button>
                      </div>
                     
                    </Modal>
                   </ContenedorTabla>
                 </TabContent>
               )}
             </Div>
          )}
     
      </ContentWrap>
      <Footer />
    </PageContainer>
  );
};

export default FeedAdmin;
