import React, { useState, useEffect } from 'react';
import { Form, Formik, ErrorMessage, Field } from 'formik';
import TextInput from '../components/TextInput';

import TextArea from '../components/TextArea';
import RadioInput from '../components/RadioInput';
import NumberInput from '../components/NumberInput';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

import { isValidEmail } from '../utils/isValidEmail';
import { Fn } from '../utils/rutValidation';
import { BsFillQuestionDiamondFill } from "react-icons/bs";
import { IoIosSave } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { IoMdDownload } from 'react-icons/io';
import { createSolicitud,getSolicitudInfoById,getSolicitudById, createRespuestasSolicitud , createPlanificacionSolicitud, createPresupuestoSolicitud, fetchRespuestas, fetchPresupuestos, updateSolicitud, updateRespuestas, uploadDocument, fetchDocument, deleteDocument, deleteSolicitud } from '../services/solicitud';
import { Tooltip } from 'react-tooltip';
import Navbar from '../components/Navbar'
import { get } from 'jquery';
const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const arrayBuffer = reader.result;
      const bytes = new Uint8Array(arrayBuffer);
      let binary = '';
      for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      const base64String = btoa(binary);
      resolve(base64String);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

const FileUpload = ({ name, label, errorMessageStyle, onChange, loading}) => {
  

  // Function to convert file to an array of bytes

  // Function to send file data to the API
  const sendFileToApi = async (bytes, form) => {
    try {
      const response = {
        id_solicitud: form.values.id_solicitud,  // adjust these fields as necessary
        tipo_documento: form.values.tipo_documento,
        documento: bytes,
        categoria: form.values.categoria
      }
   
      
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
     
    }
  };

  return (
    <>
      <Label>
        {label}
        <Asterisk>*</Asterisk>
        <ErrorMessageStyled name={name} component="div" style={errorMessageStyle} />
      </Label>
      <Field name={name}>
        {({ field, form }) => (
          <>
            <input
              type="file"
              accept=".pdf,.jpg,.jpeg"
              {...field}
              id={`${name}-file-upload-input`}
              style={{ display: 'none' }}
              onChange={onChange}
            />
            <label  htmlFor={`${name}-file-upload-input`} className="file-upload-label">
              <br />
              <ButtonSubir style={{color:'#f0f0f0'}} type="button" onClick={() => document.getElementById(`${name}-file-upload-input`).click()}>
                Subir archivo
              </ButtonSubir>
            </label>
            {loading && <Loader />}
          </>
        )}
      </Field>
      <br />
      <br />
    </>
  );
};


const DivFlexP1 = styled.div`
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 780px) {
    flex-direction: column;
  }

  &.fade {
    animation: ${fadeAnimation} 0.5s;
  }

  &.fade-out {
    animation: ${fadeAnimation} 0.5s reverse;
  }
`;

const FormContainerP1 = styled.div`
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
`;

const FormLabelP1 = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const TextInputP1 = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const NumberInputP1 = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const RadioInputP1 = styled.input`
  margin-right: 10px;
`;

const ErrorMessageP1 = styled.div`
  color: red;
  font-size: 0.875em;
`;


const DivContenedorMax = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  height: auto
  border-radius: 5px;
  @media screen and (max-width: 780px) {  
      background-color: #fff;
  }  
 
`;

const Div = styled.div`  
  border-radius: 15px;
  margin-left: 5%;  
  margin-right: 5%;  
  margin-bottom:5%;
  
  padding-left: 2%;  
  padding-top: 110px; /* Ajuste para dejar espacio para el navbar */  
  padding-bottom: 8%;  
  position: relative; /* No se necesita cambiar esto */  
  height:100%;
  
  min-height: 900px;  
  width: 1050px;  
  max-width: 1050px;  
  transition: opacity 0.3s ease;  

  @media screen and (max-width: 780px) {  
    height: auto;  
    min-height: 100%;  
    width: 100vw; /* ancho del viewport */  
    margin-left: 0; /* Elimina margen izquierdo */  
    padding-left: 10px; /* Ajusta según sea necesario */  
    margin-right: 0; /* Elimina margen derecho */  
    padding-right: 10px; /* Ajusta según sea necesario */  
    max-width: 100%; /* Asegura que el contenedor se expanda completamente */  
    height: 100vh; /* Permite que la altura mínima se ajuste al contenido */  
    position: relative; /* Asegura que el contenedor se adapte a la altura del contenido */  
    padding-bottom: 5%;  
    padding-top: 95px; /* Ajusta para dispositivos móviles también */  
  }  
`;


const DivFlex = styled.div`
  margin-top:-5px;
  display: flex;
  gap: 10%;
  justify-content: center;
  align-items: flex-start;
  align-text: center;

  @media screen and (max-width: 780px) {
    flex-direction: column;
  } 

  &.fade {
    animation: ${fadeAnimation} 0.5s;
  }

  &.fade-out {
    animation: ${fadeAnimation} 0.5s reverse;
  }

`;
const FormContainer = styled.div`
  
  padding: 20px;
  border-radius: 5px;
  width: 100%;
`;



const FormGroup = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;




const DivFlex2 = styled.div`
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 780px) {
    flex-direction: column;
  }

  &.fade {
    animation: ${fadeAnimation} 0.5s;
  }

  &.fade-out {
    animation: ${fadeAnimation} 0.5s reverse;
  }
`;

const DivFlexP4 = styled.div`

margin-top: -5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 780px) {
    flex-direction: column;
  }

  &.fade {
    animation: ${fadeAnimation} 0.5s;
  }

  &.fade-out {
    animation: ${fadeAnimation} 0.5s reverse;
  }

`;


const FormContainerP4 = styled.div`

  padding: 20px;
  border-radius: 5px;
  width: 100%;
`;


const FormGroupP4 = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const FormLabelP4 = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;


const TextAreaP4 = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`;

const FieldP4 = styled(TextInput)`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TableP4 = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
    text-align: left;
  }

  td {
    background-color: #fff;
  }
`;
const ErrorMessageP4 = styled.div`
  color: red;
  font-size: 0.875em;
`;


const DivFlexP5 = styled.div`
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 780px) {
    flex-direction: column;
  }

  &.fade {
    animation: ${fadeAnimation} 0.5s;
  }

  &.fade-out {
    animation: ${fadeAnimation} 0.5s reverse;
  }
`;

const FormContainerP5 = styled.div`
  
  padding: 20px;
  border-radius: 5px;
  width: 100%;
`;

const FormLabelP5 = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const TableP5 = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
    text-align: left;
  }

  td {
    background-color: #fff;
  }
`;

const FieldP5 = styled(TextInput)`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
const FieldP55 = styled(NumberInput)`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ErrorMessageP5 = styled.div`
  color: red;
  font-size: 0.875em;
`;

const DivForm = styled.div`

`;

const Label = styled.label`
    font-weight: bold;
    margin-right: 5px;
    margin-top:0px;
    margin-buttom:0px;
`;
const ErrorMessageStyled = styled(ErrorMessage)`
  display: inline-block;
  color: red; /* Puedes ajustar el estilo del mensaje de error aquí */
  margin-left: 3px; /* Espacio entre el label y el mensaje de error */
  
`;

const ButtonSubir = styled.button`
    padding: 7px 17px;
    background-color: #11CDDE;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin:1%;
    margin-right:0%;
    bottom:2%;
    &:hover {
      color: white;
      background-color: #07B4C4 ;
    }
`;

const ButtonNext = styled.button`
    padding: 10px 20px;
    background-color: #42ADEA;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin:1%;
    margin-right:0%;
    bottom:2%;
    &:hover {
      background-color: #3592C8;
    }
`;
const ButtonAtras = styled.button`
    padding: 10px 20px;
    background-color: #42ADEA;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin:1%;
    bottom:2%;

    &:hover {
      background-color: #3592C8;
    }
`;
const ButtonEnviar = styled.button`
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin:1%;
    bottom:2%;
    background-color:#3CB371;
    &:hover {
      background-color: #359B62;
    }
    &:disabled {
      background-color: #e7e7e7; /* Fondo cuando está deshabilitado */
      color: #a0a0a0; /* Color del texto cuando está deshabilitado */
      cursor: not-allowed; /* Cambiar cursor cuando está deshabilitado */
      pointer-events: none; /* Deshabilitar efectos hover */
  }
`;
const DivError = styled.button`
    border: none;
    padding:5px;
    color:black;
    height:auto;
    margin:1%;
    bottom:0%;
    background-color:salmon;
    border-radius: 5px;
    border:solid 1px;
    border-color:black;
`;
const DivSinErrores = styled.div`
    border: none;
    padding:5px;
    color:black;
    height:auto;
    margin:1%;
    bottom:0%;
    background-color:#3CB371;
    
    border-radius: 5px;
    border:solid 1px;
    border-color:black;
`;

const DivButtons = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-top:0%;
  bottom:75px;
  margin:0px;

  @media screen and (max-width: 780px) {
    position: relative;
    bottom: 15%;
    margin-bottom:10%;
    width: 100%;

  } 
`;

const DivContenedorError = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    bottom: 0;
    width: 95%;
`;


const ProgressBar = styled.div`
  display: flex;

  justify-content: space-between;
  margin-bottom: 20px;
  @media screen and (max-width: 780px) {
    margin-top:2%;
    justify-content: center;
    align-items: center;
    width:auto;
    } 
`;

const StepProgress = styled.div`
  flex: 1;
  display: flex; /* Añade esta línea para hacer que los pasos se alineen horizontalmente */
  @media screen and (max-width: 780px) {
    margin-bottom:5%;
    } 
`;


const Step = styled.div`  
  display: flex;  
  align-items: center;  
  position: relative;  
  flex: 1; /* Opcional: Esto asegura que cada Step ocupe el mismo espacio horizontalmente */  
  animation: ${fadeAnimation} 0.5s ease-in-out;  
  @media screen and (max-width: 780px) {  
    flex-direction: row; /* Ajusta la dirección del flexbox a horizontal */  
    justify-content: center; /* Centra los elementos en el eje principal */  
    align-items: center; /* Centra los elementos en el eje secundario */  

  }  
`;


const Bullet = styled.div`
position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => (props.active === 'true' ? '#76c7c0' : '#e0e0df')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.active === 'true' ? 'white' : 'black')};
  font-weight: bold;
  z-index:10;
  cursor: pointer; /* Agrega esta línea para mostrar la mano de selección */
  &:hover {
    background-color: #61A29C; 
  }
  @media screen and (max-width: 780px) {
    margin-top:10%;
    
    } 
`;

const StepTitle = styled.p`  
  margin-top: 10px;  
  @media screen and (max-width: 780px) {  
    display: none;  
    margin: 0;  
    padding: 0;  
    height: 0;  
    overflow: hidden;  
    visibility: hidden;  
  }  
`;




const SaveIcon = styled.button`  
  padding: 7px;  
  border-radius: 5px;  
  border: none;  
  position: absolute;  
  left: 3%;  
  margin-top: 55px;   
  display: flex; /* Cambiar a flexbox */  
  align-items: center; /* Centra verticalmente */  
  background-color: #50AAC5;  
  transition: width 0.3s ease, height 0.3s ease;  
  color: white;  

  &:hover {  
    background-color: #438DA3;  
    color: white;  
  }  

  &:disabled {
    background-color: #A0BCC5; /* Color más tenue para el botón deshabilitado */
    color: #D3D3D3; /* Color de texto más tenue para el botón deshabilitado */
    cursor: not-allowed; /* Cambia el cursor cuando el botón está deshabilitado */
    
    &:hover {  
      background-color: #A0BCC5; /* Mantener el color tenue al hacer hover en el botón deshabilitado */
      color: #D3D3D3;  
    }
  }

  @media screen and (max-width: 780px) {  
    padding: 10px;  
    margin-top: 85px;   
  }  
`;

const FechaTerminoIcon = styled.button`  
  padding: 7px;  
  border-radius: 5px;  
  border: none;  
  position: absolute;  
  right: 3%;  
  margin-top: 55px;   
  display: flex; /* Cambiar a flexbox */  
  align-items: center; /* Centra verticalmente */  
  background-color: #ECCC64;  
  transition: width 0.3s ease, height 0.3s ease;  
  color: black;  


  @media screen and (max-width: 780px) {  
    padding: 10px;  
    margin-top: 85px;   
  }  
`;  

const Icon = styled(IoIosSave)`  
  margin-right: 5px; /* Espacio entre el icono y el texto */  
    font-size: 1.2rem; /* Cambiar el tamaño del icono */ 
`;  



const CheckIcon = styled(FaCheckCircle)`
  position: absolute;
  margin-left: -3%;
  margin-bottom: 20px;
  display: block;
  color: #22bb33;
  z-index: 10;
`;

const CloseIcon = styled(IoMdCloseCircle)`
  position: absolute; 
  margin-left: -3%;
  margin-bottom: 20px;
  display: block;
  color: #bb2124;
  z-index: 10;
`;

const PI = styled.p`  
  margin-top: 1%;  
  width: 90%;  
  font-size: 20px;  
  overflow-wrap: break-word; // Permite que las palabras largas se dividan si es necesario  
  word-wrap: break-word; // Alternativa para compatibilidad con navegadores más antiguos  
  hyphens: auto; // Habilita los guiones en palabras largas en navegadores compatibles  
  
  text-align: justify; // Alinea el texto de manera que ambos lados (izquierda y derecha) estén justificados  

  @media screen and (max-width: 780px) {  
    font-size: 18px;  
    width: 95%;  
  }  
`;  
const ButtonDownload = styled.button`
    padding: 10px 20px;
    background-color: #04AA6D;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;


    &:hover {
      background-color: #048254;
    }
`;

const DivNavbar = styled.div`  
  position: fixed; /* Cambiar a posición fija */  
  top: 0; /* Colocarla en la parte superior */  
  left: 0; /* Asegurar que ocupe todo el ancho */  
  width: 100%;  
  background-color: #f1f1f1; /* Puedes establecer el fondo si es necesario */  
  /* Es posible que también desees añadir un poco de padding o altura según tu diseño */  
  padding: 0px; /* Ajusta esto según sea necesario */  
  z-index:999;
  margin-bottom:50%;
`;  

const Asterisk = styled.span`
    color: red;
    margin-left: 4px; /* Ajusta el espacio entre el texto del label y el asterisco */
`;

const totalSteps = 7; 

const DocumentDisplay = ({ certificado, clearCertificado }) => {
  const isFromDB = certificado && certificado.nombre_documento;
  const isLoadedByUser = certificado && certificado[0] && certificado[0].name;

  const handleDelete = () => {
    if (isFromDB) {
      // Simulate an API call to delete the document from the database
      deleteDocument(certificado.id_documento).then((response) => {
        if(response){
          clearCertificado(null);
        }
      })
    } else if (isLoadedByUser) {
      clearCertificado(null);
    }
  };

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };
  const handleDownload = () => {
    if (isFromDB) {
      const mimeType = certificado.tipo_documento; // Adjust based on your document type
      const blob = base64ToBlob(certificado.documento, mimeType);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = certificado.nombre_documento;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } else if (isLoadedByUser) {
      const file = certificado[0];
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    }
  };


  if (isFromDB) {
    return (
      <div style={{ display: 'flex' }}>
        <p>Nombre del documento: {certificado.nombre_documento}</p>
        <div style={{marginTop:'2.5%'}}>
        <button
          type="button"
          onClick={handleDownload}
          style={{
            cursor: 'pointer',
            color: 'blue',
            border: '0px',
            borderRadius: '25px',
            width: '5%',
            marginTop: '2.5%',
            height: '5%'
          }}
        >
         <IoMdDownload size={24} />
        </button>
        </div>
      <div style={{marginTop:'2.5%'}}>
      <button
          type="button"
          onClick={handleDelete}
          style={{
            cursor: 'pointer',
            color: 'red',
            border: '0px',
            borderRadius: '25px',
            width: '5%',
            marginTop: '2.5%',
            height: '5%'
          }}
        >
          X
        </button>
      </div>
    
      </div>
    );
  }

  if (isLoadedByUser) {
    return (
      <div style={{ display: 'flex' }}>
        <p>Nuevo documento: {certificado[0].name}</p>
        <button
          type="button"
          onClick={handleDelete}
          style={{
            cursor: 'pointer',
            color: 'red',
            border: '0px',
            borderRadius: '25px',
            width: '5%',
            marginTop: '2.5%',
            height: '5%'
          }}
        >
          x
        </button>
      </div>
    );
  }

  return null; // Hide when neither condition is met
};




const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(-1);
  const [errores, setErrores] = useState([]);
  const [animating, setAnimating] = useState(false);
  const [solicitud, setSolicitud] = useState([])
  const [fecha,setFecha] = useState(0);
  const [solicitudDraft, setSolicitudDraft] = useState([])
  const [isDraft, setIsDraft] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const [tokenData , setTokenData] = useState({});
  const [respuestas, setRespuestas] = useState([]);
  const [presupuestos, setPresupuestos] = useState([]);
  const [presupuestoGO, setPresupuestosGO] = useState([]);
  const [presupuestoInfra, setPresupuestosInfra] = useState([]);
  const [presupuestoHhrr, setPresupuestosHhrr] = useState([]);
  const [certificadoVigencia, setCertificadoVigencia] = useState([]);
  const [certificadoCedulaREP, setCertificadoCedulaREP] = useState([]);
  const [certificadoCedulaDire, setCertificadoCedulaDire] = useState([]);
  const [certificadoCedulaOtroDire, setCertificadoCedulaOtroDire] = useState([]);
  const [certificadoBancario, setCertificadoBancario] = useState([]);
  const [certificadoCartaCompromiso, setCertificadoCartaCompromiso] = useState([]);
  const [certificadoCotizacion, setCertificadoCotizacion] = useState([]);
  const [draftLoading ,setDraftLoading] = useState(false);
  const [pendingDisabled,setPendingDisabled] = useState(false);

  
  const deleteDocumentoDB  = async () => {
    try{
      setCertificadoVigencia([])
      deleteDocument(certificadoVigencia.id_documento).then((certificadoVigencia) => {
        
      })
     }catch(e){
        
     }
  }

  const [isLoadingSubmit, setIsLoadingSubmit]= useState(false);
  
  
  useEffect( () => {
    const userData = sessionStorage.getItem('userData');
    const token = sessionStorage.getItem('token');
    setTokenData(token);
    const user = JSON.parse(userData);
    setUser(user);
    
    const solicitudesEnabled = sessionStorage.getItem('solicitudesEnabled')
    const timeoutMethod = (callback, delay) => {
      setTimeout(callback, delay);
    };
    
 
    let method= ((param) => {
    
    
      getSolicitudById(param, token).then((solicitud) => {
        if(solicitud){
          setSolicitud(solicitud);
          const calculateDaysRemaining = () => {
            const fechaObjetivo = new Date(solicitud.fecha_fin);
            const fechaActual = new Date();
            const diferenciaMilisegundos = fechaObjetivo - fechaActual;
            const diasRestantes = Math.ceil(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
            setFecha(diasRestantes);
          };
          calculateDaysRemaining();
         
         
        }
      },[solicitud]);
            
            // fetchPresupuestos(param).then((presupuestos) => {
      //   if(presupuestos){
      //     setPresupuestos(presupuestos);
      //   }
      // })


    })

    const queryParams = new URLSearchParams(window.location.search);

    if(user.rol==='Postulante' && !solicitudesEnabled.includes(queryParams.get('id_postulacion')) && !queryParams.get('id_proceso') ){
      window.location.href = '/feedPostulante';
    }
    if(queryParams.get('id_proceso')){
      const queryParamValue = queryParams.get('id_proceso');
      method(queryParamValue);

     setIsLoading(false);
    }
    else if(queryParams.get('id_postulacion')){
      const queryParamValue = queryParams.get('id_postulacion');
      setIsDraft(true)
      setPendingDisabled(solicitudDraft.estado!='Borrador')
      getSolicitudInfoById(queryParamValue, token).then((solicitud) => {
      
      setSolicitudDraft(solicitud);
      var StepOne = true;
      var StepTwo = true;
      var StepFive = true;
      var ArrayOne = [solicitud.nombre_proyecto, solicitud.numero_beneficiarios, solicitud.linea_financiamiento, solicitud.aporte_solicitado]
      var ArrayTwo = [solicitud.nombre_organizacion,solicitud.rut_organizacion,solicitud.direccion_org, solicitud.nombre_representante_legal, solicitud.rut_representante_legal, solicitud.direccion_rep_legal, solicitud.telefono_rep_legal, solicitud.correo_rep_legal, solicitud.nombre_responsable_proy, solicitud.rut_responsable_proy, solicitud.direccion_responsable_proy, solicitud.telefono_responsable_proy, solicitud.correo_responsable_proy ]
      var ArraFive = [solicitud.gasto_operacional_item_0, solicitud.gasto_operacional_item_1, solicitud.gasto_operacional_item_2, solicitud.gasto_operacional_utilizacion_0, solicitud.gasto_operacional_utilizacion_1, solicitud.gasto_operacional_utilizacion_2,solicitud.gasto_operacional_monto_0,solicitud.gasto_operacional_monto_1,solicitud.gasto_operacional_monto_2]
      setPresupuestosGO([solicitud.gasto_operacional_monto_0,solicitud.gasto_operacional_monto_1,solicitud.gasto_operacional_monto_2,solicitud.gasto_operacional_monto_3,solicitud.gasto_operacional_monto_4])
      setPresupuestosInfra([solicitud.infra_monto_0,solicitud.infra_monto_1])
      setPresupuestosHhrr([solicitud.rrhh_monto_0,solicitud.rrhh_monto_1])
      ArrayOne.forEach(
        (element)=>{if (!element ){StepOne = false}}
        )
        ArrayTwo.forEach(
        (element)=>{if (!element ){StepTwo = false}}
        )

        ArraFive.forEach(
          (element)=>{if (!element ){StepFive = false}}
          )
          

      stepValid[0]=StepOne;
      stepValid[1]=StepTwo;
      stepValid[4]=StepFive;

      method(solicitud.id_proceso)

      

   
     
      },[solicitudDraft]);
      try{
        fetchRespuestas(queryParamValue).then((respuestasX) => {
          if(respuestasX){
            var respuestas = respuestasX[0];
            setRespuestas(respuestas);
            
            var StepThree = true;
            var StepFour = true;
        
           
            var ArrayThree = [respuestas.identificacion_problema, respuestas.beneficiarios, respuestas.descripcion_proyecto, respuestas.objetivo_general, respuestas.objetivo_especifico_1, respuestas.objetivo_especifico_2, respuestas.objetivo_especifico_3]
            var ArrayFour = [respuestas.nombre_actividad_0, respuestas.descripcion_actividad_0, respuestas.nombre_actividad_1, respuestas.descripcion_actividad_1, respuestas.nombre_actividad_2, respuestas.descripcion_actividad_2  ]
          
           
            ArrayThree.forEach(
            (element)=>{if (!element ){StepThree = false}}
            )
            ArrayFour.forEach(
            (element)=>{if (!element ){StepFour = false}}
            )
         
            stepValid[2]=StepThree;
            stepValid[3]=StepFour;
           
          }
      //    
       
      },[respuestas]).finally(() => {
        
        timeoutMethod(() => {
          setIsLoading(false);
        }, 2000); // Adjust the delay as needed
      });
      }catch(e){
        
      }
      
      try{
        fetchDocument(queryParamValue).then((certificado) => {
          if(certificado){
            
            certificado.forEach(element => {

              
              if(element.categoria==='Certificado de vigen'){
                setCertificadoVigencia(element);
              }
              else if(element.categoria==='Certificado de cedul'){
                setCertificadoCedulaREP(element);
            }else if(element.categoria ==='CertificadoBancario'){
              setCertificadoBancario(element);
            }
            else if(element.categoria==='CertificadoCartaComp'){
              setCertificadoCartaCompromiso(element);
            }
            else if(element.categoria==='Cedula directiva 2'){
              setCertificadoCedulaOtroDire(element);
            }
            else if(element.categoria==='Cedula directiva'){
              setCertificadoCedulaDire(element);
            }
            else if(element.categoria==='CertificadoCotizacio'){
              setCertificadoCotizacion(element);
            }
        })
      }
        })
     
      }catch(er){
        
      }
      
  } else {
    setIsLoading(false);
  }


  

   
    
    
  }, []);

  /* 
 



*/
const [certificados, setCertificados] = useState({
  certificado1: true,
  certificado2: true,
  certificado3: true,
  certificado4: true,
  certificado5: true,
  certificado6: true,
  certificado7: true,
});
const [certificadosFalse, setCertificadosFalse] = useState({
  certificado1: false,
  certificado2: false,
  certificado3: false,
  certificado4: false,
  certificado5: false,
  certificado6: false,
  certificado7: false,
});

useEffect(() => {
    
  if (
    certificadoCartaCompromiso && certificadoCartaCompromiso.length > 0 &&
    certificadoCotizacion && certificadoCotizacion.length > 0 &&
    certificadoBancario && certificadoBancario.length > 0 &&
    certificadoCedulaOtroDire && certificadoCedulaOtroDire.length > 0 &&
    certificadoCedulaDire && certificadoCedulaDire.length > 0 &&
    certificadoCedulaREP && certificadoCedulaREP.length > 0 &&
    certificadoVigencia && certificadoVigencia.length > 0
) {
    validateCertificados(certificados);
} else {
    validateCertificados(certificadosFalse);
}

  

   
  }
);

  const [stepValid, setStepValid] = useState(Array(totalSteps).fill(false));
  const allStepsValid = stepValid.every(step => step === true);



  const nextStep = () => {
    setAnimating(true);
    setTimeout(() => {
      setCurrentStep(currentStep + 1);
      setTimeout(() => {
        setAnimating(false);
      }, 500);
    }, 0);
  };

  const prevStep = () => {
    setAnimating(true);
    setTimeout(() => {
      setCurrentStep(currentStep - 1);
      setTimeout(() => {
        setAnimating(false);
      }, 500);
    }, 0);
  };

  const validateCertificados = (certificados) => {
    const errors = {};
    Object.keys(certificados).forEach((key, index) => {
      if (!certificados[key]) {
        errors[key] = `Debe cargar el certificado ${index + 1}`;
      }
    });

    const errorMessages = Object.values(errors);
    setErrores(errorMessages);

    setStepValid(prev => {
      const newValidations = [...prev];
      newValidations[5] = errorMessages.length === 0;
      return newValidations;
    });

    return errors;
  };

  const validateRut = (rut) => {
    if (!rut) {
        return 'RUT requerido';
    }

    if (!Fn.validaRut(rut)) {
        return 'Invalido!, El rut debe ir sin puntos y Con guion';
    }

    return null; // Retorna null si el RUT es válido
}

  const validate = (values, currentStep) => {
    const errors = {};
  
      if (currentStep === 0) {
        if (!values.NombreProyecto) {
          errors.NombreProyecto = 'Nombre proyecto Requerido';
        } else if (values.NombreProyecto.length < 5) {
          errors.NombreProyecto = 'Nombre proyecto Es muy corto';
        }
        if (!values.radio) {
          errors.radio = 'Linea de financiamiento Requerido';
        }

        if (values.NumBeneficiarios <= 0 || values.NumBeneficiarios == null || values.NumBeneficiarios === '') {
          errors.NumBeneficiarios = 'Num. Beneficiarios requerido';
        }
        if (values.AporteSolicitado <= 0 || values.AporteSolicitado == null || values.AporteSolicitado === '') {
          errors.AporteSolicitado = 'Aporte solicitado requerido';
        }
        if(values.Aporte>1000000){
          errors.AporteSolicitado = 'El máximo a solicitar es de $1.000.000';
        }
        
        } 
      else if (currentStep === 1) {
        if (!values.NombreOrg) {
            errors.NombreOrg = 'Nombre de la organización requerido';
        } else if (values.NombreOrg.length < 3) {
            errors.NombreOrg = 'Nombre de la organización muy corto';
        }
        if (!values.RUTOrg) {
            errors.RUTOrg = 'RUT de la organización requerido';
        } else {
          const rutError0 = validateRut(values.RUTOrg);
          if (rutError0) {
              errors.RUTOrg = rutError0;
          }
      }
        if (!values.DirOrg) {
            errors.DirOrg = 'Dirección de la organización requerida';
        } else if (values.DirOrg.length < 3) {
            errors.DirOrg = 'Dirección de la organización muy corta';
        }
        if (!values.NombreRepLegal) {
            errors.NombreRepLegal = 'Nombre del representante legal requerido';
        } else if (values.NombreRepLegal.length < 3) {
            errors.NombreRepLegal = 'Nombre del representante legal muy corto';
        }
        if (!values.RUTRespProyecto) {
          errors.RUTRespProyecto = 'RUT del responsable del proyecto requerido';
      } else {
          const rutError = validateRut(values.RUTRespProyecto);
          if (rutError) {
              errors.RUTRespProyecto = rutError;
          }
      }
        if (!values.DirRepLegal) {
            errors.DirRepLegal = 'Dirección del representante legal requerida';
        } else if (values.DirRepLegal.length < 3) {
            errors.DirRepLegal = 'Dirección del representante legal muy corta';
        }
        if (!values.TelRepLegal) {
            errors.TelRepLegal = 'Teléfono del representante legal requerido';
        }
        if (!values.CorreoRepLegal) {
            errors.CorreoRepLegal = 'Correo del representante legal requerido';
        } else if (!isValidEmail(values.CorreoRepLegal)) {
            errors.CorreoRepLegal = 'Formato de correo del representante legal inválido';
        }
        if (!values.NombreRespProyecto) {
            errors.NombreRespProyecto = 'Nombre del responsable del proyecto requerido';
        } else if (values.NombreRespProyecto.length < 3) {
            errors.NombreRespProyecto = 'Nombre del responsable del proyecto muy corto';
        }
        if (!values.RUTRepLegal) {
            errors.RUTRepLegal = 'RUT del responsable del proyecto requerido';
        }else {
          const rutError1 = validateRut(values.RUTRepLegal);
          if (rutError1) {
              errors.RUTRepLegal = rutError1;
          }
      }
        if (!values.DirRespProyecto) {
            errors.DirRespProyecto = 'Dirección del responsable del proyecto requerida';
        } else if (values.DirRespProyecto.length < 3) {
            errors.DirRespProyecto = 'Dirección del responsable del proyecto muy corta';
        }
        if (!values.TelRespProyecto) {
            errors.TelRespProyecto = 'Teléfono del responsable del proyecto requerido';
        }
        if (!values.CorreoRespProyecto) {
            errors.CorreoRespProyecto = 'Correo del responsable del proyecto requerido';
        } else if (!isValidEmail(values.CorreoRespProyecto)) {
            errors.CorreoRespProyecto = 'Formato de correo del responsable del proyecto inválido';
        }
    }else if (currentStep === 2) {

      if (!values.IdProblema) {
        errors.IdProblema = 'Identificación del Problema Requerido';
      } else if (values.IdProblema.length < 5) {
        errors.IdProblema = 'Identificación del Problema Es muy corto';
      }
      if (!values.Beneficiarios) {
        errors.Beneficiarios = 'Beneficiarios Requerido';
      } else if (values.Beneficiarios.length < 5) {
        errors.Beneficiarios = 'Beneficiarios Es muy corto';
      }
      if (!values.DesProyecto) {
        errors.DesProyecto = 'Descripcion de problema Requerido';
      } else if (values.DesProyecto.length < 5) {
        errors.DesProyecto = 'Descripcion de problema Es muy corto';
      }
      if (!values.ObjetivoGeneral) {
        errors.ObjetivoGeneral = 'Objetivo general Requerido';
      } else if (values.ObjetivoGeneral.length < 5) {
        errors.ObjetivoGeneral = 'Descripcion de problema Es muy corto';
      }
      if (!values.ObjetivoEspec1) {
        errors.ObjetivoEspec1 = 'Objetivo especifico 1 Requerido';
      } else if (values.ObjetivoEspec1.length < 5) {
        errors.ObjetivoEspec1 = 'Objetivo especifico 1 Es muy corto';
      }
      if (!values.ObjetivoEspec2) {
        errors.ObjetivoEspec2 = 'Objetivo especifico 2 Requerido';
      } else if (values.ObjetivoEspec2.length < 5) {
        errors.ObjetivoEspec2 = 'Objetivo especifico 2 Es muy corto';
      }
    }
    else if (currentStep === 3) {

      for (let i = 0; i < 3; i++) {
        if (!values[`ActividadesNombre_${i}`]) {
          errors[`ActividadesNombre_2`] = `Nombre de la actividad Requerido`;
        } else if (values[`ActividadesNombre_${i}`].length < 5) {
          errors[`ActividadesNombre_2`] = `Nombre de la actividad Es muy corto`;
        }
        
        if (!values[`ActividadesDescripcion_${i}`]) {
          errors[`ActividadesDescripcion_2`] = `Descripción de la actividad Requerido`;
        } else if (values[`ActividadesDescripcion_${i}`].length < 5) {
          errors[`ActividadesDescripcion_2`] = `Descripción de la actividad Es muy corto`;
        }
      }
      if (!values.PlanDifusion) {
        errors.PlanDifusion = 'Plan de difusion Requerido';
      } else if (values.PlanDifusion.length < 5) {
        errors.PlanDifusion = 'Plan de difusion Es muy corto';
      }
      
    }
    else if (currentStep === 4) {

      for (let index = 0; index < 3; index++) {
        if (!values[`GoperacionalesItem_${index}`]) {
          errors[`GoperacionalesItem_${index}`] = `Item gasto operacional Requerido`;
        } else if (values[`GoperacionalesItem_${index}`].length < 5) {
          errors[`GoperacionalesItem_${index}`] = `Item gasto operacional Es muy corto`;
        }
        
        if (!values[`GoperacionalesUtilizacion_${index}`]) {
          errors[`GoperacionalesUtilizacion_${index}`] = `Utilizacion gasto operacional Requerido`;
        } else if (values[`GoperacionalesUtilizacion_${index}`].length < 5) {
          errors[`GoperacionalesUtilizacion_${index}`] = `Utilizacion gasto operacional Es muy corto`;
        }
      
        const montoSolicitado = goperacionalesMontoSolicitados[index];
        if (montoSolicitado <= 0 || montoSolicitado == null || montoSolicitado == '') {
          errors[`GoperacionalesMontoSolicitado_${index}`] = `Monto solicitado requerido`;
        }
      }

      }
      else if (currentStep === 5) {
        
        //validateCertificados(certificados);
       
      

      }
    
      const errorMessages = Object.values(errors);
      setErrores(errorMessages);
  
      setStepValid(prev => {
        const newValidations = [...prev];

        newValidations[currentStep] = errorMessages.length === 0;

        return newValidations;
      });
  
      return errors;
    };
    
    const errorMessageStyle = {
      color: 'red',
    };

    

    const [goperacionalesMontoSolicitados, setGoperacionalesMontoSolicitados] = useState(Array(5).fill(0));



    
    useEffect(() => {
      var fill = []
      
      if(presupuestoGO){
        for (let i = 0; i < 5; i++) {
          fill.push(presupuestoGO[i])
        }
      }else{
        fill = [0,0,0,0,0]
      }

      setGoperacionalesMontoSolicitados(fill);
      calculateTotal(fill)
    }, [presupuestoGO]);

   const [goperacionalesTotal, setGoperacionalesTotal] = useState(0);

const handleGoperacionalesMontoSolicitadoChange = (index, event) => {
  const newGoperacionalesMontoSolicitados = [...goperacionalesMontoSolicitados];
  newGoperacionalesMontoSolicitados[index] = event.target.value;
  setGoperacionalesMontoSolicitados(newGoperacionalesMontoSolicitados);

  calculateTotal(newGoperacionalesMontoSolicitados);
};

const calculateTotal = (montoSolicitados) => {
  const total = montoSolicitados.reduce((acc, monto) => {
    return acc + (parseInt(monto) || 0); // Sumar solo si 'monto' es un número válido
  }, 0);
  setGoperacionalesTotal(total);
};


    const [infraestructuraMontoSolicitados, setInfraestructuraMontoSolicitados] = useState(Array(2).fill(0));

    useEffect(() => {
      var fill = []
      if(presupuestoInfra){
        for (let i = 0; i < 2; i++) {
          fill.push(presupuestoInfra[i])
        }
      }else{
        fill = [0,0]
      }
      setInfraestructuraMontoSolicitados(fill);
      calculateInfraestructuraTotal(fill);
    }, [presupuestoInfra]);
    const [infraestructuraTotal, setInfraestructuraTotal] = useState(0);
  
    const handleInfraestructuraMontoSolicitadoChange = (index, event) => {
      const newInfraestructuraMontoSolicitados = [...infraestructuraMontoSolicitados];
      newInfraestructuraMontoSolicitados[index] = event.target.value;
      setInfraestructuraMontoSolicitados(newInfraestructuraMontoSolicitados);
      calculateInfraestructuraTotal(newInfraestructuraMontoSolicitados);
    };
  
    const calculateInfraestructuraTotal = (montoSolicitados) => {
      const total = montoSolicitados.reduce((acc, monto) => {
        return acc + (parseInt(monto) || 0); // Sumar solo si 'monto' es un número válido
      }, 0);
      setInfraestructuraTotal(total);
    };

    const [recursosHumanosMontoSolicitados, setRecursosHumanosMontoSolicitados] = useState(Array(2).fill());
    useEffect(() => {
      var fill = []
      if(presupuestoHhrr){
        for (let i = 0; i < 2; i++) {
          fill.push(presupuestoHhrr[i])
        }
      }else{
        fill = [0,0]
      }
      setRecursosHumanosMontoSolicitados(fill);
      
      calculateRecursosHumanosTotal(fill);
    }, [presupuestoHhrr]);

    const [recursosHumanosTotal, setRecursosHumanosTotal] = useState(0);
  
    const handleRecursosHumanosMontoSolicitadoChange = (index, event) => {
      const newRecursosHumanosMontoSolicitados = [...recursosHumanosMontoSolicitados];
      newRecursosHumanosMontoSolicitados[index] = event.target.value;
      setRecursosHumanosMontoSolicitados(newRecursosHumanosMontoSolicitados);
      calculateRecursosHumanosTotal(newRecursosHumanosMontoSolicitados);
    };
  
    const calculateRecursosHumanosTotal = (montoSolicitados) => {
      
      const total = montoSolicitados.reduce((acc, monto) => {
        return acc + (parseInt(monto) || 0); // Sumar solo si 'monto' es un número válido
      }, 0);
      setRecursosHumanosTotal(total);
    };



    const handleClick = (step) => {
      if (!animating) {
        setCurrentStep(step);
      }
    };

      ///////////////////////////////////////////////////////////////
    /////////////////////////////////////Funcion Calculo de fecha de termino de postulacion
    ///////////////////////////////////////////////////////////////
   

    ///////////////////////////////////////////////////////////////
    /////////////////////////////////////Funcion BTN DESCARGAR BASES BASE64
    ///////////////////////////////////////////////////////////////

// Assuming you have a variable named "myVariable" that you want to export


    
  //   function downloadPDF() {  
  //     // Crea un enlace temporal  
  //     const link = document.createElement('a');  
  //     link.href = Bases;  
  //     link.download = 'Bases.pdf'; // nombre con el que se guardará el archivo  
      
  //     // Simula un clic en el enlace  
  //     document.body.appendChild(link);  
  //     link.click();  
      
  //     // Remueve el enlace temporal del documento  
  //     document.body.removeChild(link);  
  // }  
    ///////////////////////////////////////////////////////////////
    /////////////////////////////////////Funcion de envio Formulario completo
    ///////////////////////////////////////////////////////////////
    const handleUploadDocument = async (documentos) => {
      try {
        const uploadPromises = documentos.map(documento => uploadDocument(documento));
        const responses = await Promise.all(uploadPromises);
        responses.forEach(response => {
        
        });
      } catch (error) {
        alert("Error al subir el documento.");
      }
    };

    const handleSubmit = async (values) => {

      if(isDraft){
        setIsLoadingSubmit(true)
        const queryParams = new URLSearchParams(window.location.search);
        var id_solicitud_ = queryParams.get('id_postulacion');
        function formatDate(date) {
          var year = date.getFullYear();
          var month = ('0' + (date.getMonth() + 1)).slice(-2);  // Adding 1 because getMonth() returns zero-based index
          var day = ('0' + date.getDate()).slice(-2);
          return year + '-' + month + '-' + day;

        }
        var createSolicitudForm = {
          nombre_proyecto: values.NombreProyecto,
          nombre_organizacion: values.NombreOrg ? values.NombreOrg : '',
          numero_beneficiarios: values.NumBeneficiarios,
          linea_financiamiento: values.radio,
          aporte_solicitado: values.AporteSolicitado,
          rut_organizacion: values.RUTOrg,
          direccion_org: values.DirOrg,
          nombre_representante_legal: values.NombreRepLegal,
          rut_representante_legal: values.RUTRepLegal,
          direccion_rep_legal: values.DirRepLegal,
          telefono_rep_legal: values.TelRepLegal ? values.TelRepLegal.toString() : '',
          correo_rep_legal: values.CorreoRepLegal,
          nombre_responsable_proy: values.NombreRespProyecto,
          rut_responsable_proy: values.RUTRespProyecto,
          direccion_responsable_proy: values.DirRespProyecto,
          telefono_responsable_proy: values.TelRespProyecto ?  values.TelRespProyecto.toString() : '',
          correo_responsable_proy: values.CorreoRespProyecto,
          id_proceso:3,
          estado:'Pendiente',
          rut_evaluador:null,
          rut_solicitante:user.rut_usuario,
          email_solicitante:user.correo_usuario,
          Fecha_Postulacion: formatDate(new Date()),
          gasto_operacional_item_0 : values.GoperacionalesItem_0,
          gasto_operacional_utilizacion_0 : values.GoperacionalesUtilizacion_0,
          gasto_operacional_monto_0 : parseInt(goperacionalesMontoSolicitados[0]),
          gasto_operacional_item_1 : values.GoperacionalesItem_1,
          gasto_operacional_utilizacion_1 : values.GoperacionalesUtilizacion_1,
          gasto_operacional_monto_1 : parseInt(goperacionalesMontoSolicitados[1]),
          gasto_operacional_item_2 : values.GoperacionalesItem_2,
          gasto_operacional_utilizacion_2 : values.GoperacionalesUtilizacion_2,
          gasto_operacional_monto_2 : parseInt(goperacionalesMontoSolicitados[2]),
          gasto_operacional_item_3 : values.GoperacionalesItem_3,
          gasto_operacional_utilizacion_3 : values.GoperacionalesUtilizacion_3,
          gasto_operacional_monto_3 : parseInt(goperacionalesMontoSolicitados[3]),
          gasto_operacional_item_4 : values.GoperacionalesItem_4,
          gasto_operacional_utilizacion_4 : values.GoperacionalesUtilizacion_4,
          gasto_operacional_monto_4 : parseInt(goperacionalesMontoSolicitados[4]),
          infra_item_0 : values.InfraestructuraItem_0,
          infra_utilizacion_0 : values.InfraestructuraUtilizacion_0,
          infra_monto_0 : parseInt(infraestructuraMontoSolicitados[0]),
          infra_item_1 : values.InfraestructuraItem_1,
          infra_utilizacion_1 : values.InfraestructuraUtilizacion_1,
          infra_monto_1 : parseInt(infraestructuraMontoSolicitados[1]),
          rrhh_item_0 : values.RecursosHumanosItem_0,
          rrhh_utilizacion_0 : values.RecursosHumanosUtilizacion_0,
          rrhh_monto_0 : parseInt(recursosHumanosMontoSolicitados[0]),
          rrhh_item_1 : values.RecursosHumanosItem_1,
          rrhh_utilizacion_1 : values.RecursosHumanosUtilizacion_1,
          rrhh_monto_1 : parseInt(recursosHumanosMontoSolicitados[1]),
          plan_difusion: values.PlanDifusion,
          is_draft:false,
      };

      var responsesp3 = {
        id_solicitud : id_solicitud_,
        identificacion_problema: values.IdProblema,
        beneficiarios: values.Beneficiarios,
        descripcion_proyecto: values.DesProyecto,
        objetivo_general: values.ObjetivoGeneral,
        objetivo_especifico_1: values.ObjetivoEspec1,
        objetivo_especifico_2: values.ObjetivoEspec2,
        objetivo_especifico_3: values.ObjetivoEspec3,
        nombre_actividad_0: values.ActividadesNombre_0,
        descripcion_actividad_0: values.ActividadesDescripcion_0,
        nombre_actividad_1: values.ActividadesNombre_1,
        descripcion_actividad_1: values.ActividadesDescripcion_1,
        nombre_actividad_2: values.ActividadesNombre_2,
        descripcion_actividad_2: values.ActividadesDescripcion_2,
        nombre_actividad_3: values.ActividadesNombre_3,
        descripcion_actividad_3: values.ActividadesDescripcion_3,
        nombre_actividad_4: values.ActividadesNombre_4,
        descripcion_actividad_4: values.ActividadesDescripcion_4,
      }

      try{

        var ListDocuments = []
        if(certificadoVigencia[0]){
          const base64EncodedFile = await fileToBase64(certificadoVigencia[0]);
        var documento = {
          id_solicitud : parseInt(id_solicitud_),
          tipo_documento : certificadoVigencia[0].type,
          documento : base64EncodedFile,
          categoria : 'Certificado de vigencia',
          nombre_documento : certificadoVigencia[0].name
        }
        ListDocuments.push(documento)

        }
        if(certificadoCedulaREP[0]){
          const base64EncodedFile = await fileToBase64(certificadoCedulaREP[0]);
          var documento = {
            id_solicitud : parseInt(id_solicitud_),
            tipo_documento : certificadoCedulaREP[0].type,
            documento : base64EncodedFile,
            categoria : 'Certificado de cedula representante legal',
            nombre_documento : certificadoCedulaREP[0].name
          }
          ListDocuments.push(documento)
        }
        if(certificadoCedulaDire[0]){
          const base64EncodedFile = await fileToBase64(certificadoCedulaDire[0]);
          var documento = {
            id_solicitud : parseInt(id_solicitud_),
            tipo_documento : certificadoCedulaDire[0].type,
            documento : base64EncodedFile,
            categoria : 'Cedula directiva',
            nombre_documento : certificadoCedulaDire[0].name
          }
          ListDocuments.push(documento)
        }

        if(certificadoCedulaOtroDire[0]){
          const base64EncodedFile = await fileToBase64(certificadoCedulaOtroDire[0]);
          var documento = {
            id_solicitud : parseInt(id_solicitud_),
            tipo_documento : certificadoCedulaOtroDire[0].type,
            documento : base64EncodedFile,
            categoria : 'Cedula directiva 2',
            nombre_documento : certificadoCedulaOtroDire[0].name
          }
          ListDocuments.push(documento)
        }
        if(certificadoBancario[0]){
          const base64EncodedFile = await fileToBase64(certificadoBancario[0]);
          var documento = {
            id_solicitud : parseInt(id_solicitud_),
            tipo_documento : certificadoBancario[0].type,
            documento : base64EncodedFile,
            categoria : 'CertificadoBancario',
            nombre_documento : certificadoBancario[0].name
          }
          ListDocuments.push(documento)
        }

        if(certificadoCartaCompromiso[0]){
          const base64EncodedFile = await fileToBase64(certificadoCartaCompromiso[0]);
          var documento = {
            id_solicitud : parseInt(id_solicitud_),
            tipo_documento : certificadoCartaCompromiso[0].type,
            documento : base64EncodedFile,
            categoria : 'CertificadoCartaCompromiso',
            nombre_documento : certificadoCartaCompromiso[0].name
          }
          ListDocuments.push(documento)
        }
        if(certificadoCotizacion[0]){
          const base64EncodedFile = await fileToBase64(certificadoCotizacion[0]);
          var documento = {
            id_solicitud : parseInt(id_solicitud_),
            tipo_documento : certificadoCotizacion[0].type,
            documento : base64EncodedFile,
            categoria : 'CertificadoCotizacion',
            nombre_documento : certificadoCotizacion[0].name
          }
          ListDocuments.push(documento)
        }
         updateSolicitud(id_solicitud_, createSolicitudForm).then((response) => {
           if(response){
             updateRespuestas(id_solicitud_,responsesp3).then((response) => {
               if(response){
               
                
                if(ListDocuments.length > 0 ){
                  try{
        
                    handleUploadDocument(ListDocuments).then((response) => {
                      setIsLoadingSubmit(false)
                      alert("Formulario enviado correctamente");
                      window.location.href = '/FeedPostulante';
                    });
                
              }catch(er){
                alert("Error al cargar los documentos, intente mas tarde")
              }
              }else{
                  setIsLoadingSubmit(false)
                  alert("Formulario enviado correctamente");
                  window.location.href = '/FeedPostulante';
                }
               }
             })
           }
         })
       


       
      
      }catch(error){
        alert("Error al enviar formulario, verifica la conexión a internet o intentalo de nuevo más tarde.")
      }

    
      }else{
        setIsLoadingSubmit(true)
        function formatDate(date) {
          var year = date.getFullYear();
          var month = ('0' + (date.getMonth() + 1)).slice(-2);  // Adding 1 because getMonth() returns zero-based index
          var day = ('0' + date.getDate()).slice(-2);
          return year + '-' + month + '-' + day;
        }
        var createSolicitudForm2 = {
          nombre_proyecto: values.NombreProyecto!="" ? values.NombreProyecto : null,
          nombre_organizacion: values.NombreOrg!="" ? values.NombreOrg : null,
          numero_beneficiarios: values.NumBeneficiarios,
          linea_financiamiento: values.radio,
          aporte_solicitado: values.AporteSolicitado,
          rut_organizacion: values.RUTOrg,
          direccion_org: values.DirOrg,
          nombre_representante_legal: values.NombreRepLegal,
          rut_representante_legal: values.RUTRepLegal,
          direccion_rep_legal: values.DirRepLegal,
          telefono_rep_legal: values.TelRepLegal ? values.TelRepLegal.toString() : '',
          correo_rep_legal: values.CorreoRepLegal,
          nombre_responsable_proy: values.NombreRespProyecto,
          rut_responsable_proy: values.RUTRespProyecto,
          direccion_responsable_proy: values.DirRespProyecto,
          telefono_responsable_proy: values.TelRespProyecto ?  values.TelRespProyecto.toString() : '',
          correo_responsable_proy: values.CorreoRespProyecto,
          id_proceso:3,
          estado:'Pendiente',
          rut_evaluador:null,
          rut_solicitante:user.rut_usuario,
          email_solicitante:user.correo_usuario,
          Fecha_Postulacion: formatDate(new Date()),
          gasto_operacional_item_0 : values.GoperacionalesItem_0,
          gasto_operacional_utilizacion_0 : values.GoperacionalesUtilizacion_0,
          gasto_operacional_monto_0 : parseInt(goperacionalesMontoSolicitados[0]),
          gasto_operacional_item_1 : values.GoperacionalesItem_1,
          gasto_operacional_utilizacion_1 : values.GoperacionalesUtilizacion_1,
          gasto_operacional_monto_1 : parseInt(goperacionalesMontoSolicitados[1]),
          gasto_operacional_item_2 : values.GoperacionalesItem_2,
          gasto_operacional_utilizacion_2 : values.GoperacionalesUtilizacion_2,
          gasto_operacional_monto_2 : parseInt(goperacionalesMontoSolicitados[2]),
          gasto_operacional_item_3 : values.GoperacionalesItem_3,
          gasto_operacional_utilizacion_3 : values.GoperacionalesUtilizacion_3,
          gasto_operacional_monto_3 : parseInt(goperacionalesMontoSolicitados[3]),
          gasto_operacional_item_4 : values.GoperacionalesItem_4,
          gasto_operacional_utilizacion_4 : values.GoperacionalesUtilizacion_4,
          gasto_operacional_monto_4 : parseInt(goperacionalesMontoSolicitados[4]),
          infra_item_0 : values.InfraestructuraItem_0,
          infra_utilizacion_0 : values.InfraestructuraUtilizacion_0,
          infra_monto_0 : parseInt(infraestructuraMontoSolicitados[0]),
          infra_item_1 : values.InfraestructuraItem_1,
          infra_utilizacion_1 : values.InfraestructuraUtilizacion_1,
          infra_monto_1 : parseInt(infraestructuraMontoSolicitados[1]),
          rrhh_item_0 : values.RecursosHumanosItem_0,
          rrhh_utilizacion_0 : values.RecursosHumanosUtilizacion_0,
          rrhh_monto_0 : parseInt(recursosHumanosMontoSolicitados[0]),
          rrhh_item_1 : values.RecursosHumanosItem_1,
          rrhh_utilizacion_1 : values.RecursosHumanosUtilizacion_1,
          rrhh_monto_1 : parseInt(recursosHumanosMontoSolicitados[1]),
          plan_difusion: values.PlanDifusion,
          is_draft: false,
  
  
      };

      try{
        createSolicitud(createSolicitudForm2,false).then((response) => {
          if(response){
  
            var responsesp3 = {
              id_solicitud : response.id_solicitud,
              identificacion_problema: values.IdProblema,
              beneficiarios: values.Beneficiarios,
              descripcion_proyecto: values.DesProyecto,
              objetivo_general: values.ObjetivoGeneral,
              objetivo_especifico_1: values.ObjetivoEspec1,
              objetivo_especifico_2: values.ObjetivoEspec2,
              objetivo_especifico_3: values.ObjetivoEspec3,
              nombre_actividad_0: values.ActividadesNombre_0,
              descripcion_actividad_0: values.ActividadesDescripcion_0,
              nombre_actividad_1: values.ActividadesNombre_1,
              descripcion_actividad_1: values.ActividadesDescripcion_1,
              nombre_actividad_2: values.ActividadesNombre_2,
              descripcion_actividad_2: values.ActividadesDescripcion_2,
              nombre_actividad_3: values.ActividadesNombre_3,
              descripcion_actividad_3: values.ActividadesDescripcion_3,
              nombre_actividad_4: values.ActividadesNombre_4,
              descripcion_actividad_4: values.ActividadesDescripcion_4,
            }
      
            createRespuestasSolicitud(responsesp3).then((response) => {
              if(response){
                var ListDocuments = []
                if(certificadoVigencia[0]){
                  const base64EncodedFile =  fileToBase64(certificadoVigencia[0]);
                var documento = {
                  id_solicitud : parseInt(response.id_solicitud),
                  tipo_documento : certificadoVigencia[0].type,
                  documento : base64EncodedFile,
                  categoria : 'Certificado de vigencia',
                  nombre_documento : certificadoVigencia[0].name
                }
                ListDocuments.push(documento)
        
                }
                if(certificadoCedulaREP[0]){
                  const base64EncodedFile =  fileToBase64(certificadoCedulaREP[0]);
                  var documento = {
                    id_solicitud : parseInt(response.id_solicitud),
                    tipo_documento : certificadoCedulaREP[0].type,
                    documento : base64EncodedFile,
                    categoria : 'Certificado de cedula representante legal',
                    nombre_documento : certificadoCedulaREP[0].name
                  }
                  ListDocuments.push(documento)
                }
                if(certificadoCedulaDire[0]){
                  const base64EncodedFile =  fileToBase64(certificadoCedulaDire[0]);
                  var documento = {
                    id_solicitud : parseInt(response.id_solicitud),
                    tipo_documento : certificadoCedulaDire[0].type,
                    documento : base64EncodedFile,
                    categoria : 'Cedula directiva',
                    nombre_documento : certificadoCedulaDire[0].name
                  }
                  ListDocuments.push(documento)
                }
        
                if(certificadoCedulaOtroDire[0]){
                  const base64EncodedFile =  fileToBase64(certificadoCedulaOtroDire[0]);
                  var documento = {
                    id_solicitud : parseInt(response.id_solicitud),
                    tipo_documento : certificadoCedulaOtroDire[0].type,
                    documento : base64EncodedFile,
                    categoria : 'Cedula directiva 2',
                    nombre_documento : certificadoCedulaOtroDire[0].name
                  }
                  ListDocuments.push(documento)
                }
                if(certificadoBancario[0]){
                  const base64EncodedFile =  fileToBase64(certificadoBancario[0]);
                  var documento = {
                    id_solicitud : parseInt(response.id_solicitud),
                    tipo_documento : certificadoBancario[0].type,
                    documento : base64EncodedFile,
                    categoria : 'CertificadoBancario',
                    nombre_documento : certificadoBancario[0].name
                  }
                  ListDocuments.push(documento)
                }
        
                if(certificadoCartaCompromiso[0]){
                  const base64EncodedFile =  fileToBase64(certificadoCartaCompromiso[0]);
                  var documento = {
                    id_solicitud : parseInt(response.id_solicitud),
                    tipo_documento : certificadoCartaCompromiso[0].type,
                    documento : base64EncodedFile,
                    categoria : 'CertificadoCartaCompromiso',
                    nombre_documento : certificadoCartaCompromiso[0].name
                  }
                  ListDocuments.push(documento)
                }
                if(certificadoCotizacion[0]){
                  const base64EncodedFile =  fileToBase64(certificadoCotizacion[0]);
                  var documento = {
                    id_solicitud : parseInt(response.id_solicitud),
                    tipo_documento : certificadoCotizacion[0].type,
                    documento : base64EncodedFile,
                    categoria : 'CertificadoCotizacion',
                    nombre_documento : certificadoCotizacion[0].name
                  }
                  ListDocuments.push(documento)
                }
                
                if(ListDocuments.length > 0 ){
                  try{
        
              
                    handleUploadDocument(ListDocuments).then((response) => {
                     
                    });
               
              }catch(er){
                alert("Error al cargar los documentos, intente mas tarde")
              }
              }
              }
            }).then(()=>{
              setIsLoadingSubmit(false)
              alert("Formulario enviado correctamente");
              window.location.href = '/FeedPostulante';
            })

            
          }
        })
      }catch(error){
        alert("Error al enviar formulario, verifica la conexión a internet o intentalo de nuevo más tarde");
      }

   
      }

    };
    ///////////////////////////////////////////////////////////////
    /////////////////////////////////////Funcion DE GUARDADO BORRADOR
    ///////////////////////////////////////////////////////////////
    const handleSaveDraft = async (values) => {
      setDraftLoading(true);
      if(isDraft){
        const queryParams = new URLSearchParams(window.location.search);
        var id_solicitud_ = queryParams.get('id_postulacion');
        function formatDate(date) {
          var year = date.getFullYear();
          var month = ('0' + (date.getMonth() + 1)).slice(-2);  // Adding 1 because getMonth() returns zero-based index
          var day = ('0' + date.getDate()).slice(-2);
          return year + '-' + month + '-' + day;
        }
        var createSolicitudForm = {
          nombre_proyecto: values.NombreProyecto,
          nombre_organizacion: values.NombreOrg ? values.NombreOrg : '',
          numero_beneficiarios: values.NumBeneficiarios,
          linea_financiamiento: values.radio,
          aporte_solicitado: values.AporteSolicitado,
          rut_organizacion: values.RUTOrg,
          direccion_org: values.DirOrg,
          nombre_representante_legal: values.NombreRepLegal,
          rut_representante_legal: values.RUTRepLegal,
          direccion_rep_legal: values.DirRepLegal,
          telefono_rep_legal: values.TelRepLegal ? values.TelRepLegal.toString() : '',
          correo_rep_legal: values.CorreoRepLegal,
          nombre_responsable_proy: values.NombreRespProyecto,
          rut_responsable_proy: values.RUTRespProyecto,
          direccion_responsable_proy: values.DirRespProyecto,
          telefono_responsable_proy: values.TelRespProyecto ?  values.TelRespProyecto.toString() : '',
          correo_responsable_proy: values.CorreoRespProyecto,
          id_proceso:3,
          estado:'Borrador',
          rut_evaluador:null,
          rut_solicitante:user.rut_usuario,
          email_solicitante:user.correo_usuario,
          Fecha_Postulacion: formatDate(new Date()),
          gasto_operacional_item_0 : values.GoperacionalesItem_0,
          gasto_operacional_utilizacion_0 : values.GoperacionalesUtilizacion_0,
          gasto_operacional_monto_0 : parseInt(goperacionalesMontoSolicitados[0]),
          gasto_operacional_item_1 : values.GoperacionalesItem_1,
          gasto_operacional_utilizacion_1 : values.GoperacionalesUtilizacion_1,
          gasto_operacional_monto_1 : parseInt(goperacionalesMontoSolicitados[1]),
          gasto_operacional_item_2 : values.GoperacionalesItem_2,
          gasto_operacional_utilizacion_2 : values.GoperacionalesUtilizacion_2,
          gasto_operacional_monto_2 : parseInt(goperacionalesMontoSolicitados[2]),
          gasto_operacional_item_3 : values.GoperacionalesItem_3,
          gasto_operacional_utilizacion_3 : values.GoperacionalesUtilizacion_3,
          gasto_operacional_monto_3 : parseInt(goperacionalesMontoSolicitados[3]),
          gasto_operacional_item_4 : values.GoperacionalesItem_4,
          gasto_operacional_utilizacion_4 : values.GoperacionalesUtilizacion_4,
          gasto_operacional_monto_4 : parseInt(goperacionalesMontoSolicitados[4]),
          infra_item_0 : values.InfraestructuraItem_0,
          infra_utilizacion_0 : values.InfraestructuraUtilizacion_0,
          infra_monto_0 : parseInt(infraestructuraMontoSolicitados[0]),
          infra_item_1 : values.InfraestructuraItem_1,
          infra_utilizacion_1 : values.InfraestructuraUtilizacion_1,
          infra_monto_1 : parseInt(infraestructuraMontoSolicitados[1]),
          rrhh_item_0 : values.RecursosHumanosItem_0,
          rrhh_utilizacion_0 : values.RecursosHumanosUtilizacion_0,
          rrhh_monto_0 : parseInt(recursosHumanosMontoSolicitados[0]),
          rrhh_item_1 : values.RecursosHumanosItem_1,
          rrhh_utilizacion_1 : values.RecursosHumanosUtilizacion_1,
          rrhh_monto_1 : parseInt(recursosHumanosMontoSolicitados[1]),
          plan_difusion : values.PlanDifusion,
          
      };

      var responsesp3 = {
        id_solicitud : id_solicitud_,
        identificacion_problema: values.IdProblema,
        beneficiarios: values.Beneficiarios,
        descripcion_proyecto: values.DesProyecto,
        objetivo_general: values.ObjetivoGeneral,
        objetivo_especifico_1: values.ObjetivoEspec1,
        objetivo_especifico_2: values.ObjetivoEspec2,
        objetivo_especifico_3: values.ObjetivoEspec3,
        nombre_actividad_0: values.ActividadesNombre_0,
        descripcion_actividad_0: values.ActividadesDescripcion_0,
        nombre_actividad_1: values.ActividadesNombre_1,
        descripcion_actividad_1: values.ActividadesDescripcion_1,
        nombre_actividad_2: values.ActividadesNombre_2,
        descripcion_actividad_2: values.ActividadesDescripcion_2,
        nombre_actividad_3: values.ActividadesNombre_3,
        descripcion_actividad_3: values.ActividadesDescripcion_3,
        nombre_actividad_4: values.ActividadesNombre_4,
        descripcion_actividad_4: values.ActividadesDescripcion_4,
      }

      try{
        updateSolicitud(id_solicitud_, createSolicitudForm).then((response) => {
          if(response){
            updateRespuestas(id_solicitud_,responsesp3).then((response) => {
              if(response){
               
              }
            })
          }
        })

        var ListDocuments = []
        if(certificadoVigencia[0]){
          const base64EncodedFile = await fileToBase64(certificadoVigencia[0]);
        var documento = {
          id_solicitud : parseInt(id_solicitud_),
          tipo_documento : certificadoVigencia[0].type,
          documento : base64EncodedFile,
          categoria : 'Certificado de vigencia',
          nombre_documento : certificadoVigencia[0].name
        }
        ListDocuments.push(documento)

        }
        if(certificadoCedulaREP[0]){
          const base64EncodedFile = await fileToBase64(certificadoCedulaREP[0]);
          var documento = {
            id_solicitud : parseInt(id_solicitud_),
            tipo_documento : certificadoCedulaREP[0].type,
            documento : base64EncodedFile,
            categoria : 'Certificado de cedula representante legal',
            nombre_documento : certificadoCedulaREP[0].name
          }
          ListDocuments.push(documento)
        }
        if(certificadoCedulaDire[0]){
          const base64EncodedFile = await fileToBase64(certificadoCedulaDire[0]);
          var documento = {
            id_solicitud : parseInt(id_solicitud_),
            tipo_documento : certificadoCedulaDire[0].type,
            documento : base64EncodedFile,
            categoria : 'Cedula directiva',
            nombre_documento : certificadoCedulaDire[0].name
          }
          ListDocuments.push(documento)
        }

        if(certificadoCedulaOtroDire[0]){
          const base64EncodedFile = await fileToBase64(certificadoCedulaOtroDire[0]);
          var documento = {
            id_solicitud : parseInt(id_solicitud_),
            tipo_documento : certificadoCedulaOtroDire[0].type,
            documento : base64EncodedFile,
            categoria : 'Cedula directiva 2',
            nombre_documento : certificadoCedulaOtroDire[0].name
          }
          ListDocuments.push(documento)
        }
        if(certificadoBancario[0]){
          const base64EncodedFile = await fileToBase64(certificadoBancario[0]);
          var documento = {
            id_solicitud : parseInt(id_solicitud_),
            tipo_documento : certificadoBancario[0].type,
            documento : base64EncodedFile,
            categoria : 'CertificadoBancario',
            nombre_documento : certificadoBancario[0].name
          }
          ListDocuments.push(documento)
        }

        if(certificadoCartaCompromiso[0]){
          const base64EncodedFile = await fileToBase64(certificadoCartaCompromiso[0]);
          var documento = {
            id_solicitud : parseInt(id_solicitud_),
            tipo_documento : certificadoCartaCompromiso[0].type,
            documento : base64EncodedFile,
            categoria : 'CertificadoCartaCompromiso',
            nombre_documento : certificadoCartaCompromiso[0].name
          }
          ListDocuments.push(documento)
        }
        if(certificadoCotizacion[0]){
          const base64EncodedFile = await fileToBase64(certificadoCotizacion[0]);
          var documento = {
            id_solicitud : parseInt(id_solicitud_),
            tipo_documento : certificadoCotizacion[0].type,
            documento : base64EncodedFile,
            categoria : 'CertificadoCotizacion',
            nombre_documento : certificadoCotizacion[0].name
          }
          ListDocuments.push(documento)
        }
        

        
        if(ListDocuments.length>0){
          try{
            handleUploadDocument(ListDocuments).then((response) => {
              setDraftLoading(false);
              alert("Borrador guardado correctamente");
              window.location.href = '/FeedPostulante';
            });
          }catch(er){
            alert("Error al cargar los documentos, intente mas tarde")
          }
        }else{
          setDraftLoading(false);
          alert("Borrador guardado correctamente");
        //  window.location.href = '/FeedPostulante';
        }
     
      }catch(error){
        alert("Error al guardar el borrador, verifica tu conexión a internet o intentalo de nuevo más tarde.")
      }
     

      }else{
        function formatDate(date) {
          var year = date.getFullYear();
          var month = ('0' + (date.getMonth() + 1)).slice(-2);  // Adding 1 because getMonth() returns zero-based index
          var day = ('0' + date.getDate()).slice(-2);
          return year + '-' + month + '-' + day;
        }
        var createSolicitudForm = {
          nombre_proyecto: values.NombreProyecto!="" ? values.NombreProyecto : null,
          nombre_organizacion: values.NombreOrg!=" " ? values.NombreOrg : null,
          numero_beneficiarios: values.NumBeneficiarios,
          linea_financiamiento: values.radio,
          aporte_solicitado: values.AporteSolicitado,
          rut_organizacion: values.RUTOrg,
          direccion_org: values.DirOrg,
          nombre_representante_legal: values.NombreRepLegal,
          rut_representante_legal: values.RUTRepLegal,
          direccion_rep_legal: values.DirRepLegal,
          telefono_rep_legal: values.TelRepLegal ? values.TelRepLegal.toString() : '',
          correo_rep_legal: values.CorreoRepLegal,
          nombre_responsable_proy: values.NombreRespProyecto,
          rut_responsable_proy: values.RUTRespProyecto,
          direccion_responsable_proy: values.DirRespProyecto,
          telefono_responsable_proy: values.TelRespProyecto ?  values.TelRespProyecto.toString() : '',
          correo_responsable_proy: values.CorreoRespProyecto,
          id_proceso:3,
          estado:'Borrador',
          rut_evaluador:null,
          rut_solicitante:user.rut_usuario,
          email_solicitante:user.correo_usuario,
          Fecha_Postulacion: formatDate(new Date()),
          gasto_operacional_item_0 : values.GoperacionalesItem_0,
          gasto_operacional_utilizacion_0 : values.GoperacionalesUtilizacion_0,
          gasto_operacional_monto_0 : parseInt(goperacionalesMontoSolicitados[0]),
          gasto_operacional_item_1 : values.GoperacionalesItem_1,
          gasto_operacional_utilizacion_1 : values.GoperacionalesUtilizacion_1,
          gasto_operacional_monto_1 : parseInt(goperacionalesMontoSolicitados[1]),
          gasto_operacional_item_2 : values.GoperacionalesItem_2,
          gasto_operacional_utilizacion_2 : values.GoperacionalesUtilizacion_2,
          gasto_operacional_monto_2 : parseInt(goperacionalesMontoSolicitados[2]),
          gasto_operacional_item_3 : values.GoperacionalesItem_3,
          gasto_operacional_utilizacion_3 : values.GoperacionalesUtilizacion_3,
          gasto_operacional_monto_3 : parseInt(goperacionalesMontoSolicitados[3]),
          gasto_operacional_item_4 : values.GoperacionalesItem_4,
          gasto_operacional_utilizacion_4 : values.GoperacionalesUtilizacion_4,
          gasto_operacional_monto_4 : parseInt(goperacionalesMontoSolicitados[4]),
          infra_item_0 : values.InfraestructuraItem_0,
          infra_utilizacion_0 : values.InfraestructuraUtilizacion_0,
          infra_monto_0 : parseInt(infraestructuraMontoSolicitados[0]),
          infra_item_1 : values.InfraestructuraItem_1,
          infra_utilizacion_1 : values.InfraestructuraUtilizacion_1,
          infra_monto_1 : parseInt(infraestructuraMontoSolicitados[1]),
          rrhh_item_0 : values.RecursosHumanosItem_0,
          rrhh_utilizacion_0 : values.RecursosHumanosUtilizacion_0,
          rrhh_monto_0 : parseInt(recursosHumanosMontoSolicitados[0]),
          rrhh_item_1 : values.RecursosHumanosItem_1,
          rrhh_utilizacion_1 : values.RecursosHumanosUtilizacion_1,
          rrhh_monto_1 : parseInt(recursosHumanosMontoSolicitados[1]),
          plan_difusion : values.PlanDifusion,
          is_draft:true,
  
  
      };
  
    
      try{

        createSolicitud(createSolicitudForm,true).then((response) => {
          if(response){
    
            var responsesp3 = {
              id_solicitud : response.id_solicitud,
              identificacion_problema: values.IdProblema,
              beneficiarios: values.Beneficiarios,
              descripcion_proyecto: values.DesProyecto,
              objetivo_general: values.ObjetivoGeneral,
              objetivo_especifico_1: values.ObjetivoEspec1,
              objetivo_especifico_2: values.ObjetivoEspec2,
              objetivo_especifico_3: values.ObjetivoEspec3,
              nombre_actividad_0: values.ActividadesNombre_0,
              descripcion_actividad_0: values.ActividadesDescripcion_0,
              nombre_actividad_1: values.ActividadesNombre_1,
              descripcion_actividad_1: values.ActividadesDescripcion_1,
              nombre_actividad_2: values.ActividadesNombre_2,
              descripcion_actividad_2: values.ActividadesDescripcion_2,
              nombre_actividad_3: values.ActividadesNombre_3,
              descripcion_actividad_3: values.ActividadesDescripcion_3,
              nombre_actividad_4: values.ActividadesNombre_4,
              descripcion_actividad_4: values.ActividadesDescripcion_4,
            }
          
          
            
         
            createRespuestasSolicitud(responsesp3).then((response) => {

              
            })

            var ListDocuments = []
            if(certificadoVigencia[0]){
              const base64EncodedFile =  fileToBase64(certificadoVigencia[0]);
            var documento = {
              id_solicitud : parseInt(response.id_solicitud),
              tipo_documento : certificadoVigencia[0].type,
              documento : base64EncodedFile,
              categoria : 'Certificado de vigencia',
              nombre_documento : certificadoVigencia[0].name
            }
            ListDocuments.push(documento)
    
            }
            if(certificadoCedulaREP[0]){
              const base64EncodedFile =  fileToBase64(certificadoCedulaREP[0]);
              var documento = {
                id_solicitud : parseInt(response.id_solicitud),
                tipo_documento : certificadoCedulaREP[0].type,
                documento : base64EncodedFile,
                categoria : 'Certificado de cedula representante legal',
                nombre_documento : certificadoCedulaREP[0].name
              }
              ListDocuments.push(documento)
            }
            if(certificadoCedulaDire[0]){
              const base64EncodedFile =  fileToBase64(certificadoCedulaDire[0]);
              var documento = {
                id_solicitud : parseInt(response.id_solicitud),
                tipo_documento : certificadoCedulaDire[0].type,
                documento : base64EncodedFile,
                categoria : 'Cedula directiva',
                nombre_documento : certificadoCedulaDire[0].name
              }
              ListDocuments.push(documento)
            }
    
            if(certificadoCedulaOtroDire[0]){
              const base64EncodedFile =  fileToBase64(certificadoCedulaOtroDire[0]);
              var documento = {
                id_solicitud : parseInt(response.id_solicitud),
                tipo_documento : certificadoCedulaOtroDire[0].type,
                documento : base64EncodedFile,
                categoria : 'Cedula directiva 2',
                nombre_documento : certificadoCedulaOtroDire[0].name
              }
              ListDocuments.push(documento)
            }
            if(certificadoBancario[0]){
              const base64EncodedFile =  fileToBase64(certificadoBancario[0]);
              var documento = {
                id_solicitud : parseInt(response.id_solicitud),
                tipo_documento : certificadoBancario[0].type,
                documento : base64EncodedFile,
                categoria : 'CertificadoBancario',
                nombre_documento : certificadoBancario[0].name
              }
              ListDocuments.push(documento)
            }
    
            if(certificadoCartaCompromiso[0]){
              const base64EncodedFile =  fileToBase64(certificadoCartaCompromiso[0]);
              var documento = {
                id_solicitud : parseInt(response.id_solicitud),
                tipo_documento : certificadoCartaCompromiso[0].type,
                documento : base64EncodedFile,
                categoria : 'CertificadoCartaCompromiso',
                nombre_documento : certificadoCartaCompromiso[0].name
              }
              ListDocuments.push(documento)
            }
            if(certificadoCotizacion[0]){
              const base64EncodedFile =  fileToBase64(certificadoCotizacion[0]);
              var documento = {
                id_solicitud : parseInt(response.id_solicitud),
                tipo_documento : certificadoCotizacion[0].type,
                documento : base64EncodedFile,
                categoria : 'CertificadoCotizacion',
                nombre_documento : certificadoCotizacion[0].name
              }
              ListDocuments.push(documento)
            }
            
    
            
            if(ListDocuments.length > 0){
              try{
                handleUploadDocument(ListDocuments).then((response) => {
                  setDraftLoading(false);
                  alert("Borrador guardado correctamente");
                  window.location.href = '/FeedPostulante';
                });
              }catch(er){
                alert("Error al cargar los documentos, intente mas tarde")
              }
          }else{
            setDraftLoading(false);
            alert('Borrador guardado con éxito');
            window.location.href = '/FeedPostulante';
          }
          }
          
    
          
        })
        
      }catch(error){
        alert("Error al guardar el borrador, verifica tu conexión a internet o intentalo de nuevo más tarde.")
      }
     
        
       
      }
     
     
    };

    const areAllFieldsEmpty = (values) => {
      return (
        !values.NombreProyecto.trim() &&         // Verifica si NombreProyecto está vacío o solo contiene espacios
        !values.NumBeneficiarios &&              // Verifica si NumBeneficiarios es nulo o 0
        !values.radio &&                         // Verifica si no se seleccionó ninguna opción de radio
        !values.AporteSolicitado                 // Verifica si AporteSolicitado es nulo o 0
      );
    };


    const handleInput = (e) => {
      const regex = /^[0-9]{1,8}[kK]$/;
      if (!regex.test(e.target.value)) {
        // Mostrar error o cambiar estado para indicar que hay un problema
      }
    }

    if (isLoading) {
      return <div>Loading...</div>; // Show a loading state while data is being fetched
    }
  
  return (
    <Formik
    initialValues={{
        NombreProyecto: isDraft ? solicitudDraft.nombre_proyecto :'',
        NumBeneficiarios: isDraft ? solicitudDraft.numero_beneficiarios : '',
        radio: isDraft ? solicitudDraft.linea_financiamiento : '',
        AporteSolicitado: isDraft ? solicitudDraft.aporte_solicitado : '',

        NombreOrg:  isDraft ? solicitudDraft.nombre_organizacion : '',
        RUTOrg: isDraft ? solicitudDraft.rut_organizacion : '',
        DirOrg: isDraft ? solicitudDraft.direccion_org : '',
        NombreRepLegal: isDraft ? solicitudDraft.nombre_representante_legal : '',
        RUTRepLegal:  isDraft ? solicitudDraft.rut_representante_legal : '',
        DirRepLegal: isDraft ? solicitudDraft.direccion_rep_legal : '',
        TelRepLegal:  isDraft ? solicitudDraft.telefono_rep_legal : '',
        CorreoRepLegal: isDraft ? solicitudDraft.correo_rep_legal : '',
        NombreRespProyecto: isDraft ? solicitudDraft.nombre_responsable_proy : '',
        RUTRespProyecto:  isDraft ? solicitudDraft.rut_responsable_proy : '',
        DirRespProyecto: isDraft ? solicitudDraft.direccion_responsable_proy : '',
        TelRespProyecto: isDraft ? solicitudDraft.telefono_responsable_proy : '',
        CorreoRespProyecto: isDraft ? solicitudDraft.correo_responsable_proy : '',

        IdProblema: isDraft ? respuestas.identificacion_problema : '',
        Beneficiarios: isDraft ? respuestas.beneficiarios : '',
        DesProyecto: isDraft ? respuestas.descripcion_proyecto : '',
        ObjetivoGeneral: isDraft ? respuestas.objetivo_general : '',
        ObjetivoEspec1: isDraft ? respuestas.objetivo_especifico_1 : '',
        ObjetivoEspec2: isDraft ? respuestas.objetivo_especifico_2 : '',
        ObjetivoEspec3: isDraft ? respuestas.objetivo_especifico_3 : '',

        ActividadesNombre_0: isDraft ? respuestas.nombre_actividad_0 : '',
        ActividadesDescripcion_0: isDraft ? respuestas.descripcion_actividad_0 : '',
        ActividadesNombre_1: isDraft ? respuestas.nombre_actividad_1 : '',
        ActividadesDescripcion_1: isDraft ? respuestas.descripcion_actividad_1 : '',
        ActividadesNombre_2: isDraft ? respuestas.nombre_actividad_2 : '',
        ActividadesDescripcion_2: isDraft ? respuestas.descripcion_actividad_2 : '',
        ActividadesNombre_3: isDraft ? respuestas.nombre_actividad_3 : '',
        ActividadesDescripcion_3: isDraft ? respuestas.descripcion_actividad_3 : '',
        ActividadesNombre_4: isDraft ? respuestas.nombre_actividad_4 : '',
        ActividadesDescripcion_4: isDraft ? respuestas.descripcion_actividad_4 : '',
        PlanDifusion: isDraft ? solicitudDraft.plan_difusion : '',


        GoperacionalesItem_0: isDraft ? solicitudDraft.gasto_operacional_item_0 : '',
        GoperacionalesUtilizacion_0: isDraft ? solicitudDraft.gasto_operacional_utilizacion_0 : '',
        GoperacionalesMontoSolicitado_0: isDraft ? solicitudDraft.gasto_operacional_monto_0 : '',
        GoperacionalesItem_1: isDraft ? solicitudDraft.gasto_operacional_item_1 : '',
        GoperacionalesUtilizacion_1: isDraft ? solicitudDraft.gasto_operacional_utilizacion_1 : '',
        GoperacionalesMontoSolicitado_1: isDraft ? solicitudDraft.gasto_operacional_monto_1 : '',
        GoperacionalesItem_2: isDraft ? solicitudDraft.gasto_operacional_item_2 : '',
        GoperacionalesUtilizacion_2: isDraft ? solicitudDraft.gasto_operacional_utilizacion_2 : '',
        GoperacionalesMontoSolicitado_2: isDraft ? solicitudDraft.gasto_operacional_monto_2 : '',
        GoperacionalesItem_3: isDraft ? solicitudDraft.gasto_operacional_item_3 : '',
        GoperacionalesUtilizacion_3: isDraft ? solicitudDraft.gasto_operacional_utilizacion_3 : '',
        GoperacionalesMontoSolicitado_3: isDraft ? solicitudDraft.gasto_operacional_monto_3 : '',
        GoperacionalesItem_4: isDraft ? solicitudDraft.gasto_operacional_item_4 : '',
        GoperacionalesUtilizacion_4: isDraft ? solicitudDraft.gasto_operacional_utilizacion_4 : '',
        GoperacionalesMontoSolicitado_4: isDraft ? solicitudDraft.gasto_operacional_monto_4 : '',

        InfraestructuraItem_0: isDraft ? solicitudDraft.infra_item_0 : '',
        InfraestructuraUtilizacion_0: isDraft ? solicitudDraft.infra_utilizacion_0 : '',
        InfraestructuraMontoSolicitado_0: isDraft ? solicitudDraft.infra_monto_0 : '',

        InfraestructuraItem_1: isDraft ? solicitudDraft.infra_item_1 : '',
        InfraestructuraUtilizacion_1: isDraft ? solicitudDraft.infra_utilizacion_1 : '',
        InfraestructuraMontoSolicitado_1: isDraft ? solicitudDraft.infra_monto_1 : '',
        

        RecursosHumanosItem_0: isDraft ? solicitudDraft.rrhh_item_0 : '',
        RecursosHumanosUtilizacion_0: isDraft ? solicitudDraft.rrhh_utilizacion_0 : '',
        RecursosHumanosMontoSolicitado_0: isDraft ? solicitudDraft.rrhh_monto_0 : '',

        RecursosHumanosItem_1: isDraft ? solicitudDraft.rrhh_item_1 : '',
        RecursosHumanosUtilizacion_1: isDraft ? solicitudDraft.rrhh_utilizacion_1 : '',
        RecursosHumanosMontoSolicitado_1: isDraft ? solicitudDraft.rrhh_monto_1 : '',
        
        //GoperacionalesExtraTotal : isDraft ? (solicitudDraft.gasto_operacional_monto_0+ solicitudDraft.gasto_operacional_monto_1 + solicitudDraft.gasto_operacional_monto_2+ solicitudDraft.gasto_operacional_monto_3+ solicitudDraft.gasto_operacional_monto_4) : '',
        
        


      }}
      
      validate={(values) => validate(values, currentStep)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
         
         <DivContenedorMax>
      
          <Navbar name={user.nombre_usuario} />
       
      
        <Div>
       
        <ProgressBar>
       
  <StepProgress>
    <Step className={`${currentStep >= -1 ? 'active' : ''}`}>
      <Bullet active={(currentStep >= -1).toString()} onClick={() => handleClick(-1)}><span>0</span></Bullet>
      <StepTitle>Instrucciones</StepTitle>
      
    </Step>
    <Step className={`${currentStep >= 0 ? 'active' : ''}`}>
      <Bullet active={(currentStep >= 0).toString()} onClick={() => handleClick(0)}><span>1</span></Bullet>
      <StepTitle>Resumen</StepTitle>
      {/* {stepValid[0] ? <CheckIcon /> : <CloseIcon />} */}
    </Step>

    <Step className={`${currentStep >= 1 ? 'active' : ''}`}>
      <Bullet active={(currentStep >= 1).toString()} onClick={() => handleClick(1)}><span>2</span></Bullet>
      <StepTitle>Organización Postulante</StepTitle>
      {/* {stepValid[1] ? <CheckIcon /> : <CloseIcon />} */}
    </Step>

    <Step className={`${currentStep >= 2 ? 'active' : ''}`}>
      <Bullet active={(currentStep >= 2).toString()} onClick={() => handleClick(2)}><span>3</span></Bullet>
      <StepTitle>Problema/Solucion</StepTitle>
      {/* {stepValid[2] ? <CheckIcon /> : <CloseIcon />} */}
    </Step>

    <Step className={`${currentStep >= 3 ? 'active' : ''}`}>
      <Bullet active={(currentStep >= 3).toString()} onClick={() => handleClick(3)}><span>4</span></Bullet>
      <StepTitle>Planificacion</StepTitle>
      {/* {stepValid[3] ? <CheckIcon /> : <CloseIcon />} */}
    </Step>

    <Step className={`${currentStep >= 4 ? 'active' : ''}`}>
      <Bullet active={(currentStep >= 4).toString()} onClick={() => handleClick(4)}><span>5</span></Bullet>
      <StepTitle>Presupuesto</StepTitle>
      {/* {stepValid[4] ? <CheckIcon /> : <CloseIcon />} */}
    </Step>

    <Step className={`${currentStep >= 5 ? 'active' : ''}`}>
      <Bullet active={(currentStep >= 5).toString()} onClick={() => handleClick(5)}><span>6</span></Bullet>
      <StepTitle>Adjuntos</StepTitle>
      {/* {stepValid[5] ? <CheckIcon /> : <CloseIcon />} */}
    </Step>
  </StepProgress>
  {
    !isDraft || (isDraft && solicitudDraft.estado==='Borrador')  ? <div>
   <SaveIcon  
  disabled={draftLoading || areAllFieldsEmpty(values)} 
  onClick={() => {handleSaveDraft(values)}}
>   
    <Icon />  
   {draftLoading ? 'Cargando...' : 'Guardar'} 
  </SaveIcon>  
    </div>: <></>
  }
  
  <FechaTerminoIcon >  
{
  fecha < 0 ? <p>Fecha de postulación finalizada</p> : <p>Quedan :  {fecha} días</p>
}
 
  </FechaTerminoIcon>  
</ProgressBar>
   
       

       
 
        <DivForm>
          <p><strong>Los campos con * son obligatorios</strong></p>
        {currentStep === -1 && (
             <DivFlex className={animating ? 'fade' : ''}>
             <div>
               <br></br>
               <h2>Instrucciones</h2>
                
                   <PI>Bienvenido al portal del programa Asignación de recursos para el Desarrollo Social ColbúnCoronel  2024, iniciativa que Colbún pone a disposición de las organizaciones sociales de la comuna.

                   En esta plataforma podrá ir completando los campos necesarios para ingresar su proyecto. La información puede completarse gradualmente siempre que la respalde haciendo click en el botón Guardar. 
                   </PI> <PI>
                   En cualquier instante podrá verificar las preguntas que faltan responder haciendo click en "Verificación y envío". Sólo cuando toda la información esté completamente ingresada, la postulación podrá ser enviada de forma definitiva.

                   Ante cualquier duda puede escribirnos al correo: contacto@fdscoronel.org</PI>
                   <br></br>
                   {/* <ButtonDownload onClick={() => downloadPDF()}>Descargar pdf</ButtonDownload> */}
                   </div>
             </DivFlex>
            )}
          <Form>
            {currentStep === 0 && (
              <DivFlex className={animating ? 'fade' : ''}>
              <div>
                <h2>Resumen</h2>
                    
                   

                    <TextInput
                        name="NombreProyecto"  
                        label="Nombre Proyecto" 
                        maxLength={25} 
                        required={true} 
                        
                        
                    />
                    
                    <br />
                    
                    <NumberInput name="NumBeneficiarios" maxLength={2} max={2} label="Número de beneficiarios directos del proyecto"  required={true} showPrefix={false}/>
                    <br />
                    <Label>¿Cuál es la línea de financiamiento a la que postula con este proyecto?:<Asterisk>*</Asterisk></Label>
                    <RadioInput name="radio" value="Bienestar Físico, Deporte y Recreación" label="Bienestar Físico, Deporte y Recreación" />
                    <RadioInput name="radio" value="Bienestar Psicosocial" label="Bienestar Psicosocial" />
                    <RadioInput name="radio" value="Formación y Capacitación" label="Formación y Capacitación" />
                    <RadioInput name="radio" value="Protección y cuidado del medio ambiente" label="Protección y cuidado del medio ambiente" />
                    <RadioInput name="radio" value="Educación y Artes" label="Educación y Artes" />
                    <RadioInput name="radio" value="Cultura e Identidad Local" label="Cultura e Identidad Local" />
                    <RadioInput name="radio" value="Infraestructura Comunitaria e Insumos" label="Infraestructura Comunitaria e Insumos" />
                    <ErrorMessage name="radio" component="div" style={errorMessageStyle} />
                    <br />
                    <NumberInput name="AporteSolicitado" label="Aporte Solicitado"
                    tooltipContent="Ingrese el monto en pesos chilenos"  required={true} maximo={1000000}   showPrefix={true}  format={true} />
                    <br />
                    </div>
              </DivFlex>

            )}
            {currentStep === 1 && (

              <DivFlex className={animating ? 'fade' : ''}>
              <div>
              <h2>Organización Postulante</h2>
              <DivFlex > 

                <div>
                 
                  <TextInput name="NombreOrg" label="Nombre de la Organización" 
                  tooltipContent="Organización postulante y ejecutora del proyecto"  required={true} />
                  <br />
                  <TextInput name="RUTOrg" label="R.U.T de la organización" 
                  tooltipContent="Ingrese el R.U.T de la organización postulante"
                  required={true} />
                  <br />
                  <TextInput name="DirOrg" label="Dirección de la organización" 
                  tooltipContent="Ingrese la dirección de la organización postulante indicando Calle, Número de casa, Villa o Población y Comuna"  required={true}  />
                  <br />
                  <TextInput name="NombreRepLegal" label="Nombre del/la representante legal" 
                  tooltipContent="Ingrese el nombre del/la representante legal de la organización social"  required={true} />
                  <br />
                  <TextInput name="RUTRepLegal" label="R.U.T del/la representante legal" 
                  tooltipContent="Ingrese el R.U.T del/la representante legal de la organización social"  required={true} />
                  <br />
                  <TextInput name="DirRepLegal" label="Dirección del/la representante legal" 
                  tooltipContent="Ingrese la dirección del/la representante legal de la organización social, indicando Calle, Número, Villa o Población y Comuna"  required={true} />
                  <br />
                </div>
                <div>
                  <NumberInput name="TelRepLegal" label="Teléfono del/la representante legal" 
                  tooltipContent="Ingrese el teléfono de contacto del/la representante legal de la organización social"  required={true} format={false} maxLength={9}/>
                  <br />
                  <TextInput name="CorreoRepLegal" label="Correo electrónico del/la representante legal" 
                  tooltipContent="Ingrese el correo electrónico del/la representante legal de la organización social." required={true}/>
                  <br />
                  <TextInput name="NombreRespProyecto" label="Nombre del/la Responsable del Proyecto" 
                  tooltipContent="Ingrese el nombre del/la responsable del proyecto'" required={true}/>
                  <br />
                  <TextInput name="RUTRespProyecto" label="R.U.T del/la responsable del proyecto" 
                  tooltipContent="Ingrese el R.U.T del/la responsable del proyecto." required={true}/>
                  <br />
                  <TextInput name="DirRespProyecto" label="Dirección del/la responsable del proyecto" 
                  tooltipContent="Ingrese la dirección del/la responsable del proyecto, indicando Calle, Número, Villa o Población y Comuna. " required={true}/>
                  <br />
                  <NumberInput name="TelRespProyecto" label="Teléfono del/la responsable del proyecto" 
                  tooltipContent="Ingrese el teléfono del/la responsable del proyecto. " required={true}  format={false} maxLength={9}/>
                  <br />
                  <TextInput name="CorreoRespProyecto" label="Correo electrónico del/la representante del proyecto" 
                  tooltipContent="Ingrese el correo electrónico del/la responsable del proyecto. "required={true} />
                  <br />
                </div>
              </DivFlex>
              </div>
              </DivFlex>

            )}
            {currentStep === 2 && (

                <div>

                                
                  <DivFlex2 className={animating ? 'fade' : ''}>
                          <FormContainer>
                            <h2>Problema</h2>
                            <strong><p>Limite de 500 carácteres por campo*</p></strong>
                            <FormGroup style={{ display: 'flex', gap: '20px' }}>
                              <div style={{ flex: 1 }}>
                                
                                
                                <TextArea name="IdProblema" 
                                label="Identificación del problema"
                                maxLength={255}
                                tooltipContent="Describa la situación que motiva la realización del proyecto y que justifica  <br/>  su búsqueda   de financiamiento. ¿Cuál es el  problema identificado? ¿Qué <br/> datos ayudan a entender las causas del  problema?  ¿Qué efectos tendrá <br/> el problema si no se realiza el proyecto?" required={true}/>
                              </div>
                              <div style={{ flex: 1 }}>
                               
                                <TextArea name="Beneficiarios" 
                                 label="Beneficiarios y beneficiarias"
                                required={true}
                                tooltipContent="Describa a quienes afecta el problema y quienes recibirán de forma <br/> directa e indirecta el bien o servicio propuesto en este proyecto.<br/>  (Relate cuántos/as son, sus edades, género, condiciones de vida, etc.)" />
                              </div>
                            </FormGroup>
                          </FormContainer>
                        </DivFlex2>
                        
                        <DivFlex2 className={animating ? 'fade' : ''}>
                          <FormContainer>
                            <h2>Solución</h2>
                            <strong><p>Limite de 500 carácteres por campo*</p></strong>
                            <FormGroup>
                              <TextArea name="DesProyecto" 
                              label="Descripción general del proyecto"
                              tooltipContent="Describa de qué se trata su proyecto. ¿Qué se pretende realizar? <br/>¿Qué aspectos del problema abordarán y cómo lo abordarán?" required={true}/>
                            </FormGroup>
                            <FormGroup style={{ display: 'flex', gap: '20px' }}>
                              <div style={{ flex: 1 }}>
                                
                                <TextArea name="ObjetivoGeneral" 
                                label="Objetivo General"
                                tooltipContent="¿Para qué se realizará este proyecto? ¿Qué logros se espera alcanzar?<br/> (Es requisito ingresar un obj. general y tres obj. específicos.)"  required={true}/>
                              </div>
                              <div style={{ flex: 1 }}>
                               
                                <TextArea name="ObjetivoEspec1" label="Objetivo Específico 1"  required={true}/>
                              </div>
                            </FormGroup>
                            <FormGroup style={{ display: 'flex', gap: '20px' }}>
                              <div style={{ flex: 1 }}>
                             
                                <TextArea name="ObjetivoEspec2"  label="Objetivo Específico 2" required={true}/>
                              </div>
                              <div style={{ flex: 1 }}>
                               
                                <TextArea name="ObjetivoEspec3"  label="Objetivo Específico 3" required={true}/>
                              </div>
                            </FormGroup>
                          </FormContainer>
                        </DivFlex2>
                  </div>

            )}
            {currentStep === 3 && (

                <DivFlexP4 className={animating ? 'fade' : ''}>
                <FormContainerP4>
                  <h2>Planificación</h2>
                  <strong><p>Limite de 500 carácteres por campo*</p></strong>
                  <FormLabelP4>Actividades del Proyecto:<Asterisk>*</Asterisk></FormLabelP4>
                  <p>¿Qué actividades específicas se realizarán durante la ejecución del proyecto? (Puede agregar las filas que necesite para ingresar sus actividades). Debe mencionar por lo menos 3 actividades con sus respectivas descripciones</p>
            
                  <TableP4>
                    <thead>
                      <tr>
                        <th>Nombre de la Actividad</th>
                        <th>Descripción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <tr key={index}>
                          <td>
                            
                            <FieldP4 name={`ActividadesNombre_${index}`}  placeholder="Nombre de la actividad"
                           />
                          </td>
                          <td>
                            <TextArea max={500} name={`ActividadesDescripcion_${index}`} placeholder="Descripción" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </TableP4>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <>
                      <ErrorMessageP4 name={`ActividadesNombre_${index}`} component="div" />
                      <ErrorMessageP4 name={`ActividadesDescripcion_${index}`} component="div" />
                    </>
                  ))}
                  <FormGroupP4>
                  
                    <FormLabel>Plan de difusión<Asterisk>*</Asterisk></FormLabel>
                    <strong><p>Limite de 500 carácteres*</p></strong>
                    <p>Responda: ¿Cómo convocará a los beneficiarios y beneficiarias del proyecto? ¿Cómo dará a conocer su proyecto a la comunidad de Coronel? Señale si realizará hitos de lanzamiento o cierre, difusión en medios de comunicación, prensa o redes sociales, etc.</p>
                    <TextArea name="PlanDifusion" />
                  </FormGroupP4>
                </FormContainerP4>
                </DivFlexP4>

            )}
            {currentStep === 4 && (

                  <DivFlexP5 className={animating ? 'fade' : ''}>
                  <FormContainerP5>
                    <h2>Presupuesto</h2>
                    
                    <FormLabelP5>Gastos Operacionales:<Asterisk>*</Asterisk><p>(Mínimo de gastos operacionales : 3)</p></FormLabelP5>
                    <p>Corresponde a los gastos asociados a la ejecución del proyecto tales como: materiales de apoyo  y/o de oficina para el desarrollo de capacitación y/o entrenamiento; arriendo de espacio físico y de vehículos; gastos de transporte (bencina, peajes y pasajes) del equipo ejecutor y de los participantes. Materiales o servicios para actividades de difusión del proyecto (por ejemplo: pendón, coctel, amplificación, impresiones, afiches, videos, etc.); además de subsidios para el cuidado, transporte y/o alimentación de los participantes que deben ser fundamentados en el proyecto.</p>
                    <p><strong>Recuerde que la suma de los items no puede superar el monto total solicitado en el proyecto</strong></p>
                    <TableP5>
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Utilización</th>
                          <th>Monto solicitado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {goperacionalesMontoSolicitados.map((value, index) => (
                          <tr key={index}>
                            <td>
                              <FieldP5 name={`GoperacionalesItem_${index}`} placeholder="Item" />
                            </td>
                            <td>
                              <FieldP5 name={`GoperacionalesUtilizacion_${index}`} placeholder="Utilización" />
                            </td>
                            <td>
                              <FieldP5
                                name={`GoperacionalesMontoSolicitado_${index}`} 
                                placeholder="Monto solicitado" 
                                type="text" 
                                
                                value={value }
                                showPrefix={true}
                                onChange={(e) => handleGoperacionalesMontoSolicitadoChange(index, e)} 
                              />
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <FieldP5 name="GoperacionalesExtraTotal" showPrefix={true} placeholder="TOTAL" value={goperacionalesTotal} readOnly />
                           
                          </td>
                        </tr>
                      </tbody>
                    </TableP5>
                    {goperacionalesMontoSolicitados.map((_, index) => (
                      <>
                        <ErrorMessageP5 name={`GoperacionalesItem_${index}`} component="div" />
                        <ErrorMessageP5 name={`GoperacionalesUtilizacion_${index}`} component="div" />
                        <ErrorMessageP5 name={`GoperacionalesMontoSolicitado_${index}`} component="div" />
                      </>
                    ))}
                    
                    <FormLabelP5>Infraestructura:<Asterisk>*</Asterisk></FormLabelP5>
                    <p>Considera todos los gastos involucrados en la reparación o construcción de infraestructura. Involucra materiales y mano de obra.</p>
                    <TableP5>
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Utilización</th>
                          <th>Monto solicitado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {infraestructuraMontoSolicitados.map((value, index) => (
                          <tr key={index}>
                            <td>
                              <FieldP5 name={`InfraestructuraItem_${index}`} placeholder="Item" />
                            </td>
                            <td>
                              <FieldP5 name={`InfraestructuraUtilizacion_${index}`} placeholder="Utilización" />
                            </td>
                            <td>
                              <FieldP5 
                                name={`InfraestructuraMontoSolicitado_${index}`} 
                                placeholder="Monto solicitado" 
                                type="number" 
                                value={value}
                                showPrefix={true}
                                onChange={(e) => handleInfraestructuraMontoSolicitadoChange(index, e)} 
                              />
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <FieldP5 name="InfraestructuraExtraTotal" showPrefix={true} placeholder="TOTAL" value={infraestructuraTotal} readOnly />
                          </td>
                        </tr>
                      </tbody>
                    </TableP5>
                    {infraestructuraMontoSolicitados.map((_, index) => (
                      <>
                        <ErrorMessageP5 name={`InfraestructuraItem_${index}`} component="div" />
                        <ErrorMessageP5 name={`InfraestructuraUtilizacion_${index}`} component="div" />
                        <ErrorMessageP5 name={`InfraestructuraMontoSolicitado_${index}`} component="div" />
                      </>
                    ))}

                    <FormLabelP5>Recursos Humanos:<Asterisk>*</Asterisk></FormLabelP5>
                    <p>Corresponde a los pagos de honorarios a profesionales, técnicos, monitores o talleristas como complemento al equipo existente en la propia institución postulante para el desarrollo de las actividades, y cuyos servicios se encuentren debidamente justificados en el proyecto.</p>
                    <TableP5>
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Utilización</th>
                          <th>Monto solicitado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {recursosHumanosMontoSolicitados.map((value, index) => (
                          <tr key={index}>
                            <td>
                              <FieldP5 name={`RecursosHumanosItem_${index}`} placeholder="Item" />
                            </td>
                            <td>
                              <FieldP5 name={`RecursosHumanosUtilizacion_${index}`} placeholder="Utilización" />
                            </td>
                            <td>
                            <FieldP5
                              name={`RecursosHumanosMontoSolicitado_${index}`}
                              placeholder="Monto solicitado"
                              type='number'
                              showPrefix={true}
                              value={value}
                              onChange={(e) => handleRecursosHumanosMontoSolicitadoChange(index, e)} 
                            />

                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <FieldP5 name="RecursosHumanosExtraTotal" showPrefix={true} placeholder="TOTAL" value={recursosHumanosTotal} readOnly />
                          </td>
                        </tr>
                      </tbody>
                    </TableP5>
                    {recursosHumanosMontoSolicitados.map((_, index) => (
                      <>
                        <ErrorMessageP5 name={`RecursosHumanosItem_${index}`} component="div" />
                        <ErrorMessageP5 name={`RecursosHumanosUtilizacion_${index}`} component="div" />
                        <ErrorMessageP5 name={`RecursosHumanosMontoSolicitado_${index}`} component="div" />
                      </>
                    ))}
                  </FormContainerP5>
                  </DivFlexP5>

            )}
            {currentStep === 5 && (
        <DivFlex className={animating ? 'fade' : ''}>
        <div>
          <h2>Adjuntos </h2><p><strong>Tamaño maximo por archivo (10MB)</strong></p>
         
          <FileUpload onChange={(e) => { setCertificadoVigencia(e.target.files)}} name="certificado1" label="Certificado de vigencia de la organización (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay  certificado={certificadoVigencia} clearCertificado={setCertificadoVigencia}/>
     
          <FileUpload onChange={(e) => {setCertificadoCedulaREP(e.target.files)}} name="certificado2" label="Cédula de Identidad del representante legal de la organización (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay
        certificado={certificadoCedulaREP}
        clearCertificado={setCertificadoCedulaREP}
      />
          <FileUpload onChange={(e) => {setCertificadoCedulaDire(e.target.files)}}name="certificado3" label="Cédula de Identidad de un integrante de la directiva. (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay
        certificado={certificadoCedulaDire}
        clearCertificado={setCertificadoCedulaDire}
      />
          <FileUpload onChange={(e) => {setCertificadoCedulaOtroDire(e.target.files)}} name="certificado4" label="Cédula de Identidad de otro integrante de la directiva. (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay
        certificado={certificadoCedulaOtroDire}
        clearCertificado={setCertificadoCedulaOtroDire}
      />
          <FileUpload onChange={(e) => {setCertificadoBancario(e.target.files)}}name="certificado5" label="Cuenta bancaria: (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay
        certificado={certificadoBancario}
        clearCertificado={setCertificadoBancario}
      />
          <FileUpload onChange={(e)=> {setCertificadoCartaCompromiso(e.target.files)}} name="certificado6" label="Carta de compromiso del trabajador/a. (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay
        certificado={certificadoCartaCompromiso}
        clearCertificado={setCertificadoCartaCompromiso}
      />
          <FileUpload onChange={(e) => {setCertificadoCotizacion(e.target.files)}} name="certificado7" label="Cotizaciones (PDF o JPG):" errorMessageStyle={errorMessageStyle} />
          <DocumentDisplay
        certificado={certificadoCotizacion}
        clearCertificado={setCertificadoCotizacion}
      />
        </div>
        </DivFlex>
          
            )}
            <br />
            <br />
          <div>
            <strong>Siempre guardar el estado, por seguridad.</strong>
          </div>
            
             <DivButtons>
            

              <div>
              {currentStep !== -1 &&  <ButtonAtras type="button" onClick={prevStep} disabled={currentStep === -1}>Anterior</ButtonAtras>}
              </div>
              
              <div>
              
              {currentStep === 5  ?  <div>
                
                <ButtonEnviar
                  type="submit"
               
                >
                  {!isLoadingSubmit ? "Enviar" : "Enviando..."}
                </ButtonEnviar>

                  </div>
                  
                : <></>
                }
              </div>
              <div>
              {currentStep !== 5 && <ButtonNext type="button" onClick={nextStep} disabled={currentStep === 5}>Siguiente</ButtonNext>}
              </div>
          
              </DivButtons>
              
          </Form>
        </DivForm>
        <br/>
        </Div>

        </DivContenedorMax>
      )}

    </Formik>
  );
};

export default MultiStepForm;
