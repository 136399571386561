import styled from 'styled-components';

export const Img = styled.img`
    margin-top: -10px;
    height: auto;
    width: 100%;
    position: relative;
    z-index: 1;
    border-radius: 10px;
`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);

`;

export const Div = styled.div`
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-flow: column;
    @media screen and (max-width: 800px) {
        flex-flow: column; /* Cambia a columna en pantallas más pequeñas */
    }
`;

export const DivColorInputs = styled.div`
    max-height: 700px;
    margin-top: 1%;
    margin-bottom: 2%;
    margin-right: 0px;
    width: 33%;
    padding: 25px;
    border-radius: 15px 15px 15px 15px;
    background-color: rgba(255, 255, 255, 0.9);
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 800px) {
        max-height: auto;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 2%;
        margin-left: 2%;
        background-color:white;
        color: black;
        width: 100%;
        border-radius: 15px;
    }
`;

export const DivColorText = styled.div`
    padding: 4%;
    width: 50%;
    height: auto;
    flex-direction: column;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 800px) {
        display:none;
    }
`;
export const Img2 = styled.img`
    max-width: 100%;
    height: auto;
`;
export const Input = styled.input`
@media screen and (max-width: 800px) {
    width: 90%;
    padding: 1%;
    margin-bottom:5%;
}
    
`;


export const StyledDivInfo = styled.div`
    display: flex;
    padding: 20px;
    @media screen and (max-width: 800px) {
        margin-top:70%;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
`;

export const StyledImg = styled.img`
    width:80%;
    max-width:600px;
    height: auto;
    border-radius:10px;
     @media screen and (max-width: 800px) {

        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

`;


export const StyledButton = styled.button`
    transition: 0.5s ease;
    background-color: blue;
    border-radius: 5px;
    padding: 10px;
    margin: 0 5px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;

    &:hover {
        background-color: blue;
        color: white;
        font-size: 16px;
        transition: 0.5s ease;
    }
`;



export const CenterButton = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

export const StyledImageContainer = styled.div`
    display: column;
    justify-content: center;
    align-items: center;
    margin-right:5%;
    
    width: 50%;
    height: 100%;
    @media screen and (max-width: 800px) {
        margin-top:5%;
        flex-direction: column;
margin-right:0%;
        width: 100%;
         justify-content: center;
        align-items: center;
    }
`;

export const StyledTextContainer = styled.div`
    width: 75%;
    padding-bottom: 5%;
    margin-left:5%;
    @media screen and (max-width: 800px) {
        margin-top:0%;
         margin-left:0%;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
`;

export const StyledH2 = styled.h2`
    font-size: 1.5em;
    text-align: left;
    @media screen and (max-width: 800px) {
         text-align: center;
        width: 100%;
    }
`;

export const StyledP = styled.p`
    text-align: justify;
   
    @media screen and (max-width: 800px) {
         text-align: center;
        width: 100%;
    }
`;