import React from 'react';
import { useState,useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import {fetchRegiones,fetchComunas,registerUser} from '../services/register';
import validationSchema from "./../common/validationSchema";
import format from "./../common/format";


const RegisterPage = () => {

  const [regions, setRegions] = useState([]);

  useEffect(() => {
    fetchRegiones().then((response) => {
     
      setRegions(response);
    });
  },[])
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [comunas, setComunas] = useState([]);

  const initialValues = {
    userImage: '',
    firstName: '',
    lastName: '',
    secondLastName: '',
    email: '',
    region: '',
    comuna: '',
    phone: '',
    password: '',
    confirmPassword: '' // nuevo campo para confirmar contraseña
  };



  const handleSubmit = (values) => {
    /*
    
    
    */
   var data = {
  rut_usuario:values.rut,
  nombre_usuario:values.firstName,
  apellido_usuario: values.lastName + " " + values.secondLastName,
  correo_usuario:values.email,
  id_comuna:values.comuna,
  numero_telefono:values.phone,
  contrasenna:values.password,
  rol:'Postulante',
  reset:true

  }
  registerUser(data).then((response) => {
    if(response){

      alert("Usuario registrado correctamente");
      window.location.href = "/login";
    }
  });
  };

  const handleRegionChange = (event, setFieldValue) => { const selectedRegionId = event.target.value; // Update Formik value with the name of the region 
  setFieldValue('comuna', '');
  fetchComunas(event.target.value).then((response)=> {
  setComunas(response.comunas);
  }) // Reset comuna value 
  

};

  const handleComunaChange = (event, setFieldValue) => {
    const selectedComuna = event.target.value;
    setFieldValue('comuna', selectedComuna); // Update Formik value with comuna name
  };
  



  return (
    <Container>
      <Title>Registro</Title>
      <Formik initialValues={initialValues}  validationSchema={validationSchema.customFormSchema} onSubmit={handleSubmit}>
        {({values, errors, touched, handleChange, handleSubmit, handleReset, setFieldTouched, isValid, setFieldValue}) => (
          <StyledForm>
            
            <br/>
            <Label htmlFor="rut">RUT:</Label>
            <StyledField id="rut" name="rut" type="text" value={values.rut} maxLength="12" onChange={ (event) => setFieldValue('rut', format.rut(event.target.value)) } />
            <ErrorMessage name="rut" component={ErrorText} />

            <Label htmlFor="firstName">Nombre:</Label>
            <StyledField id="firstName" name="firstName" type="text" />
            <ErrorMessage name="firstName" component={ErrorText} />

            <Label htmlFor="lastName">Primer Apellido:</Label>
            <StyledField id="lastName" name="lastName" type="text" />
            <ErrorMessage name="lastName" component={ErrorText} />

            <Label htmlFor="secondLastName">Segundo Apellido:</Label>
            <StyledField id="secondLastName" name="secondLastName" type="text" />
            <ErrorMessage name="secondLastName" component={ErrorText} />

            <Label htmlFor="email">Correo Electrónico:</Label>
            <StyledField id="email" name="email" type="email" />
            <ErrorMessage name="email" component={ErrorText} />

            <Label htmlFor="region">Región:</Label>
            <StyledField id="region" name="region" as="select" onChange={(event) => handleRegionChange(event, setFieldValue)}>
              <option value="">Seleccione una opción</option>
              {regions.map((region) => (
                <option key={region.id_region} value={region.id_region}>
                  {region.descripcion_region}
                </option>
              ))}
            </StyledField>
            <ErrorMessage name="region" component={ErrorText} />

            <Label htmlFor="comuna">Comuna:</Label>
            <StyledField id="comuna" name="comuna" as="select" onChange={(event) => handleComunaChange(event, setFieldValue)}>
              <option value="">Seleccione una opción</option>
              {comunas.map((comuna) => (
                <option key={comuna.id_comuna} value={comuna.id_comuna}>
                  {comuna.descripcion_comuna}
                </option>
              ))}
            </StyledField>
            <ErrorMessage name="comuna" component={ErrorText} />

            <Label htmlFor="phone">Teléfono:</Label>
            <StyledField id="phone" name="phone" type="text" />
            <ErrorMessage name="phone" component={ErrorText} />

            <Label htmlFor="password">Contraseña:</Label>
            <StyledField id="password" name="password" type="password" />
            <ErrorMessage name="password" component={ErrorText} />

            <Label htmlFor="confirmPassword">Confirmar Contraseña:</Label>
            <StyledField id="confirmPassword" name="confirmPassword" type="password" />
            <ErrorMessage name="confirmPassword" component={ErrorText} />

            <SubmitButton type="submit">Registrar</SubmitButton>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
};

// Styled-components
const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-top: 10px;
  display: block;
  font-weight: bold;
`;

const StyledField = styled(Field)`
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: -15px;
  margin-bottom: 10px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #FFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #0056b3;
  }
`;



export default RegisterPage;