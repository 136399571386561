import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';
import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Tooltip } from 'react-tooltip';

const Label = styled.label`
    font-weight: bold;
`;

const InputStyled = styled(InputNumber)`
    width: 300px;
    &:focus {
        background-color: lightblue; /* Cambia el color de fondo a tu preferencia */
        outline: none; /* Opcional: elimina el borde azul por defecto en algunos navegadores */
    }
`;

const Div = styled.div`
    color: red;
`;

const Asterisk = styled.span`
    color: red;
    margin-left: 4px; /* Ajusta el espacio entre el texto del label y el asterisco */
`;

const NumberInput = ({ label, tooltipContent, required, showPrefix = false, maximo,minimo, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const { setFieldValue } = useFormikContext();

    const handleChange = (e) => {
        setFieldValue(field.name, e.value);
    };

    return (
        <div>
            <Label>
                {label}
                {required && <Asterisk>*</Asterisk>}
            </Label>
            <br />
            <InputStyled 
                {...props} // Acepta las propiedades como name, placeholder, value, readOnly, etc.
                value={field.value} 
                onValueChange={handleChange} 
                data-tooltip-id="my-tooltipNumber"
                data-tooltip-content={tooltipContent}
                max={maximo}
                min={minimo}
                prefix={showPrefix ? '$' : ''}
            />
            <Tooltip id="my-tooltipNumber" place='bottom'/>
            {meta.touched && meta.error ? <Div>{meta.error}</Div> : null}
        </div>
    );
};

export default NumberInput;
