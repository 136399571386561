import React, { useEffect, useState } from 'react';
import { Formik, Form, Field,useFormikContext  } from 'formik';
import styled from 'styled-components';
import TextInput from '../components/TextInput';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrap = styled.div`
  flex: 1;
  margin-top: 70px; 
`;

const DivContenedor = styled.div`
  background-color: #fff;
  margin-left: 10%;
  margin-right: 10%;
  padding: 20px;
  border-radius: 10px;
`;

const ButtonActualizar = styled.button`
  padding: 10px 20px;
  background-color: #2FAA4B;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    background-color: #2B8D41;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 100px;
  margin-bottom: 10px;
`;

const DivForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const validate = values => {
  const errors = {};
  if (!values.nombre) {
    errors.nombre = 'Nombre es requerido';
  }
  if (!values.apellidoPaterno) {
    errors.apellidoPaterno = 'Apellido Paterno es requerido';
  }
  if (!values.apellidoMaterno) {
    errors.apellidoMaterno = 'Apellido Materno es requerido';
  }
  if (!values.comuna) {
    errors.comuna = 'Comuna es requerida';
  }
  if (!values.region) {
    errors.region = 'Región es requerida';
  }
  if (!values.contrasena) {
    errors.contrasena = 'Contraseña es requerida';
  } else if (values.contrasena.length < 6) {
    errors.contrasena = 'La contraseña debe tener al menos 6 caracteres';
  }
  if (!values.confirmarContrasena) {
    errors.confirmarContrasena = 'Confirmar contraseña es requerido';
  } else if (values.contrasena !== values.confirmarContrasena) {
    errors.confirmarContrasena = 'Las contraseñas deben coincidir';
  }
  return errors;
};

const SetFieldValuesFromLocalStorage = () => {


  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    const userData = sessionStorage.getItem('userData');
    if (userData) {
      const user = JSON.parse(userData);
      // Setea los valores del formulario
      setFieldValue('nombre', user.nombre_usuario || '');
      setFieldValue('apellido', user.apellido_usuario || '');
      setFieldValue('correo', user.correo_usuario || '');
      setFieldValue('comuna', user.id_comuna || '');
      setFieldValue('telefono', user.numero_telefono || '');
    }
  }, [setFieldValue]);

  return null;
};

function FeedPostulante() {

  const [user,setUser] = useState([])
  useEffect(()=>{
    const userData = sessionStorage.getItem('userData');
    if (userData){
      setUser(userData)
    }
  })



  return (
    <PageContainer>
      <Navbar name={user.nombre_usuario} />
      <ContentWrap>
        <DivContenedor>
       
          <DivForm>
          <h1>Editar datos de mi cuenta</h1>
          <Formik
            initialValues={{
              nombre: '',
              apellido: '',
              comuna: '',
              region: '',
              contrasena: '',
              confirmarContrasena: '',
            }}
            validate={validate}
            onSubmit={(values) => {
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <Row>
                <Field value='testt' name="nombre" as={TextInput} label="Nombre" />
                <Field name="apellido" as={TextInput} label="Apellido" />
                </Row>

                <Row>
                <Field name="region" as={TextInput} label="Región" />
                <Field name="comuna" as={TextInput} label="Comuna" />
                </Row>
                <Row>
                <Field name="contrasena" as={TextInput} label="Contraseña" type="password" />
                

                <Field name="confirmarContrasena" as={TextInput} label="Confirmar Contraseña" type="password" />
                </Row>
                <SetFieldValuesFromLocalStorage />
                <ButtonActualizar type="submit">Editar datos</ButtonActualizar>
              </Form>
            )}
          </Formik>
          </DivForm>
        </DivContenedor>
      </ContentWrap>
      <Footer />
    </PageContainer>
  );
}

export default FeedPostulante;
